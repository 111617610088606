import React, { useState } from "react";

const SwitchButton = ({ options, value, onChange, customClass=null }) =>
{
    const [selectedValue, setSelectedValue] = useState(value);

    const toggleSwitch = () => {
        const newValue = options.find(option => option.value !== selectedValue)?.value;
        setSelectedValue(newValue);
        if (onChange) onChange(newValue);
    };

    const activeOption = options.find(option => option.value === selectedValue);
    const inactiveOption = options.find(option => option.value !== selectedValue);

    return (
        <div className={`switch-container ${customClass}`} onClick={toggleSwitch}>
            <div className={`switch ${selectedValue === options[1]?.value ? "on" : "off"}`}>
                <div className="switch-handle">
                    <span className="switch-handle-label">{activeOption?.label}</span>
                </div>
            </div>
        </div>
    );
};

export default SwitchButton;

