import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { audiencePosts as eventTracking} from "../api/audience";
import { apiUrl } from "../utils/url";
import { hideLoader, showLoader } from "../components/loader";

const Logout = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        // Show loader during logout process
        showLoader();

        const performLogout = async () => {
            try {
                // Only attempt to track logout event if token exists
                if (localStorage?.getItem("token")) {
                    const myHeaders = new Headers();
                    myHeaders.append("Authorization", `Bearer ${localStorage.getItem("token")}`);
                    const formdata = new FormData();
                    formdata.append("name", "Sign Out");

                    const api = apiUrl + `/event-track`;
                    const setOptions = {
                        method: "POST",
                        body: formdata,
                        headers: myHeaders
                    };

                    try {
                        // Track the logout event but don't wait indefinitely if it fails
                        await Promise.race([
                            eventTracking(null, api, hideLoader, setOptions, "custom_message"),
                            new Promise(resolve => setTimeout(resolve, 2000)) // Timeout after 2 seconds
                        ]);
                    } catch (error) {
                        console.log("Logout tracking error:", error);
                        // Continue with logout even if tracking fails
                    }
                }

                // Update Redux state first
                dispatch({ type: "LOGOUT" });

                // Dispatch custom event for App.js to detect authentication change
                window.dispatchEvent(new Event('auth-change'));

                localStorage.clear();

                // Wait a moment for state updates to propagate
                setTimeout(() => {
                    window.location.href = "/login";
                }, 500);

            } catch (error) {
                console.error("Error during logout:", error);
                // Fallback in case of errors
                localStorage.clear();
                window.location.href = "/login";
            }
        };

        performLogout();
    }, []);

    return (
        <div className="full-page-loader d-flex">
            <div className="lds-ripple"><div></div><div></div></div>
        </div>
    );
};

export default Logout;