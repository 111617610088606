
import wrong from "../assets/img/wrong-mark.svg";
import right from "../assets/img/green-tick.svg";
const PasswordValidation = ({ passwordTC, isSixChar, isTwentyChar, isOneUpperCase, isOneNumber }) => (
    passwordTC && (
        <div className="password-tc-box position-absolute f-ph f-reg rounded shadow-2" style={{ top: "100%", maxWidth:'360px' }}>
            <div className="ptc-title f-l mb-2 f-bol">
                Your password must have:
            </div>
            <div className="ptc-subtitle">
                {isSixChar ? (
                    <img
                        src={right}
                        alt="wrong"
                        className="ptc-img img-fluid"
                    />
                ) : (
                    <img
                        src={wrong}
                        alt="wrong"
                        className="ptc-img img-fluid"
                    />
                )}
                At least 8 characters
            </div>
            <div className="ptc-subtitle">
                {isTwentyChar ? (
                    <img
                        src={right}
                        alt="wrong"
                        className="ptc-img img-fluid"
                    />
                ) : (
                    <img
                        src={wrong}
                        alt="wrong"
                        className="ptc-img img-fluid"
                    />
                )}
                Max 20 characters
            </div>
            <div className="ptc-subtitle">
                {isOneUpperCase ? (
                    <img
                        src={right}
                        alt="wrong"
                        className="ptc-img img-fluid"
                    />
                ) : (
                    <img
                        src={wrong}
                        alt="wrong"
                        className="ptc-img img-fluid"
                    />
                )}
                At least 1 upper case letter (A .. Z)
            </div>
            <div className="ptc-subtitle">
                {isOneNumber ? (
                    <img
                        src={right}
                        alt="wrong"
                        className="ptc-img img-fluid"
                    />
                ) : (
                    <img
                        src={wrong}
                        alt="wrong"
                        className="ptc-img img-fluid"
                    />
                )}
                At least 1 number (0 .. 9)
            </div>
            <div className="ptc-subtitle mt-2">
                Avoid using a password that you use on other websites or one that is easy for someone else to guess.
            </div>
        </div>
    )
)

export default PasswordValidation