const INIT_STATE = [];
const emailPlansReducer = (state = INIT_STATE, action)=> {
    switch (action.type) {
        case 'SET_EMAIL_PLANS':
            return action.payload 
        case 'RESET_EMAIL_PLANS':
            return INIT_STATE
        default:
            return state
    }

}
export default emailPlansReducer