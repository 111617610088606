import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import IroCircularColorPicker from "../IroCircularColorPicker";
import { handleSubmitCustomTheme } from "../IroColorPicker";

const SelectedCustomTheme = ({ color, themeType, onApply }) =>
{
    const dispatch = useDispatch();
    const { customizeThemeReducer } = useSelector(state => state);

    const [inputColor, setInputColor] = useState(color);
    const [validColor, setValidColor] = useState("#ffffff");
    const [typingTimeout, setTypingTimeout] = useState(null);

    useEffect(() => {
        if (color) {
            setInputColor(color);
            setValidColor(color);
        }
    }, [color]);

    const validateHex = (hex) => {
        return /^#[0-9A-Fa-f]{6}$/.test(hex);
    };

    const onColorChange = (color) => {
        const newColor = color.hexString;
        if (validateHex(newColor)) {
            setInputColor(newColor);
            setValidColor(newColor);
            dispatch({
                type: "SET_CUSTOM_COLOR",
                payload: {
                    ...color,
                    [themeType]: newColor
                }
            });
        }
    };

    const handleChangeColor = (e) => {
        let newColor = e.target.value.toLowerCase().trim();

        // Ensure input starts with `#`
        if (!newColor.startsWith("#")) {
            newColor = `#${newColor.replace(/#/g, "")}`;
        }

        // Allow only valid hex characters
        if (!/^#[0-9a-f]*$/.test(newColor)) return;

        setInputColor(newColor);

        // Clear timeout to prevent unnecessary dispatches
        if (typingTimeout) clearTimeout(typingTimeout);

        // Wait before dispatching
        const timeout = setTimeout(() => {
            if (validateHex(newColor)) {
                setValidColor(newColor);
                dispatch({
                    type: "SET_CUSTOM_COLOR",
                    payload: {
                        ...color,
                        [themeType]: newColor
                    }
                });
            }
        }, 300);

        setTypingTimeout(timeout);
    };

    const applyChanges = () => {
        if (validateHex(validColor)) {
            handleSubmitCustomTheme("SET_CUSTOMIZE_THEME", dispatch, customizeThemeReducer, themeType);
            if(onApply){
                onApply();
            }
        }
    };

    return (
        <div className="project-theme-wheel color-picker-main" id="iro-picker-container">
            <div className={'iro-picker-wrapper'}>
                {/** Always show color picker, but pass only valid hex values **/}
                <IroCircularColorPicker
                    color={validColor}
                    themeType={themeType}
                    onColorChange={onColorChange}
                    width={111}
                    boxHeight={111}
                    layoutDirection="horizontal"
                    display="flex"
                    justifyContent="center"
                />

                <div className="d-flex justify-content-between align-items-center gap-20px">
                    <div className="d-flex align-items-center gap-1 zaap-normal-text color-picker-div">
                        <span id={`hex_code-${themeType}-label`} style={{ background: validColor }}></span>
                        <input
                            type="text"
                            value={inputColor}
                            onChange={handleChangeColor}
                            maxLength={7}
                            id={`hex_code-${themeType}`}
                            className="w-100"
                            style={{ borderWidth: "1px" }}
                        />
                    </div>
                    <button className="dark-btn h-auto w-100" onClick={applyChanges} style={{ padding: "9px 2.7px" }}>
                        Apply Color
                    </button>
                </div>
            </div>
        </div>
    );
};

export default SelectedCustomTheme;
