import { Modal } from "react-bootstrap";
import IroColorPicker, { handleSubmitCustomTheme } from "./IroColorPicker";
import { useEffect, useState } from "react";
import SelectedThemeInput from "./commons/select-theme-input";
import SelectedCustomTheme from "./commons/select-custom-theme";
import { useDispatch, useSelector } from "react-redux";

const colorConfig = (_this, name, color) => {
    $(_this).parent().find(".text-color-img").removeClass('active');
    $(_this).addClass('active');
    if (_this) {
        if ($(_this).attr('data-type') == 'header') {
            if ($(_this).attr('data-original-title') != 'Colored') {
                $(".custom-theme-preview svg rect:first-child").css('fill', $(_this).attr('data-color'));
                $(_this).parent().parent().find('.color-picker-main').removeClass('d-flex');
                $(_this).parent().parent().find('.color-picker-main').addClass('d-none');
                $("#hex_code").val($(_this).attr('data-color'));
                $(`#hex_code-${$(_this).attr('data-type')}`).val($(_this).attr('data-color'));
                $(`#rgb_code-${$(_this).attr('data-type')}`).val($(_this).attr('data-color'));
            }
            else {
                $(".custom-theme-preview svg rect:first-child").css('fill', $("#hex_code").val());
                $(_this).parent().parent().find('.color-picker-main').removeClass('d-none');
                $(_this).parent().parent().find('.color-picker-main').addClass('d-flex');
            }
        }
        else if ($(_this).attr('data-type') == 'background') {
            
// console.log($(_this).attr('data-color'))
            if ($(_this).attr('data-original-title') != 'Colored') {
                $(".custom-theme-preview svg rect:nth-child(2)").css('fill', $(_this).attr('data-color'));
                $(_this).parent().parent().find('.color-picker-main').removeClass('d-flex');
                $(_this).parent().parent().find('.color-picker-main').addClass('d-none');
                $(`#hex_code-${$(_this).attr('data-type')}`).val($(_this).attr('data-color'));
                $(`#rgb_code-${$(_this).attr('data-type')}`).val($(_this).attr('data-color'));
            }
            else {
                $(".custom-theme-preview svg rect:nth-child(2)").css('fill', $("#hex_code2").val());
                $(_this).parent().parent().find('.color-picker-main').removeClass('d-none');
                $(_this).parent().parent().find('.color-picker-main').addClass('d-flex');
            }
        }
        else if ($(_this).attr('data-type') == 'links') {
            if ($(_this).attr('data-original-title') != 'Colored') {
                $(".custom-theme-preview svg g path").css('fill', $(_this).attr('data-color'));
                $(_this).parent().parent().find('.color-picker-main').removeClass('d-flex');
                $(_this).parent().parent().find('.color-picker-main').addClass('d-none');

                $(`#hex_code-${$(_this).attr('data-type')}`).val($(_this).attr('data-color'));
                $(`#rgb_code-${$(_this).attr('data-type')}`).val($(_this).attr('data-color'));
            }
            else {
                $(".custom-theme-preview svg g path").css('fill', $("#hex_code3").val());
                $(_this).parent().parent().find('.color-picker-main').removeClass('d-none');
                $(_this).parent().parent().find('.color-picker-main').addClass('d-flex');
            }
        }
        else if ($(_this).attr('data-type') == 'buttons') {
            if ($(_this).attr('data-original-title') != 'Colored') {
                $(".custom-theme-preview svg path:nth-child(4)").css('fill', $(_this).attr('data-color'));
                $(".custom-theme-preview svg path:nth-child(5)").css('fill', $(_this).attr('data-color'));
                $(_this).parent().parent().find('.color-picker-main').removeClass('d-flex');
                $(_this).parent().parent().find('.color-picker-main').addClass('d-none');
                $(`#hex_code-${$(_this).attr('data-type')}`).val($(_this).attr('data-color'));
                $(`#rgb_code-${$(_this).attr('data-type')}`).val($(_this).attr('data-color'));
            }
            else {
                $(".custom-theme-preview svg path:nth-child(4)").css('fill', $("#hex_code4").val());
                $(".custom-theme-preview svg path:nth-child(5)").css('fill', $("#hex_code4").val());
                $(_this).parent().parent().find('.color-picker-main').removeClass('d-none');
                $(_this).parent().parent().find('.color-picker-main').addClass('d-flex');
            }
        }
        else if ($(_this).attr('data-type') == 'button_text') {
            if ($(_this).attr('data-original-title') != 'Colored') {
                // $(".custom-theme-preview svg path:nth-child(4)").css('fill', $(_this).attr('data-color'));
                // $(".custom-theme-preview svg path:nth-child(5)").css('fill', $(_this).attr('data-color'));
                $(_this).parent().parent().find('.color-picker-main').removeClass('d-flex');
                $(_this).parent().parent().find('.color-picker-main').addClass('d-none');
                $(`#hex_code-${$(_this).attr('data-type')}`).val($(_this).attr('data-color'));
                $(`#rgb_code-${$(_this).attr('data-type')}`).val($(_this).attr('data-color'));
            }
            else {
                // $(".custom-theme-preview svg path:nth-child(4)").css('fill', $("#hex_code4").val());
                // $(".custom-theme-preview svg path:nth-child(5)").css('fill', $("#hex_code4").val());
                $(_this).parent().parent().find('.color-picker-main').removeClass('d-none');
                $(_this).parent().parent().find('.color-picker-main').addClass('d-flex');
            }
        }
        else if ($(_this).attr('id') == 'hex_code') {
            $(".custom-theme-preview svg rect:first-child").css('fill', $(_this).attr('data-color'));
            $(`#hex_code-${$(_this).attr('data-type')}`).val($(_this).attr('data-color'));
            $(`#rgb_code-${$(_this).attr('data-type')}`).val($(_this).attr('data-color'));
        }
    }
    else if (name) {
        if (name == 'header') {
            $(".custom-theme-preview svg rect:first-child").css('fill', color);
            $(_this).parent().parent().find('.color-picker-main').removeClass('d-flex');
            $(_this).parent().parent().find('.color-picker-main').addClass('d-none');
            $("#hex_code").val(color);
            $(`#hex_code-${name}`).val(color);
            $(`#rgb_code-${name}`).val(color);
        }
        else if (name == '') {
            $(".custom-theme-preview svg rect:nth-child(2)").css('fill', color);
            $(_this).parent().parent().find('.color-picker-main').removeClass('d-flex');
            $(_this).parent().parent().find('.color-picker-main').addClass('d-none');
            $(`#hex_code-${name}`).val(color);
            $(`#rgb_code-${name}`).val(color);

        }
        else if (name == 'links') {
            $(".custom-theme-preview svg g path").css('fill', color);
            $(_this).parent().parent().find('.color-picker-main').removeClass('d-flex');
            $(_this).parent().parent().find('.color-picker-main').addClass('d-none');

            $(`#hex_code-${name}`).val(color);
            $(`#rgb_code-${name}`).val(color);
        }
        else if (name == 'buttons') {
            $(".custom-theme-preview svg path:nth-child(4)").css('fill', color);
            $(".custom-theme-preview svg path:nth-child(5)").css('fill', color);
            $(_this).parent().parent().find('.color-picker-main').removeClass('d-flex');
            $(_this).parent().parent().find('.color-picker-main').addClass('d-none');
            $(`#hex_code-${name}`).val(color);
            $(`#rgb_code-${name}`).val(color);

        }
        else if (name == 'button_text') {
            $(".custom-theme-preview svg path:nth-child(4)").css('fill', color);
            $(".custom-theme-preview svg path:nth-child(5)").css('fill', color);
            $(_this).parent().parent().find('.color-picker-main').removeClass('d-flex');
            $(_this).parent().parent().find('.color-picker-main').addClass('d-none');
            $(`#hex_code-${name}`).val(color);
            $(`#rgb_code-${name}`).val(color);
        }
        else if (name == 'hex_code') {
            $(".custom-theme-preview svg rect:first-child").css('fill', color);
            $(`#hex_code-${name}`).val(color);
            $(`#rgb_code-${name}`).val(color);
        }
    }
}
const CustomThemeModal = ({ setOpenCustomTheme, openCustomTheme, openCustomThemeFrom }) =>
{
    const dispatch = useDispatch();
    const [activeThemeType, setActiveThemeType] = useState(null);

    const { productModalReducer, customizeThemeReducer } = useSelector(state => state);
    const { customThemeColorReducer } = useSelector(state => state);

    const handleResetThemeType = () =>{
        setActiveThemeType(null);
    }

    useEffect(() => {
        if (openCustomThemeFrom === "customize" && customizeThemeReducer?.custom_theme) {
            const parsedTheme = JSON.parse(customizeThemeReducer?.custom_theme);
            const temp_obj = {
                header: parsedTheme?.header?.color || parsedTheme?.header,
                background: parsedTheme?.background?.color || parsedTheme?.background,
                links: parsedTheme?.links?.color || parsedTheme?.links,
                buttons: parsedTheme?.buttons?.color || parsedTheme?.buttons,
                button_text: parsedTheme?.button_text?.color || parsedTheme?.button_text,
                text_color: parsedTheme?.text_color || "#ffffff",
            };
            dispatch({ type: "SET_CUSTOM_COLOR", payload: temp_obj });
        } else if (openCustomThemeFrom === "product" && productModalReducer?.custom_theme) {
            const parsedTheme = JSON.parse(productModalReducer?.custom_theme);
            const temp_obj = {
                header: parsedTheme?.header?.color || parsedTheme?.header,
                background: parsedTheme?.background?.color || parsedTheme?.background,
                links: parsedTheme?.links?.color || parsedTheme?.links,
                buttons: parsedTheme?.buttons?.color || parsedTheme?.buttons,
                button_text: parsedTheme?.button_text?.color || parsedTheme?.button_text,
            };
            dispatch({ type: "SET_CUSTOM_COLOR", payload: temp_obj });
        }
    }, [openCustomThemeFrom, customizeThemeReducer, productModalReducer]);

    return (
        <div className="d-flex justify-content-between align-items-center gap-4">
            <div name="custom_theme_div">
                <div className="notification-container"></div>
                <input type="hidden" name="request_type" value="custom_theme" />
                <input type="hidden" name="link_id" value="505308" />

                <div>
                    {/* Background Color */}
                    <div>
                        <span className="zaap-normal-text">Background Color</span>
                        <SelectedThemeInput
                            data_type="background"
                            themeType="background"
                            color={customThemeColorReducer?.background}
                            onClick={() => setActiveThemeType("background")}
                        />
                        {activeThemeType === "background" && (
                            <SelectedCustomTheme
                                themeType="background"
                                color={customThemeColorReducer?.background}
                                onApply={handleResetThemeType}
                            />
                        )}
                    </div>

                    {/* Block Color */}
                    <div className="mt-2">
                        <span className="zaap-normal-text">Block Color</span>
                        <SelectedThemeInput
                            data_type="links"
                            themeType="links"
                            color={customThemeColorReducer?.links}
                            onClick={() => setActiveThemeType("links")}
                        />
                        {activeThemeType === "links" && (
                            <SelectedCustomTheme themeType="links" color={customThemeColorReducer?.links} onApply={handleResetThemeType} />
                        )}
                    </div>

                    {/* Button Color */}
                    <div className="mt-2">
                        <span className="zaap-normal-text">Button Color</span>
                        <SelectedThemeInput
                            data_type="buttons"
                            themeType="buttons"
                            color={customThemeColorReducer?.buttons}
                            onClick={() => setActiveThemeType("buttons")}
                        />
                        {activeThemeType === "buttons" && (
                            <SelectedCustomTheme themeType="buttons" color={customThemeColorReducer?.buttons} onApply={handleResetThemeType} />
                        )}
                    </div>

                    {/* Text Color */}
                    <div className="mt-2">
                        <span className="zaap-normal-text">Text Color</span>
                        <SelectedThemeInput
                            data_type="text_color"
                            themeType="text_color"
                            color={customThemeColorReducer?.text_color}
                            onClick={() => setActiveThemeType("text_color")}
                        />
                        {activeThemeType === "text_color" && (
                            <SelectedCustomTheme themeType="text_color" color={customThemeColorReducer?.text_color} onApply={handleResetThemeType} />
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CustomThemeModal;