import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { hideLoader, showLoader } from '../components/loader';
import { Alert } from "react-bootstrap"
import { apiUrl } from '../utils/url';
import PasswordInput from "../components/form/PasswordInput";

const ResetPassword = () =>
{
    const history = useHistory()
    const [values, setvalues] = useState({password_confirmation: null, password: null});
    const [error, setError] = useState([])

    const formSubmit = (e) =>
    {
        e.preventDefault();
        const params = new URLSearchParams(window.location.search);
        setError(null);

        if(values.password && values.password_confirmation && values.password != values.password_confirmation){
            return toast.error("Please ensure that both passwords match.")
        }

        showLoader();

        if (params.get("t"))
        {
            const formdata = new FormData()
            for (const key in values) {
                if (Object.hasOwnProperty.call(values, key)) {
                    formdata.append(key, values[key]);
                }
            }
            fetch(`${apiUrl}/reset-password/${params.get("t")}`, {
                body: formdata,
                method: "POST"
            }).then(async (res) => {
                hideLoader()
                const response = await res?.json();
                if (response?.status_code == 200) {
                    localStorage.setItem("token", params.get("t"));
                    toast.success("Successfully Reset")
                    setTimeout(() => {
                        history.push("/login")
                    }, 3000);
                }
                else {
                    const errorList = [];
                    if (response?.errors?.password) 
                        response?.errors?.password?.map((val) => errorList.push(val))
                    
                    if (response?.errors?.password_confirmation)
                        response?.errors?.password_confirmation?.map((val) => errorList.push(val))

                    setError(errorList)

                    if (response?.status_message) toast.error(response?.status_message);

                }
            }).catch((err) => {
                hideLoader()
            })
        }
        else{
            hideLoader()
            toast.error("We couldn't reset your password at this time. Please try again later.")
        }
    };

    const handleChange = (e) => {
        setvalues({ ...values, [e.target.name]: e.target.value })
    };

    return (
        <div id="wrapper">
            <div class="auth-main newmain reset-password-box">
                <div class="steps-card steps-card-width">
                    <div class="card border-0 p-0 m-0 w-100 h-100">
                        <div>
                            <h1 class="text-left newpageheading mb-0">Reset Password</h1>

                            <form action="" method="post" class="mt-4" role="form" onSubmit={formSubmit}>
                                {error?.length > 0 && (
                                    <Alert variant="danger">
                                        {error?.map((val, index) => <li>{val}</li>)}
                                    </Alert>
                                )}
                                <div class="form-group">
                                    <label class="col-12 p-0 d-flex justify-content-between mt-3 mb-1">
                                        <strong>Reset Password</strong>
                                    </label>

                                    <PasswordInput
                                        placeholder="Your new password"
                                        name="password"
                                        value={values.password}
                                        onChange={handleChange}
                                        required="required"
                                        showGuidance={true}
                                    />
                                </div>

                                <div class="form-group">
                                    <label class="col-12 p-0 d-flex justify-content-between mt-3 mb-1"><strong>Reset Confirm Password</strong></label>
                                    <PasswordInput
                                        placeholder="Repeat new password"
                                        name="password_confirmation"
                                        value={values.password_confirmation}
                                        onChange={handleChange}
                                        required="required"
                                        showGuidance={true}
                                    />
                                </div>

                                <div class="form-group mt-4">
                                    <button
                                        type="submit"
                                        name="submit"
                                        style={{width: "100%", fontSize: 14, height: 35, borderRadius: 12}}
                                        className="red-btn-u"
                                        disabled={!(values.password && values.password_confirmation)}
                                    >
                                        Reset
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

                <div class="zaap-logo-area">
                    <svg xmlns="http://www.w3.org/2000/svg" width="63" height="20" viewBox="0 0 63 20" fill="none">
                        <path fill-rule="evenodd" clip-rule="evenodd"
                              d="M5.95083 0.966803C7.15528 -0.81946 9.94462 0.0331104 9.94462 2.1875V10.4541C9.94462 11.6599 8.96706 12.6375 7.76117 12.6375H2.18715C0.438038 12.6375 -0.601066 10.6836 0.376802 9.23337L5.95083 0.966803ZM7.58704 10.1058L7.58704 3.32694C7.58704 3.15512 7.36459 3.08713 7.26853 3.22959L2.69764 10.0084C2.61966 10.1241 2.70253 10.2799 2.84202 10.2799H7.41291C7.50908 10.2799 7.58704 10.202 7.58704 10.1058ZM10.4327 18.2189C9.22827 20.0052 6.43893 19.1526 6.43893 16.9982L6.43893 14.7419C6.43893 14.0909 6.9667 13.5631 7.61772 13.5631C8.26875 13.5631 8.79651 14.0909 8.79651 14.7419V15.8588C8.79651 16.0306 9.01897 16.0986 9.11503 15.9561L13.6859 9.17728C13.7639 9.06162 13.681 8.90579 13.5415 8.90579L11.8715 8.90579C11.2205 8.90579 10.6927 8.37803 10.6927 7.727C10.6927 7.07597 11.2205 6.54821 11.8715 6.54821H14.1964C15.9455 6.54821 16.9846 8.50212 16.0068 9.95235L10.4327 18.2189Z"
                              fill="#2D2D2D"/>
                        <path
                            d="M24.609 12.5111L30.4896 4.81475V2.83301H20.6631V5.40414H26.5095L20.5693 13.1432V15.0822H30.66V12.5111H24.609Z"
                            fill="#2D2D2D"/>
                        <path
                            d="M35.3201 15.3299C36.3513 15.3299 37.2036 14.9711 37.8769 14.2622V15.0822H40.6723V6.16438H37.8769V6.98441C37.2206 6.27542 36.3684 5.91666 35.3116 5.91666C34.1014 5.91666 33.0787 6.36938 32.2435 7.26629C31.4083 8.1632 30.9907 9.2822 30.9907 10.6233C30.9907 11.9644 31.4083 13.0834 32.2435 13.9803C33.0787 14.8772 34.1014 15.3299 35.3116 15.3299H35.3201ZM35.8229 12.8442C35.2178 12.8442 34.7235 12.6392 34.3315 12.2292C33.9395 11.8192 33.7435 11.281 33.7435 10.6147C33.7435 9.95701 33.9395 9.42741 34.3315 9.0174C34.7235 8.60738 35.2178 8.40237 35.8229 8.40237C36.4366 8.40237 36.9479 8.60738 37.3485 9.0174C37.749 9.42741 37.9536 9.95701 37.9536 10.6147C37.9536 11.2725 37.749 11.8106 37.3485 12.2292C36.9479 12.6477 36.4366 12.8527 35.8144 12.8527L35.8229 12.8442Z"
                            fill="#2D2D2D"/>
                        <path
                            d="M45.829 15.3299C46.8603 15.3299 47.7125 14.9711 48.3858 14.2622V15.0822H51.1812V6.16438H48.3858V6.98441C47.7296 6.27542 46.8773 5.91666 45.8205 5.91666C44.6103 5.91666 43.5876 6.36938 42.7524 7.26629C41.9172 8.1632 41.4996 9.2822 41.4996 10.6233C41.4996 11.9644 41.9172 13.0834 42.7524 13.9803C43.5876 14.8772 44.6103 15.3299 45.8205 15.3299H45.829ZM46.3319 12.8442C45.7268 12.8442 45.2325 12.6392 44.8404 12.2292C44.4484 11.8192 44.2524 11.281 44.2524 10.6147C44.2524 9.95701 44.4484 9.42741 44.8404 9.0174C45.2325 8.60738 45.7268 8.40237 46.3319 8.40237C46.9455 8.40237 47.4568 8.60738 47.8574 9.0174C48.258 9.42741 48.4625 9.95701 48.4625 10.6147C48.4625 11.2725 48.258 11.8106 47.8574 12.2292C47.4568 12.6477 46.9455 12.8527 46.3233 12.8527L46.3319 12.8442Z"
                            fill="#2D2D2D"/>
                        <path
                            d="M57.9516 5.91666C56.8948 5.91666 56.0426 6.27542 55.3863 6.98441V6.16438H52.5909V18.7552H55.3863V14.2622C56.0596 14.9711 56.9119 15.3299 57.9431 15.3299C59.1533 15.3299 60.176 14.8772 61.0112 13.9803C61.8464 13.0834 62.264 11.9644 62.264 10.6233C62.264 9.2822 61.8464 8.1632 61.0112 7.26629C60.176 6.36938 59.1533 5.9252 57.9431 5.9252L57.9516 5.91666ZM57.4317 12.8527C56.8096 12.8527 56.2982 12.6477 55.8977 12.2377C55.4971 11.8277 55.2926 11.2896 55.2926 10.6233C55.2926 9.96555 55.4886 9.43595 55.8892 9.02594C56.2897 8.61592 56.8011 8.41091 57.4232 8.41091C58.0283 8.41091 58.5226 8.61592 58.9147 9.02594C59.3067 9.43595 59.5027 9.96555 59.5027 10.6233C59.5027 11.281 59.3067 11.8192 58.9147 12.2377C58.5226 12.6563 58.0283 12.8613 57.4232 12.8613L57.4317 12.8527Z"
                            fill="#2D2D2D"/>
                    </svg>
                </div>
            </div>
        </div>
    )
}
export default ResetPassword