import React, { useEffect, useState } from 'react'
import { pageUrl, protocol } from "../../../utils/url";
import chainImage from "../../../assets/img/dashboard/chain.png"
import funnelImage from "../../../assets/img/dashboard/funnel.png"
import targetImage from "../../../assets/img/dashboard/target.png"
import goldPlateImage from "../../../assets/img/dashboard/gold-plate.png"
import heartImage from "../../../assets/img/dashboard/heart.png"
import calendarHalfImage from "../../../assets/img/dashboard/calendar-half.png"
import Skeleton from "react-loading-skeleton";
export const DashboardHeader = ({projects, selectedProfile, stats, selectedProject}) => {
    const [showSkeleton, setShowSkeleton] = useState(true);
    const [value, setValue] = useState({})
    useEffect(() => {
        const filteredProjects = projects?.filter((val) => {
            if (selectedProject) {
                setShowSkeleton(false);
                return val?.id === selectedProfile;
            } else {
                setShowSkeleton(false);
                return val?.project_id === selectedProfile;
            }
        });
        setValue(filteredProjects[0])
    }, [projects, selectedProject, selectedProfile]);

    return(
            <>
                <div className={`project-details-main`} >
                    <div id={`fade-color`}>
                        <div className="container dashboard-container" >
                            <div className="project-details-div d-flex align-items-center" style={{ width: "-webkit-fill-available" }}>
                                <div className="project-user-pp">
                                    {
                                        showSkeleton ?
                                        <Skeleton
                                            circle
                                            height={70}
                                            width={70}
                                            containerClassName="avatar-skeleton"
                                        />
                                        :
                                        <img src={value?.settings ? value?.settings[1]?.value :value?.project?.settings[1]?.value} width="100%" alt="" />
                                    }
                                </div>
                                <div className="project-details d-flex align-items-start justify-content-center flex-column">
                                    <div className="project-title d-flex align-items-center ps-2">
                                        {showSkeleton ?
                                            <>
                                                <Skeleton width={100} className="ms-2 zaap-badge badge-free" />
                                                <Skeleton width={50} className="ms-2 zaap-badge badge-free" />
                                            </>
                                            :
                                            value?.settings ? value?.settings[0]?.value:value?.project?.settings[0]?.value ?? localStorage?.slug
                                        }

                                    </div>
                                    <div className="project-link d-flex align-items-center">
                                        {showSkeleton ?
                                            <>
                                                <Skeleton width={150} className="text-muted-light ms-2" />
                                                <Skeleton count={1} className="ms-3 mt-1 copy-clipboard" />
                                            </>
                                            :
                                            <a href={`${protocol + pageUrl}/${value?.settings ? value?.slug:value?.project?.slug}`} target="blank" className="text-muted-light">{`${pageUrl}/${value?.settings ? value?.slug:value?.project?.slug ?? localStorage?.slug}`}</a>
                                        }
                                    </div>
                                </div>
                            </div>
                        
                            <div className="cards-container">
                                <div className="views-card card dashboard-analytics-cards" data-color="views-card-hover" id="dashboard-analytics-cards">
                                    <div className="" style={{padding:"15px"}}>
                                        <div className="card-heading dashboard-card-heading">
                                            Views
                                        </div>
                                        <div className="card-text dashboard-card-text">
                                            Last 7 days
                                        </div>
                                        <div className="dashboard-card-numbers">
                                            {stats?.page_activity?.impressions_seven_days}
                                        </div>
                                    </div>
                                    <div className="views-card-pic-div">
                                        <img className="views-card-image" src={chainImage} alt="" />
                                    </div>
                                </div>
                                <div className="clicks-card card dashboard-analytics-cards" data-color="clicks-card-hover" >
                                    <div className="" style={{padding:"15px"}}>
                                        <div className="card-heading dashboard-card-heading">
                                            Clicks
                                        </div>
                                        <div className="card-text dashboard-card-text">
                                            Last 7 days
                                        </div>
                                        <div className="dashboard-card-numbers">
                                        {stats?.page_activity?.impressions_seven_days}
                                        </div>
                                    </div>
                                    <div className="clicks-card-pic-div">
                                        <img className="clicks-card-image" src={funnelImage} alt="" />
                                    </div>
                                </div>
                                <div className="sales-card card dashboard-analytics-cards" data-color="sales-card-hover" >
                                    <div className="" style={{padding:"15px"}}>
                                        <div className="card-heading dashboard-card-heading">
                                            Sales
                                        </div>
                                        <div className="card-text dashboard-card-text">
                                            Last 7 days
                                        </div>
                                        <div className="dashboard-card-numbers">
                                            {stats?.sales?.seven_days?.sales}
                                        </div>
                                    </div>
                                    <div className="sales-card-pic-div">
                                        <img className="sales-card-image" src={targetImage} alt="" />
                                    </div>
                                </div>
                                <div className="revenue-card card dashboard-analytics-cards" data-color="revenue-card-hover" >
                                    <div className="" style={{padding:"15px"}}>
                                        <div className="card-heading dashboard-card-heading">
                                            Revenue
                                        </div>
                                        <div className="card-text dashboard-card-text">
                                            Last 7 days
                                        </div>
                                        <div className="dashboard-card-numbers">
                                        {stats?.sales?.seven_days?.revenue}
                                        </div>
                                    </div>
                                    <div className="revenue-card-pic-div">
                                        <img className="revenue-card-image" src={goldPlateImage} alt="" />
                                    </div>
                                </div>
                                <div className="subscribers-card card dashboard-analytics-cards" data-color="subscribers-card-hover" >
                                    <div className="" style={{padding:"15px"}}>
                                        <div className="card-heading dashboard-card-heading">
                                            Subscribers
                                        </div>
                                        <div className="card-text dashboard-card-text">
                                            Last 7 days
                                        </div>
                                        <div className="dashboard-card-numbers">
                                        {stats?.subscribers_seven_days}
                                        </div>
                                    </div>
                                    <div className="subscribers-card-pic-div">
                                        <img className="subscribers-card-image" src={heartImage} alt="" />
                                    </div>
                                </div>
                                <div className="meetings-card card dashboard-analytics-cards" data-color="meetings-card-hover" >
                                    <div className="" style={{padding:"15px"}}>
                                        <div className="card-heading dashboard-card-heading">
                                            Meetings
                                        </div>
                                        <div className="card-text dashboard-card-text">
                                            Last 7 days
                                        </div>
                                        <div className="dashboard-card-numbers">
                                            {stats?.products_seven_days}
                                        </div>
                                    </div>
                                    <div className="meetings-card-pic-div">
                                        <img className="meetings-card-image" src={calendarHalfImage} alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
    )
}
