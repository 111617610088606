import { useEffect } from "react";
import { Modal } from "react-bootstrap"
import ConfettiGenerator from "confetti-js";
import { useSelector } from "react-redux";
import copyClipboardWhite from "../../../assets/img/copy-clipboard.svg"
import { useHistory } from "react-router-dom";
import { pageUrl } from "../../../utils/url";

const CongratsModal = ({ show }) => {
    const history = useHistory()
    const { slug } = useSelector(state => state?.slugReducer);
    const link = `${pageUrl}/${slug ? slug : localStorage.getItem("slug")}`

    const handleHide = () => {
        $("#my-canvas").remove()
        history.push({state: {congratsOpen: false}});
    }

    const copyLink = () => navigator.clipboard.writeText(link).then((r => {toast.success("✅ Copied and ready to paste!", { icon: false,})}))

    useEffect(() => {
        var confettiSettings = {"target":"my-canvas","max":"400","size":"1","animate":true,"props":["square","triangle","line"],"colors":[[165,104,246],[230,61,135],[0,199,228],[253,214,126]],"clock":"50","rotate":false,"start_from_edge":false,"respawn":true};
        const confetti = new ConfettiGenerator(confettiSettings);
        confetti.render();
    
        return () => confetti.clear();
    }, [])

    return (
        <Modal centered show={show} onHide={handleHide} id="createLinkModal">
            <canvas style={{ display: "block", position: "fixed", width: "100%", height: "100%", top: 0, left: 0 }} id="my-canvas"></canvas>
            <div className="modal-dialog-centered" role="document">
                <div className="modal-content modal-semi-curve">

                    <div class=" modal-header create-biolink-header">
                        <div style={{ margin: "0 auto" }}><h4 class="modal-title create-biolink-heading text-center">Your page is live!</h4></div>  <button type="button" onClick={handleHide} className="close" style={{ margin: 0, padding: 0 }}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>

                    <div class="modal-body">
                        <div class="form-group">
                            <p class="text-gray font-weight-500 text-center">Start adding your socials, collecting emails and selling your products. Share your page with this link:</p>
                            <h5 class="mb-1 text-center"><span style={{ color: "#FF1451" }}>{link}</span></h5>
                        </div>
                    </div>

                    <div class="form-group mt-1 mb-3 d-flex justify-content-center">

                        <button type="submit" class="btn btn-block my-1 flex-1 py-2 mx-4" style={{borderColor: "#5B5B5B"}} onClick={copyLink}>
                            <b style={{ color: "#5B5B5B" }}>
                                Copy Link
                            </b>
                            {`  `}
                            <img src={copyClipboardWhite} alt="" srcset="" />
                        </button>

                    </div>
                </div>
            </div>
        </Modal>
    )
}
export default CongratsModal