import { useState } from 'react';
import PasswordValidation from "../password-validation";
import {isContainNumber, isUpperCase} from "../../utils/helper";

const PasswordInput = ({ value, onChange, onFocus, onBlur, name = "password", placeholder = "Enter a password", showGuidance = false }) =>
{
    const [showPassword, setShowPassword] = useState(false);
    const [passwordTC, setPasswordTC] = useState(false);
    const [isSixChar, setSixChar] = useState(false);
    const [isTwentyChar, setTwentyChar] = useState(false);
    const [isOneUpperCase, setOneUpperCase] = useState(false);
    const [isOneNumber, setOneNumber] = useState(false);

    const handleChange = (e) =>
    {
        const value = e.target.value;

        if ((value.length >= 8) && (isContainNumber(value)) && (isUpperCase(value))) {
            setPasswordTC(false)
        }
        else
        {
            setPasswordTC(true)

            if (value.length >= 8) {
                setSixChar(true);
            }
            else if (value.length < 8) {
                setSixChar(false);
            }
            if (value.length >= 20) {
                setTwentyChar(true);
            }
            else if (value.length < 20) {
                setTwentyChar(false);
            }
            if (isContainNumber(value)) {
                setOneNumber(true);
            }
            else if (!isContainNumber(value)) {
                setOneNumber(false);
            }
            if (isUpperCase(value)) {
                setOneUpperCase(true);
            }
            else if (!isUpperCase(value)) {
                setOneUpperCase(false);
            }
        }

        if(onChange){
            onChange(e);
        }
    }

    const handleBlur = (e) =>{
        if(showGuidance){
            setPasswordTC(false)
        }
        if(onBlur){
            onBlur(e);
        }
    }

    const handleFocus = (e) =>{
        if(showGuidance){
            setPasswordTC(true)
            handleChange(e)
        }
        if(onFocus){
            onFocus(e);
        }
    }

    return (
        <div className={'passwordInputFieldWrapper'}>
            <div className='newinput-u'>
                <div className={'passwordInputField'}>
                    <input
                        type={showPassword ? "text" : "password"}
                        value={value}
                        onChange={handleChange}
                        placeholder={placeholder}
                        name={name}
                        onFocus={handleFocus}
                        onBlur={handleBlur}
                        required
                    />
                    <button type="button" onClick={() => setShowPassword((prev) => !prev)}>
                        <img src={showPassword ? '/icons/eye-icon.svg' : '/icons/eye-strike-icon.svg'} alt={'eye-icon'} />
                    </button>
                </div>
                {showGuidance ?
                    <PasswordValidation
                        from="signup"
                        passwordTC={passwordTC}
                        isSixChar={isSixChar}
                        isTwentyChar={isTwentyChar}
                        isOneUpperCase={isOneUpperCase}
                        isOneNumber={isOneNumber}
                    /> : ''
                }
            </div>
        </div>
    );
};

export default PasswordInput;
