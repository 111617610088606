import React, { useEffect, useState } from "react";
import Xicon from "../assets/img/products/xicon.svg"
import "../styles/settings.css";
import TeamModal from "../components/teammember/team_modal";
import { useDispatch, useSelector } from "react-redux";
import { apiUrl } from "../utils/url";
import { hideLoader, showLoader } from "../components/loader";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useAlert } from "../contexts/AlertContext";

export default function Teammembers({ setActivePage }) {
    const { basePath, slug } = useSelector(state => state?.slugReducer);
    const pro = useSelector(state => state?.proReducer);
    const newUser = useSelector(state => state?.newUserReducer);
    const planType = useSelector(state => state?.plantypeReducer);
    const isEligible = useSelector(state => state?.eligibilityReducer);
    const [showModalasd, setShowModalasd] = useState(false)
    const [email, setEmail] = useState("")
    const [teamMembers, setTeamMembers] = useState([])
    const username = localStorage.user;
    const history = useHistory()
    const dispatch = useDispatch();
    const { state } = useLocation()
    const { setAlert } = useAlert();
    useEffect(() => {
        if (!pro) {
            setShowModalasd(true);
        } else if (!isEligible) {
            setShowModalasd(true);
        } else {
            setShowModalasd(false);
            getdata()
        }
    }, [pro, planType, isEligible]);

    function handlesave(e) {
        showLoader()
        let errorList = [];
        e.preventDefault()
        const api = apiUrl + `/settings/team-members/create`;
        var form_data = new FormData();
        var myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${localStorage?.getItem("token")}`);
        form_data.append("email", email);
        form_data.append("project", slug);
        const setOptions = {
            method: "POST",
            headers: myHeaders,
            body: form_data
        }
        fetch(api, setOptions)
            .then(async (res) => {
                const response = await res?.json();
                if (response?.status_code === 200) {
                    setAlert({ type: 'success', title: response?.status_message })
                    getdata()
                    setEmail("")
                }
                else {
                    response?.errors?.email?.map((val) => errorList?.push(val));
                    response?.errors?.member?.map((val) => errorList?.push(val));
                    if (errorList.length !== 0) {
                        errorList?.map((err) => {
                            return (
                                setAlert({ type: 'error', title: err })
                            );
                        });
                    }
                    else {
                        setAlert({ type: 'error', title: response?.status_message })
                    }
                    hideLoader()
                }

            }).catch((err) => {
                setAlert({ type: 'error', title: err })
            })
    }
    function getdata() {
        let errorList = [];
        showLoader()
        if (slug) {
            const api = apiUrl + `/settings/team-members/get/${slug}`;
            var myHeaders = new Headers();
            myHeaders.append("Authorization", `Bearer ${localStorage?.getItem("token")}`);
            const setOptions = {
                method: "GET",
                headers: myHeaders,
            };

            fetch(api, setOptions).then(async (res) => {
                const response = await res.json();
                if (response.status_code === 200) {
                    setTeamMembers(response.data)
                    hideLoader()
                }
                else {
                    response?.errors?.project?.map((val) => errorList?.push(val));
                    if (errorList.length !== 0) {
                        errorList?.map((err) => {
                            if (err == "Invalid Project") {
                                history.push("/dashboard")
                                toast.error(err)
                            }
                            else {
                                toast.error(err)
                            }
                        });
                    }
                    else {
                        toast.error(response?.status_message)
                    }
                    hideLoader()
                }
            })
                .catch((error) => {
                    toast.error(error)
                    hideLoader()
                });
        }
    }
    function removedata(id) {
        showLoader()
        const api = apiUrl + `/settings/team-members/delete/${id}`;
        var myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${localStorage?.getItem("token")}`);
        const setOptions = {
            method: "DELETE",
            headers: myHeaders,
        };

        fetch(api, setOptions
        ).then(async (res) => {
            const response = await res.json();
            if (response?.status_code === 200) {
                if (response?.data?.action != "back to dashboard") {
                    setAlert({ type: 'success', title: response?.status_message })
                    getdata()
                }
                else {
                    history.push("/dashboard")
                    toast.success(response.status_message)
                }
            }
            else {
                setAlert({ type: 'error', title: response?.status_message })
                hideLoader()
            }
        })
            .catch((error) => {
                console.error("Error:", error);
            });
    }
    const splitName = (split_name) => {
        const number_of_spaces = split_name?.length > 1 ? [split_name[0], split_name[split_name?.length - 2], split_name[split_name?.length - 1]].toString().split(",").length : split_name?.length;

        let first_char;
        let sec_char;
        let space;
        if (number_of_spaces) {
            if (number_of_spaces == 1) {
                first_char = split_name.slice(0, 1)
                sec_char = split_name.slice(1, 2)
            }
            else if (number_of_spaces == 2) {
                space = split_name.indexOf(" ");
                first_char = split_name.slice(0, 1)
                sec_char = split_name.slice(space + 1, space + 2)
            }
            else if (number_of_spaces == 3) {
                space = split_name.lastIndexOf(" ");
                first_char = split_name.slice(0, 1)
                sec_char = split_name.slice(space + number_of_spaces - 2, space + number_of_spaces - 1)
            }
            else {
                space = username.lastIndexOf(" ");
                first_char = username.slice(0, 1)
                sec_char = username.slice(space + number_of_spaces - 2, space + number_of_spaces - 1)
            }
            return (first_char + sec_char).toUpperCase()
        }
        else return ""
    };
    const upgradeHandleClick = (e) => {
        e.preventDefault();
        history.push(basepath + "/settings")
    }
    return (
        <section class="container h-100 pt-2 position-relative" style={{ padding: "25px", overflowY: "auto" }}>
            <div className="container settings-section" style={{ padding: "30px", marginTop: "46px" }}>
                <form onSubmit={handlesave} className="form-inline">
                    <div>
                        <h3 className="settings-section-heading">Team</h3>
                        <div className="account-form d-flex align-items-baseline mt-3">
                            <input
                                type="email"
                                minLength={1}
                                className="form-control"
                                placeholder="Email Address"
                                value={email}
                                onChange={(a) => setEmail(a.target.value)}
                            />
                            <button
                                type="submit"
                                className="btn ms-3 settings-button-primary btn-primary btn-semi-rounded d-flex align-items-center justify-content-center py-2 px-3"
                                style={{ fontSize: "12px", fontWeight: "500" }}
                            >
                                Send Invite
                            </button>
                        </div>
                    </div>
                    <div>
                    </div>
                </form>
                <div className="team-list-container">
                    {teamMembers && <div div className="team-list" >
                        <div style={{ display: "flex", alignItems: "center", width: "100%", marginTop: 14 }}>
                            <div style={{ paddingLeft: 10 }}>
                                <p className="team-members-icon">{splitName(teamMembers?.owner)}</p>
                            </div>
                            <div className="member-name-container">
                                <p className="team-member-name" style={{ paddingLeft: 15 }}>{teamMembers.owner}</p>
                            </div>
                        </div>
                        <div style={{ paddingRight: 10 }}>
                            <button className="team-owner-btn">
                                Owner
                            </button>
                        </div>
                    </div>}
                    {teamMembers?.owner && teamMembers?.team_members?.map((member, i) => {
                        return (
                            <div key={i} className="team-list" >
                                <div style={{ display: "flex", alignItems: "center", width: "100%", marginTop: 14 }}>
                                    <div style={{ paddingLeft: 10 }}>
                                        <p className="team-members-icon">{splitName(member?.user?.name ?? member?.email)}</p>
                                    </div>
                                    <div className="member-name-container">
                                        <p className="team-member-name" style={{ paddingLeft: 15 }}>{member?.user?.name ?? member?.email}</p>
                                    </div>
                                </div>
                                <div style={{ paddingRight: 10 }}>
                                    <button className="member-remove-btn" onClick={() => removedata(member?.id)}>
                                        Remove
                                        <img src={Xicon} width="10px" style={{ marginLeft: "3px" }} />
                                    </button>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
            {showModalasd && <TeamModal from={"team-settings"} showModal={showModalasd} setShowModal={setShowModalasd} setActivePage={setActivePage} upgradeHandleClick={upgradeHandleClick} heading={'Upgrade to add team members.'} text={'Team members is a Ultra feature. Upgrade to <br /> zaap Ultra to unlock this feature'} isUltra={true} />}
        </section>
    )
}