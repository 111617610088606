
import { Modal } from "react-bootstrap";
import embed_block_banner from "../assets/img/payout-settings-modal.svg"
import payout_settings_paypal from "../assets/img/payout-settings-paypal.svg"
import payout_settings_stripe from "../assets/img/payout-settings-stripe.svg"
import returnIcon from "../assets/img/modals/return.svg"
import { toast } from "react-toastify";
import { apiUrl } from "../utils/url";
import { hideLoader, showLoader } from "./loader";
import { useSelector } from "react-redux";

const PayoutSettings = ({ show, setShow, connectStripe, connectPayPal, stripeConnectReducer, setpayoutDisconnect=false }) => {

    const { slug } = useSelector(state => state?.slugReducer);
    const hideModal = () => setShow(false);
    const disconnectModal = () => {
        setpayoutDisconnect(true);
        hideModal()
    }
    const disconnectSripe = (e) => {
        e.preventDefault();
        showLoader()
    
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${localStorage?.getItem("token")}`)
        const formdata = new FormData()
        formdata.append("project", slug ? slug : localStorage?.getItem("slug"));
        
        const api = apiUrl + `/stripe-disconnect`;
        const setOptions = {
            method: "POST",
            body: formdata,
            headers: myHeaders
        }
        fetch(api, setOptions)
        .then(async (res) => {
          const response = await res?.json();
          if (response?.status === 'success') {
            hideModal()
            toast.success(response?.message);
            setTimeout(() => {
                window.location.reload();
            }, 1000);
          } else {
            toast.error(response?.errors);
            hideLoader();
          }
        })
        .catch(() => {
          hideLoader();
        });
    
    
    }
    return (
        <Modal centered show={show} onHide={hideModal} id="createLinkModal">
            <div className="modal-dialog-centered" role="document">
                <div className="modal-content">

                    <div className="modal-body">
                        <form name="create_folder" method="post" role="form">
                            <div className="form-group">
                                <img src={embed_block_banner} width="30px" alt="" />
                                <h4 className="mt-2 mb-1">Payout Settings</h4>
                                <p className="text-gray font-weight-500">Connect your Stripe and PayPal accounts to start selling</p>

                                <div style={{ border: "1px dashed #A0A0A0", borderRadius: "17px", padding: "10px" }} className="mb-2">
                                    <div className={`d-flex align-items-center justify-content-between`}>
                                        <div className="d-flex align-items-center justify-content-start">
                                            <img src={payout_settings_stripe} width="40px" alt="" />
                                            <p className="text-gray font-weight-500 mb-0 ms-2">Connect your Stripe account</p>    
                                        </div>
                                        {stripeConnectReducer?.stripe === 0 ?
                                            <button type="button" className="btn btn-grey btn-semi-rounded" onClick={connectStripe}><b>Connect</b></button> : 
                                            <button type="button" className="btn btn-grey btn-semi-rounded" onClick={disconnectSripe}><b>Disconnect</b></button>
                                        }
                                    </div>
                                </div>

                                <div style={{ border: "1px dashed #A0A0A0", borderRadius: "17px", padding: "10px" }} className="mb-2">
                                    <div className={`d-flex align-items-center justify-content-between`}>
                                        <div className="d-flex align-items-center justify-content-start">
                                            <img src={payout_settings_paypal} width="40px" alt="" />
                                            <p className="text-gray font-weight-500 mb-0 ms-2">Connect your PayPal account</p>
                                        </div>
                                        {stripeConnectReducer?.paypal === 0 ?
                                            <button type="button" className="btn btn-grey btn-semi-rounded" onClick={connectPayPal}><b>Connect</b></button> : 
                                            <button type="button" className="btn btn-grey btn-semi-rounded" onClick={disconnectModal}><b>Disconnect</b></button>
                                        }
                                    </div>
                                </div>

                                <div className="text-center mt-4 d-flex">
                                    <button type="button" onClick={hideModal} className="close btn btn-grey btn-semi-rounded me-2 d-flex align-items-center justify-content-center">
                                        <b className="me-2">Cancel</b>
                                        <small className="badge bg-light text-dark p-1">Esc</small>
                                    </button>
                                    <button type="button" onClick={hideModal} className="btn btn-primary btn-semi-rounded d-flex align-items-center justify-content-center">
                                        <b className="me-2">Done</b>
                                        <small className="badge bg-primary-dark text-dark px-2 py-1 d-flex align-items-center justify-content-center">
                                            <img src={returnIcon} alt="" />
                                        </small>
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </Modal>
    )
}
export default PayoutSettings;