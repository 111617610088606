import { ReactSortable } from "react-sortablejs";
import returnIcon from "../../../../assets/img/modals/return.svg";
// import * as socialsOptions from "../../../utils/socials.json"
import { useState, useEffect } from "react";
import productType from "../../../../assets/img/product-type-icon.svg";
import lightmodeicon from "../../../../assets/img/products/lightmodeicon.svg";
import iro from "@jaames/iro";

const ThemePage = ({ data, handleSubmit , error, setError  }) => {
    const [product_theme, setProductTheme] = useState(data?.product_theme);
    const [accent_theme, setAccent_Theme] = useState(data?.accent_theme);

    let colorWheel4;
    let typingTimer4;
    let doneTypingInterval = 500;
    useEffect(() => {
        colorWheel4 = new iro.ColorPicker("#colorWheelDemo4", {
          width: 100,
          color: accent_theme,
          borderWidth: 1,
          borderColor: "#fff",
          display: "flex",
        });
    
        colorWheel4.on("color:change", function (color, changes) {
          $("#hex_code4").val(color.hexString);
          $("#rgb_code4").val(
            colorWheel4.color.rgbString.replace("rgb(", "").replace(")", "")
          );
          setAccent_Theme(color.hexString);
        });
    
        $("#hex_code4").keyup(function () {
          clearTimeout(typingTimer4);
          if ($(this).val()) {
            typingTimer4 = setTimeout(() => {
              colorWheel4.color.hexString = $(this).val();
            }, doneTypingInterval);
          }
        });
      }, []);


    return (
        <div class="tab-pane fade customize-page-main active show p20-u" id="customizePageSocials" role="tabpanel" aria-labelledby="social-tab">
            <section>
            <h4 className="productHead1">Product Theme</h4>
                <form id="newProductModal" className="ProductBody" name="product_type" method="post" role="form">
                    <div className="notification-container"></div>
                    <div className="form-group p-0 mt-4">
                        <label className="productlabels">Product Page Theme</label>
                        <div className="up-in-toggle bg-f7f7f8  product-style theme-toggle">
                            <input
                                onChange={(a) =>
                                    setProductTheme(a.target.value)
                                }
                                type="radio"
                                id="file_type_switch_left"
                                name="product_theme"
                                value="light"
                                className="light-inp"
                                checked={product_theme == "light" ? true : false}
                            />
                            <label
                                htmlFor="file_type_switch_left"
                                className="d-flex align-items-center justify-content-center"
                            >
                                {" "}
                                <img
                                    src={lightmodeicon}
                                    style={{ marginRight: 5 }}
                                    width="13px"
                                />
                                <span class="font-weight-500 dark-style">Light</span>
                            </label>
                            <input
                                onChange={(a) =>
                                    setProductTheme(a.target.value)
                                }
                                type="radio"
                                id="file_type_switch_right"
                                name="product_theme"
                                value="darkmode"
                                checked={product_theme == "darkmode" ? true : false}
                            />
                            <label
                                htmlFor="file_type_switch_right"
                                className="d-flex align-items-center justify-content-center"
                            >
                                {/* <img style={{ marginRight: 5 }} src={darkmodeicon} width="25px" /> */}
                                <svg width="13" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M5.5 12L6.28446 13.5689C6.54995 14.0999 6.68269 14.3654 6.86003 14.5954C7.01739 14.7996 7.20041 14.9826 7.40455 15.14C7.63462 15.3173 7.9001 15.4501 8.43108 15.7155L10 16.5L8.43108 17.2845C7.9001 17.5499 7.63462 17.6827 7.40455 17.86C7.20041 18.0174 7.01739 18.2004 6.86003 18.4046C6.68269 18.6346 6.54995 18.9001 6.28446 19.4311L5.5 21L4.71554 19.4311C4.45005 18.9001 4.31731 18.6346 4.13997 18.4046C3.98261 18.2004 3.79959 18.0174 3.59545 17.86C3.36538 17.6827 3.0999 17.5499 2.56892 17.2845L1 16.5L2.56892 15.7155C3.0999 15.4501 3.36538 15.3173 3.59545 15.14C3.79959 14.9826 3.98261 14.7996 4.13997 14.5954C4.31731 14.3654 4.45005 14.0999 4.71554 13.5689L5.5 12Z" stroke="#6B6B6B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M14 1L15.1786 4.06442C15.4606 4.79765 15.6016 5.16426 15.8209 5.47264C16.0153 5.74595 16.254 5.98475 16.5274 6.17909C16.8357 6.39836 17.2024 6.53937 17.9356 6.82138L21 8L17.9356 9.17862C17.2024 9.46063 16.8357 9.60164 16.5274 9.82091C16.254 10.0153 16.0153 10.254 15.8209 10.5274C15.6016 10.8357 15.4606 11.2024 15.1786 11.9356L14 15L12.8214 11.9356C12.5394 11.2024 12.3984 10.8357 12.1791 10.5274C11.9847 10.254 11.746 10.0153 11.4726 9.82091C11.1643 9.60164 10.7976 9.46063 10.0644 9.17862L7 8L10.0644 6.82138C10.7976 6.53937 11.1643 6.39836 11.4726 6.17909C11.746 5.98475 11.9847 5.74595 12.1791 5.47264C12.3984 5.16426 12.5394 4.79765 12.8214 4.06442L14 1Z" stroke="#6B6B6B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>

                                <span className="dark-style" style={{ marginLeft: 5 }}>Dark</span>

                            </label>
                        </div>
                        <div className="mb-3 col-md-12 mt-4">
                                  <div>
                                    <label className="text-gray font-weight-500 accent-text">Accent Color</label>
                                  </div>
                                </div>
                                <div className="d-flex justify-content-start align-items-center flex-wrap">
                                  <div>
                                    <div
                                      className="wheel"
                                      id="colorWheelDemo4"
                                    ></div>
                                  </div>
                                  <div style={{ paddingLeft: 10 }}>
                                    {" "}
                                    <input
                                      type="text"
                                      id="hex_code4"
                                      className="w-50  py-1"
                                      name="hex_code4"
                                      defaultValue={accent_theme}
                                      required="required"
                                      style={{ marginTop: 10, border: "none", outline: "none", fontWeight: "bold", color: "  rgba(14, 18, 59, 0.5)   " }}
                                    />
                                  </div>
                                </div>
                    </div>
                    <div class="text-center mt-4 d-flex submit-buttons-fixed">
                        <button type="button" onClick={(e)=>{
                        handleSubmit(e,{
                            accent_theme,
                            product_theme
                        },'theme')}} class="btn btn-primary btn-semi-rounded d-flex align-items-center justify-content-center">
                            <b class="me-2 productlabel2">Save Changes</b>
                            <small class="badge bg-primary-dark text-dark px-2 py-1 d-flex align-items-center justify-content-center">
                                <img src={returnIcon} alt="" />
                            </small>
                        </button>
                    </div>
                </form>
            </section>
        </div >
    )
}
export default ThemePage