import { Modal } from "react-bootstrap"
import thunderIcon from "../../assets/img/thunder.svg"
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";


const ProjectModal = ({ show, onHide }) => {

    return (
        <>
        <div id="wrapper">
            <Modal centered id="newBlockModal"
                show={show?.show} onHide={onHide} size={"md"} >
                <div className=" modal-dialog-centered" role="document">
                    <div className="modal-content upgradenewsmodal">
                        <div className="modal-body mx-2 p-0">
                            <div className="form-group">
                                <img src={thunderIcon} style={{ width: "40px" }} alt="" />
                                <div className="newsletter-modalheading">{show?.title}</div>
                                <p className="project-modal-content">{show?.desc}</p>
                                <div className="d-flex align-items-center gap-1">
                                <button className="grey-cancel-btn" onClick={onHide}>
                                    <span>
                                        Close
                                    </span>
                                    <small class="badge bg-light text-dark p-1">Esc</small>
                                </button>
                                {
                                    show?.supportBtn && 
                                    <a href="mailto:support@zaap.ai" className="new-primary-btn text-decoration-none text-light" >
                                        Contact Support
                                    </a>
                                }
                                </div>
                            </div>
                        </div>
    
    
                    </div>
    
                </div>
            </Modal>
        </div>
        </>
    )
}
export default ProjectModal
