
import { Modal } from "react-bootstrap";
import payout_settings_warning_banner from "../../assets/img/payout-setting-warning-banner.svg"
import notConnected from "../../assets/img/payout-settings-not-connect-icon.svg";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { useState } from "react";

const ConnectCalendarModal = ({ show, setShow, type, text, TwoButtons}) => {
    const { slug, basePath } = useSelector((state) => state?.slugReducer);
    const isModalOpen = useSelector(state => state?.upgradeModalReducer?.open)
    const hideModal = () => setShow(false);
    let history = useHistory();
    const connect = () => {
        localStorage.setItem("openIntegrationPage",true);
        const newTab = window.open('', '_blank');
        newTab.location.href = basePath + '/settings';
    // history.push(basePath + '/settings/connect-calendar')
    };
    return (
        <Modal centered show={show } onHide={hideModal} >
            <div className=" modal-dialog-centered" role="document">
                <div className="modal-content p-0">
                    <div className="modal-body">
                        <div class="form-group">
                            <div style={{ marginTop: "-5px" }}>
                                <img src={notConnected} width="50px" style={{ boxShadow: "0px 4px 20px rgba(103, 101, 122, 0.19)", borderRadius: "10px" }} alt="" />
                            </div>
                            <h4 class="mt-3 mb-1">{TwoButtons ? 'Connect' : 'Disconnect'} {type ? type : 'Google Meet'}</h4>
                            <p class="text-gray font-weight-500">
                                {text}
                            </p>
                        </div>
                            <div class="text-center mt-4 d-flex">
                        {TwoButtons &&
                                <button autoFocus type="button" class="btn btn-primary btn-semi-rounded d-flex align-items-center justify-content-center" onClick={connect}>
                                    <b class="me-2">Connect</b>
                                </button>
                        }
                                <button type="button" class={`close btn btn-grey btn-semi-rounded d-flex align-items-center justify-content-center ${TwoButtons ? 'ms-2' : ''} `} onClick={hideModal}>
                                    <b class="me-2">{TwoButtons ? "Don't Connect" : 'Cancel'} </b>
                                </button>
                            </div>
                        
                    </div>
                </div>
            </div>
        </Modal>
    )
}
export default ConnectCalendarModal;
