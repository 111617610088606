import React from 'react'
import FilterDropdowns from './filter-dropdowns'
import CustomTooltip from '../common/CustomTooltip'
import analyticsInsta from "../../assets/img/analytics-insta.png"
import desktopIcon from "../../assets/img/desktop-icon.svg";
import mobileIcon from "../../assets/img/mobile-icon.svg";
import tabletIcon from "../../assets/img/tablet-icon.svg";
import analyticsUp from "../../assets/img/analytics-up.svg"
import analyticsDown from "../../assets/img/analytics-down.svg"
import menuOverview from "../../assets/img/menu-overview.svg"
import safariIcon from "../../assets/img/safari-icon.png"
import chromeIcon from "../../assets/img/chrome-icon.svg"
import edgeIcon from "../../assets/img/edge-icon.svg"
import chevron_down from "../../assets/img/modals/chevron-down.svg"
import Skeleton from 'react-loading-skeleton';
import { months, originalOptions, originalData } from "../../utils/analytics/line";
import rightArrowIcon from "../../assets/img/plain-right-arrow.svg"
import infoIcon from "../../assets/img/info-black.svg"
import { Line } from 'react-chartjs-2';
import { getOsIcon } from '../../pages/analytics';


const AnalyticsMain = ({
    topCountries, statsloading, activity, setactivity, date, dateFilters, statsData, screenWidth, analyticsGraphFilterClick, data, topSources, devices, browsers, operatingSystems, getStats, loading
}) => {
    const analyticsPageName = window.location.pathname.split("/").pop();
    return (
        <div class="section-main mt-4">
            {screenWidth == true &&
                <div className="align-items-center gap-1 filter-for-mobile">
                    <FilterDropdowns dateFilters={dateFilters} statsData={statsData} analyticsGraphFilterClick={analyticsGraphFilterClick} getStats={getStats} loading={loading} />
                </div>
            }
            <div className="graph-analytics-sections">
                <div className="d-flex align-items-center justify-content-between analytics-header">
                    <div className="analytics-card-main">
                        {
                            analyticsPageName !== 'links' &&
                            <>

                                <div className="analytics-card analytics-card-blue">
                                    <div className="d-flex align-items-center gap-1">
                                        <div className="analytics-box" style={{ backgroundColor: "#70EEFF" }}></div>
                                        <div className="analytics-type-heading">Unique Visitors</div>
                                    </div>
                                    {statsData?.unique_visitors?.type ? (
                                        <div className="d-flex align-items-center justify-content-start">
                                            <span className="analytics-count mb-1 me-1">{statsData?.unique_visitors?.count}</span>
                                            <span
                                                className={`analytics-badge ${statsData?.unique_visitors?.type === "up" ? 'analytics-badge-success' : 'analytics-badge-danger'} d-flex align-items-center justify-content-center`}
                                            >
                                                {statsData?.unique_visitors?.type !== "-" && (
                                                    <img src={statsData?.unique_visitors?.type === "up" ? analyticsUp : analyticsDown} width="10px" alt="" />
                                                )}
                                                <span className="ms-1">{statsData?.unique_visitors?.progress === "-" ? 0 : statsData?.unique_visitors?.progress}%</span>
                                            </span>
                                        </div>
                                    ) : (
                                        <div className="d-flex align-items-center justify-content-start">
                                            <h1 className="mb-1 me-2">
                                                <Skeleton width={50} height={40} className="mb-2" highlightColor="#70EEFF" borderRadius="0.5rem" duration={4} />
                                            </h1>
                                            <span className="d-flex align-items-center justify-content-center">
                                                <Skeleton width={40} height={15} className="mb-2" baseColor="#D5F5E3" highlightColor="#0CBD5B" duration={4} borderRadius="5px" />
                                            </span>
                                        </div>
                                    )}
                                </div>

                                <div className="analytics-card analytics-card-purple">
                                    <div className="d-flex align-items-center gap-1">
                                        <div className="analytics-box" style={{ backgroundColor: "#18f" }}></div>
                                        <div className="analytics-type-heading">Impressions</div>
                                    </div>
                                    {statsData?.impressions?.type ? (
                                        <div className="d-flex align-items-center justify-content-start">
                                            <span className="analytics-count mb-1 me-1">{statsData?.impressions?.count}</span>
                                            <span
                                                className={`analytics-badge ${statsData?.impressions?.type === "up" ? 'analytics-badge-success' : 'analytics-badge-danger'} d-flex align-items-center justify-content-center`}>
                                                {statsData?.impressions?.type !== "-" && (
                                                    <img src={statsData?.impressions?.type === "up" ? analyticsUp : analyticsDown} width="10px" alt="" />
                                                )}
                                                <span className="ms-1">{statsData?.impressions?.progress === "-" ? 0 : statsData?.impressions?.progress}%</span>
                                            </span>
                                        </div>
                                    ) : (
                                        <div className="d-flex align-items-center justify-content-start">
                                            <h1 className="mb-1 me-2">
                                                <Skeleton width={50} height={40} className="mb-2" highlightColor="#18f" borderRadius="0.5rem" duration={4} />
                                            </h1>
                                            <span className="d-flex align-items-center justify-content-center">
                                                <Skeleton width={40} height={15} className="mb-2" baseColor="#D5F5E3" highlightColor="#0CBD5B" duration={4} borderRadius="5px" />
                                            </span>
                                        </div>
                                    )}
                                </div>

                                <div className="analytics-card analytics-card-orange me-0">
                                    <div className="d-flex align-items-center gap-1">
                                        <div className="analytics-box" style={{ backgroundColor: "#7F48FB" }}></div>
                                        <div className="analytics-type-heading">Visit Duration</div>
                                    </div>
                                    {statsData?.time_on_page?.type ? (
                                        <div className="d-flex align-items-center justify-content-start">
                                            <div className="analytics-count mb-1 me-1">{statsData?.time_on_page?.count}</div>
                                            <span
                                                className={`analytics-badge ${statsData?.time_on_page?.type === "up" ? 'analytics-badge-success' : 'analytics-badge-danger'} d-flex align-items-center justify-content-center`}
                                            >
                                                {statsData?.time_on_page?.type !== "-" && (
                                                    <img src={statsData?.time_on_page?.type === "up" ? analyticsUp : analyticsDown} width="10px" alt="" />
                                                )}
                                                <span className="ms-1">{statsData?.time_on_page?.progress === "-" ? 0 : statsData?.time_on_page?.progress}%</span>
                                            </span>
                                        </div>
                                    ) : (
                                        <div className="d-flex align-items-center justify-content-start">
                                            <h1 className="mb-1 me-2">
                                                <Skeleton width={50} height={40} className="mb-2" highlightColor="#7F48FB" borderRadius="0.5rem" duration={4} />
                                            </h1>
                                            <span className="d-flex align-items-center justify-content-center">
                                                <Skeleton width={40} height={15} className="mb-2" baseColor="#D5F5E3" highlightColor="#0CBD5B" duration={4} borderRadius="5px" />
                                            </span>
                                        </div>
                                    )}
                                </div>
                                <div className="analytics-card analytics-card-pink">
                                    <div className="d-flex align-items-center gap-1">
                                        <div className="analytics-box" style={{ backgroundColor: "#F00" }}></div>
                                        <div className="analytics-type-heading">Click-Through-Rate</div>
                                    </div>
                                    {statsData?.click_rate?.type ? (
                                        <div className="d-flex align-items-center justify-content-start">
                                            <div className="analytics-count mb-1 me-1">{statsData?.click_rate?.count}%</div>
                                            <span
                                                className={`analytics-badge ${statsData?.click_rate?.type === "up" ? 'analytics-badge-success' : 'analytics-badge-danger'} d-flex align-items-center justify-content-center`}
                                            >
                                                {statsData?.click_rate?.type !== "-" && (
                                                    <img src={statsData?.click_rate?.type === "up" ? analyticsUp : analyticsDown} width="10px" alt="" />
                                                )}
                                                <span className="ms-1">{statsData?.click_rate?.progress === "-" ? 0 : statsData?.click_rate?.progress}%</span>
                                            </span>
                                        </div>
                                    ) : (
                                        <div className="d-flex align-items-center justify-content-start">
                                            <h1 className="mb-1 me-2">
                                                <Skeleton width={50} height={40} className="mb-2" highlightColor="#F00" borderRadius="0.5rem" duration={4} />
                                            </h1>
                                            <span className="d-flex align-items-center justify-content-center">
                                                <Skeleton width={40} height={15} className="mb-2" baseColor="#D5F5E3" highlightColor="#0CBD5B" duration={4} borderRadius="5px" />
                                            </span>
                                        </div>
                                    )}
                                </div>
                            </>

                        }
                        <div className="analytics-card analytics-card-pink">
                            <div className="d-flex align-items-center gap-1">
                                <div className="analytics-box" style={{ backgroundColor: "#FFC700" }}></div>
                                <div className="analytics-type-heading">Link Clicks</div>
                            </div>
                            {statsData?.link_clicks?.type ? (
                                <div className="d-flex align-items-center justify-content-start">
                                    <div className="analytics-count mb-1 me-1">{statsData?.link_clicks?.count}</div>
                                    <span
                                        className={`analytics-badge ${statsData?.link_clicks?.type === "up" ? 'analytics-badge-success' : 'analytics-badge-danger'} d-flex align-items-center justify-content-center`}
                                    >
                                        {statsData?.link_clicks?.type !== "-" && (
                                            <img src={statsData?.link_clicks?.type === "up" ? analyticsUp : analyticsDown} width="10px" alt="" />
                                        )}
                                        <span className="ms-1">{statsData?.link_clicks?.progress === "-" ? 0 : statsData?.link_clicks?.progress}%</span>
                                    </span>
                                </div>
                            ) : (
                                <div className="d-flex align-items-center justify-content-start">
                                    <h1 className="mb-1 me-2">
                                        <Skeleton width={50} height={40} className="mb-2" highlightColor="#FFC700" borderRadius="0.5rem" duration={4} />
                                    </h1>
                                    <span className="d-flex align-items-center justify-content-center">
                                        <Skeleton width={40} height={15} className="mb-2" baseColor="#D5F5E3" highlightColor="#0CBD5B" duration={4} borderRadius="5px" />
                                    </span>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="align-items-center gap-1 filter-for-desktop">
                        <FilterDropdowns dateFilters={dateFilters} statsData={statsData} analyticsGraphFilterClick={analyticsGraphFilterClick} getStats={getStats} loading={loading} />
                    </div>
                </div>
                <div className="d-flex align-items-center justify-content-between fl">
                    {data?.loaded == false ?
                        <Skeleton className="my-2 w-full d-flex analytics-main-chart-main flex-basis-100"
                            highlightColor="#ffffff"
                            baseColor="rgb(208, 233, 255)"
                            borderRadius="0.5rem" duration={4} height={screenWidth ? 50 : 30} />
                        :
                        <div class="d-flex analytics-main-chart-main flex-basis-100 w-100 flex-column align-items-center gap-2">
                            <Line id="analytics-main-chart"
                                data={data} options={originalOptions}
                                width="100%" height={screenWidth ? 50 : 30} />
                            <div className="graph-data-arrow mb-2">
                                <span>
                                    Time
                                </span>
                                &nbsp;
                                <img src={rightArrowIcon} alt="" srcset="" />
                            </div>
                        </div>
                    }
                    <div className="graph-data-arrow rotate_neg90">
                        <span>
                            Data
                        </span>
                        &nbsp;
                        <img src={rightArrowIcon} alt="" srcset="" />
                    </div>
                </div>
            </div>
            {(statsloading) || (topSources?.length > 0 && !statsloading) ?
                <div className="analytics-sections">
                    <div className="d-flex justify-content-between align-items-center">
                        <span>Referrers</span>
                        <span>
                            <CustomTooltip message={[{ title: "Referrers", msg: "When a person clicks on a link to your site, the referrer information is typically recorded, allowing you to see where your traffic is coming from." }, { title: "Direct", msg: "Direct traffic appears in your analytics when a visitor arrives at your site without a traceable referrer." }]}>
                                <img src={infoIcon} alt="" className='cursor-pointer' />
                            </CustomTooltip>
                        </span>
                    </div>

                    {topSources && topSources.length > 0 ? (
                        <div className="analytics-sections-items-main">
                            {topSources.map((val, index) => (
                                <div className="analytics-sections-item" key={index}>
                                    <div className="d-flex align-items-center gap-1">
                                        <img src={val?.icon} width="18px" alt="" />
                                        <span>{val?.source}</span>
                                    </div>
                                    <div className="d-flex align-items-center gap-1">
                                        <span>{val?.count}</span>
                                        <span className="box-ededed">{val?.percent}%</span>
                                    </div>
                                </div>
                            ))}
                        </div>
                    ) : (
                        <div className="analytics-sections-items-main">
                            {[65, 45, 23, 15, 9.8].map((width, idx) => (
                                <div className="analytics-sections-item" key={idx}>
                                    <div className="d-flex align-items-center gap-1">
                                        <Skeleton
                                            width={18}
                                            height={18}
                                            className="rounded-circle"
                                            baseColor="rgba(12, 138, 247, 0.14)"
                                            highlightColor="rgba(12, 138, 247, 0.19)"
                                            borderRadius="50%"
                                            duration={4}
                                        />
                                        <Skeleton
                                            width={70}
                                            height={20}
                                            baseColor="rgba(12, 138, 247, 0.14)"
                                            highlightColor="rgba(12, 138, 247, 0.19)"
                                            duration={4}
                                        />
                                    </div>
                                    <div className="d-flex align-items-center gap-1">
                                        <Skeleton
                                            width={30}
                                            height={20}
                                            baseColor="rgba(12, 138, 247, 0.14)"
                                            highlightColor="rgba(12, 138, 247, 0.19)"
                                            duration={4}
                                        />
                                        <Skeleton
                                            width={30}
                                            height={20}
                                            baseColor="rgba(12, 138, 247, 0.14)"
                                            highlightColor="rgba(12, 138, 247, 0.19)"
                                            duration={4}
                                        />
                                    </div>
                                </div>
                            ))}
                        </div>
                    )}
                </div> : null}
            <div className="multiple-analytics-sections">
                {(statsloading) || (topCountries?.length > 0 && !statsloading) ?
                    <div className="analytics-sections">
                        <div className="d-flex justify-content-between align-items-center">
                            <span>Country</span>
                            <span>
                                <CustomTooltip message={[{ title: "Country", msg: "The geographic location of your visitors" }]}>
                                    <img src={infoIcon} alt="" className='cursor-pointer' />
                                </CustomTooltip>
                            </span>
                        </div>

                        {topCountries && topCountries.length > 0 ? (
                            <div className="analytics-sections-items-main">
                                {topCountries.map((val, index) => (
                                    <div className="analytics-sections-item analytics-progress" key={index}>
                                        <div style={{ width: val?.percent + '%' }} className="d-flex align-items-center analytics-progress-main gap-1">
                                            <img
                                                src={require(`../../assets/img/flags/${val?.source}.png`)}
                                                width="22px"
                                                alt=""
                                            />
                                            <span className='text-nowrap'>{val?.country_name}</span>
                                        </div>
                                        <div className="d-flex align-items-center gap-1 analytics-progress-end">
                                            <span>{val?.count}</span>
                                            {/* <span className="box-ededed">{val?.percent}%</span> */}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        ) : (
                            <div className="analytics-sections-items-main">
                                {Array(3).fill(null).map((width, idx) => (
                                    <div key={idx} className="analytics-sections-item p-0 analytics-progress">
                                        <div className="w-100">
                                            <Skeleton width={"45%"} height={43}
                                                className="align-middle w-100"
                                                baseColor="rgba(12, 138, 247, 0.14)"
                                                highlightColor="rgba(12, 138, 247, 0.19)"
                                                borderRadius="0.5rem"
                                                duration={4}
                                            />
                                        </div>
                                        <div className="analytics-progress-end">
                                            0%
                                        </div>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div> : null}
                <div className="analytics-sections">
                    <div className="d-flex justify-content-between align-items-center">
                        <span>Devices</span>
                        <span>
                            <CustomTooltip message={[{ title: "Devices", msg: "The type of device used to access your site" }]}>
                                <img src={infoIcon} alt="" className='cursor-pointer' />
                            </CustomTooltip>
                        </span>
                    </div>

                    {devices && Object?.keys(devices)?.length !== 0 ?
                        <div className="analytics-sections-items-main">


                            <div className="analytics-sections-item analytics-progress">
                                <div style={{ width: devices?.desktop + '%' }} className="d-flex align-items-center analytics-progress-main gap-1">
                                    <img src={desktopIcon} alt="" />
                                    <span className="font-weight-500 ms-2">Desktop</span>
                                </div>

                                <div className="d-flex align-items-center gap-1 analytics-progress-end">
                                    {devices?.desktop}%
                                </div>
                            </div>
                            <div className="analytics-sections-item analytics-progress">
                                <div style={{ width: devices?.mobile + '%' }} className="d-flex align-items-center analytics-progress-main gap-1">
                                    <img src={mobileIcon} alt="" />
                                    <span className="font-weight-500 ms-2">Mobile</span>
                                </div>

                                <div className="d-flex align-items-center gap-1 analytics-progress-end">
                                    {devices?.mobile}%
                                </div>
                            </div>
                            <div className="analytics-sections-item analytics-progress">
                                <div style={{ width: devices?.tablet + '%' }} className="d-flex align-items-center analytics-progress-main gap-1">
                                    <img src={tabletIcon} alt="" />
                                    <span className="font-weight-500 ms-2">Tablet</span>
                                </div>

                                <div className="d-flex align-items-center gap-1 analytics-progress-end">
                                    {devices?.tablet}%
                                </div>
                            </div>
                        </div>
                        :
                        <div className="analytics-sections-items-main">
                            {Array(3).fill(null).map((width, idx) => (
                                <div key={idx} className="analytics-sections-item p-0 analytics-progress">
                                    <div className="w-100">
                                        <Skeleton width={"45%"} height={43}
                                            className="align-middle w-100"
                                            baseColor="rgba(12, 138, 247, 0.14)"
                                            highlightColor="rgba(12, 138, 247, 0.19)"
                                            borderRadius="0.5rem"
                                            duration={4}
                                        />
                                    </div>
                                    <div className="analytics-progress-end">
                                        0%
                                    </div>
                                </div>
                            ))}
                        </div>
                    }
                </div>

            </div>

            <div className="multiple-analytics-sections">

                <div className="analytics-sections">
                    <div className="d-flex justify-content-between align-items-center">
                        <span>Browsers</span>
                        <span>
                            <CustomTooltip message={[{ title: "Browsers", msg: "The web browsers used by your visitors" }]}>
                                <img src={infoIcon} alt="" className='cursor-pointer' />
                            </CustomTooltip>
                        </span>
                    </div>
                    {browsers &&
                        Object.keys(browsers)?.length > 0 ?
                        <div className="analytics-sections-items-main">
                            <div className="analytics-sections-item analytics-progress">
                                <div style={{ width: browsers?.Safari + '%' }} className="d-flex align-items-center analytics-progress-main gap-1">
                                    <img src={safariIcon} alt="" />
                                    <span className="font-weight-500 ms-2">Safari</span>
                                </div>
                                <div className="d-flex align-items-center gap-1 analytics-progress-end">
                                    {browsers?.Safari}%
                                </div>
                            </div>
                            <div className="analytics-sections-item analytics-progress">
                                <div style={{ width: browsers?.Chrome + '%' }} className="d-flex align-items-center analytics-progress-main gap-1">
                                    <img src={chromeIcon} alt="" />
                                    <span className="font-weight-500 ms-2">Chrome</span>
                                </div>
                                <div className="d-flex align-items-center gap-1 analytics-progress-end">
                                    {browsers?.Chrome}%
                                </div>
                            </div>
                            <div className="analytics-sections-item analytics-progress">
                                <div style={{ width: browsers?.Edge + '%' }} className="d-flex align-items-center analytics-progress-main gap-1">
                                    <img src={edgeIcon} alt="" />
                                    <span className="font-weight-500 ms-2">Edge</span>
                                </div>
                                <div className="d-flex align-items-center gap-1 analytics-progress-end">
                                    {browsers?.Edge}%
                                </div>
                            </div>
                        </div>
                        :
                        <div className="analytics-sections-items-main">
                            {Array(3).fill(null).map((width, idx) => (
                                <div key={idx} className="analytics-sections-item p-0 analytics-progress">
                                    <div className="w-100">
                                        <Skeleton width={"45%"} height={43}
                                            className="align-middle w-100"
                                            baseColor="rgba(12, 138, 247, 0.14)"
                                            highlightColor="rgba(12, 138, 247, 0.19)"
                                            borderRadius="0.5rem"
                                            duration={4}
                                        />
                                    </div>
                                    <div className="analytics-progress-end">
                                        0%
                                    </div>
                                </div>
                            ))}
                        </div>
                    }
                </div>
                <div className="analytics-sections">
                    <div className="d-flex justify-content-between align-items-center">
                        <span>Operating Systems</span>
                        <span>
                            <CustomTooltip message={[{ title: "Operating System", msg: "The operating system running on visitors' devices" }]}>
                                <img src={infoIcon} alt="" className='cursor-pointer' />
                            </CustomTooltip>
                        </span>
                    </div>
                    <div className="analytics-sections-items-main">
                        {!statsloading ? (
                            operatingSystems && Object.keys(operatingSystems)?.length > 0 ? (
                                Object.keys(operatingSystems).map((val, index) => (
                                    <div className="analytics-sections-item analytics-progress" key={index}>
                                        <div
                                            style={{ width: `${operatingSystems[val]}%` }}
                                            className="d-flex align-items-center analytics-progress-main gap-1"
                                        >
                                            <i className={`fa-brands fa-${getOsIcon(val?.os_name)}`} style={{ fontSize: "18px" }}></i>
                                            <span className="font-weight-500 ms-2 text-capitalize">{val}</span>
                                        </div>
                                        <div className="d-flex align-items-center gap-1 analytics-progress-end">
                                            {operatingSystems[val]}%
                                        </div>
                                    </div>
                                ))
                            ) : (
                                /* Default placeholder or empty state */
                                <>
                                    {["windows", "android", "iOS"].map((val, index) => (
                                        <div className="analytics-sections-item analytics-progress" key={index}>
                                            <div
                                                style={{ width: '0%' }}
                                                className="d-flex align-items-center analytics-progress-main gap-1"
                                            >
                                                <i className={`fa-brands fa-${getOsIcon(val)}`} style={{ fontSize: "18px" }}></i>
                                                <span className="font-weight-500 ms-2 text-capitalize">{val}</span>
                                            </div>
                                            <div className="d-flex align-items-center gap-1 analytics-progress-end">
                                                0%
                                            </div>
                                        </div>
                                    ))}
                                </>
                            )
                        ) : (
                            Array(3).fill(null).map((_, index) => (
                                <div key={index} className="analytics-sections-item p-0 analytics-progress">
                                    <div className="w-100">
                                        <Skeleton
                                            width={"45%"}
                                            height={43}
                                            className="align-middle w-100"
                                            baseColor="rgba(12, 138, 247, 0.14)"
                                            highlightColor="rgba(12, 138, 247, 0.19)"
                                            borderRadius="0.5rem"
                                            duration={4}
                                        />
                                    </div>
                                    <div className="analytics-progress-end">
                                        0%
                                    </div>
                                </div>
                            ))
                        )}

                    </div>
                </div>
            </div>
        </div>
    )
}

export default AnalyticsMain