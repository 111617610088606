import { useState } from "react"
import { useDispatch } from "react-redux"
import ErrorMessage from "../components/errors/errorMessage"
import { hideLoader, showLoader } from "../components/loader"
import returnIcon from "../assets/img/modals/return.svg"
import { apiUrl } from "../utils/url"
import { useAlert } from "../contexts/AlertContext"

const Accounts = ({ accountsModalOpen }) => {
    const dispatch = useDispatch()
    const [error, setError] = useState([])
    const [value, setValue] = useState({ name: localStorage.user, email: localStorage.email });
    const { setAlert } = useAlert();

    const handleChange = (e) => setValue({ ...value, [e.target.name]: e.target.value })
    const closeAccountsModal = (e) => dispatch({ type: "RESET_ACCOUNTS_MODAL" });

    const updateSettings = (e) => {
        e.preventDefault();
        showLoader();
        const api = apiUrl + "/account-settings";
        var myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${localStorage?.getItem("token")}`);
        const formdata = new FormData();
        formdata.append("name", value?.name)
        formdata.append("email", value?.email)

        const setOptions = {
            method: "POST",
            headers: myHeaders,
            body: formdata
        }

        fetch(api, setOptions)
            .then(async (res) => {
                const response = await res?.json();
                if (response?.status_code == 200) {
                    localStorage.setItem('email', value?.email);
                    localStorage.setItem('user', value?.name);
                    setAlert({ type: 'success', title: response.status_message });
                    hideLoader();
                }
                else {
                    const errors = Object.values(response.errors);
                    setError(errors);
                    setAlert({ type: 'error', title: response.status_message });
                    hideLoader()
                }
            }).catch((errors) => {
                hideLoader()
            })
    }



    return (
        <section class="container h-100 account-form" style={{ overflowY: "auto" }}>
            <div className="container settings-section" style={{ paddingLeft: "30px", paddingRight: "30px", paddingBottom: "30px" }}>
                <form action="" method="post" onSubmit={updateSettings}>
                    <ErrorMessage error={error} />
                    <div class="">
                        <b class="acount-header settings-section-heading">Profile</b>
                        <div class="form-group mt-3">
                            <label htmlFor="name">Account Name</label>
                            <input type="text" id="name" name="name" class="form-control" onChange={handleChange} value={value?.name} />
                        </div>

                        <div class="form-group mt-2">
                            <label htmlFor="email">Email Address</label>
                            <input type="text" id="email" name="email" class="form-control" onChange={handleChange} value={value?.email} />
                        </div>

                        <button type="submit" name="submit" class="settings-button-primary btn-primary " style={{ marginTop: "20px" }}>Update<small><img src={returnIcon} alt="" /></small>
                        </button>
                    </div>
                </form>
            </div>
        </section>
    )
}
export default Accounts