import PageHeader from "../components/layout/page-header"
import dotsIcon from "../assets/img/dots.svg"
import chevron_down_greyIcon from "../assets/img/chevron-down-gray.svg"
import "../styles/monetization.css"
import * as $ from "jquery"
import { useEffect, useState } from "react"
import { useHistory, useLocation } from "react-router-dom"
import Skeleton from "react-loading-skeleton"
import { useDispatch, useSelector } from "react-redux"
import getProducts from "../api/getProducts"
import { hideLoader } from "../components/loader"
import { audiencePosts as productPosts } from "../api/audience"
import { apiUrl, pageUrl } from "../utils/url"
import CommunityFooter from "../components/community/footer"
import HeaderSearch from "../components/community/headerSearch"
import ConfirmationModal from "../components/modals/confirmation-modal"
import ConnectModal from "../components/modals/connect-modal"
import upgradeSubmit from "../components/upgrade/upgrade-button"
import { disconnectPayout } from "../components/upgrade/upgrade-button"
import PayoutSettings from "../components/payout-settings"
import DisonnectModal from "../components/modals/disconnect-modal"
import ProductTypeModal from "../components/products/product_type_modal"
import yellowCircle from "../assets/img/yellow-circle.svg"
import redCircle from "../assets/img/red-circle.svg"
import purpleCircle from "../assets/img/purple-circle.svg"
import defaultProductImage from "../assets/img/products/default-Product-image"
import Sidebar from "../components/layout/Sidebar"
import tickIcon from "../assets/img/tick.svg"
import TeamModal from "../components/teammember/team_modal"
import EmptyState from "./empty-state"
import { useAlert } from "../contexts/AlertContext"
import { get_currency_symbol } from "../components/products/product-settings/product-tabs/details-page"

const mainPath = "products";
const subPath = "newProducts";

let params = [];

const Products = ({ id }) => {
    const dispatch = useDispatch()
    const history = useHistory()
    let location = useLocation();
    const [title, setTitle] = useState();
    const [loading, setloading] = useState(true);
    const [isEdit, setIsEdit] = useState({ is: false, id: null })
    const { slug, basePath } = useSelector(state => state?.slugReducer);
    const [allProducts, setallProducts] = useState([]);
    const [payoutSettingsModal, setpayoutSettingsModal] = useState(false);
    const { productModalReducer, stripeConnectReducer } = useSelector(state => state);
    const pro = useSelector(state => state?.proReducer);
    const newUser = useSelector(state => state?.newUserReducer);
    const planType = useSelector(state => state?.plantypeReducer);
    const [confirmationModalShow, setConfirmationModalShow] = useState({ show: false, text: null, deleteAll: null});
    const [, setisConfirmMessage] = useState(false);
    const [connectModal, setconnectModal] = useState(false);
    const [disconnectModal, setdisconnectModal] = useState(false);
    const [showProductType, setShowProductType] = useState(false);
    const [activeFilter, setActiveFilter] = useState('all');
    const [showModalasd, setShowModalasd] = useState(false)
    const [selectAll, setSelectAll] = useState(false)
    const isEligible = useSelector(state => state?.eligibilityReducer);
    const customizeDomainsReducer = useSelector(state => state?.customizeDomainsReducer);
    const { setAlert } = useAlert();
    const [filter, setfilter] = useState({
        page: 1,
        orderBy: null,
        filter: null,
        orderType: "desc",
        count: localStorage?.products_count ?? 10
    })
    const openProductType = (e) => {
        e.preventDefault();
        if (!pro) {
            history.push(basePath + "/settings")
        }else{
        localStorage.removeItem('product_id');
        localStorage.removeItem('edit');
        setShowProductType(true)
        }
    }
    const closeProductType = () => setShowProductType(false)

    useEffect(() => {
    if (!pro) {
        setShowModalasd(true);
    }else {
        setShowModalasd(false);
        params = [];
        if (slug) {
            for (const key in filter) {
                if (Object.hasOwnProperty.call(filter, key)) {
                    if (filter[key])
                        params.push(`${[key]}=${filter[key]}`)
                }
            };
            if (filter?.page) callAllProducts()
        }
    }
    }, [slug, filter,pro, planType]);

    const callAllProducts = () => {
        getProducts(slug, params?.join("&")).then((response) => {
            setloading(false)
            setallProducts(response);
        })
    }

    const filterToggler = (e, value) => {
        const isDesc = e.target.classList.contains("sortable-col-desc");
    
        setfilter({ ...filter, "orderBy": value, orderType: isDesc ? "asc" : "desc" });
            
        const imgElement = e.target.querySelector('img');
        console.log("imgElement",imgElement)
        if (imgElement) {
            e.target.classList.toggle('sortable-col-desc', !isDesc);
        }
    };
    

    const deleteProduct = (id) => {
        const api = apiUrl + "/ProductFlow/delete/" + id;
        var myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${localStorage?.getItem("token")}`);
        const setOptions = {
            method: "DELETE",
            headers: myHeaders,
        }
        productPosts(setAlert, api, hideLoader, setOptions).then(() => {
            setAlert({ type: 'success', title: `Product Deleted` });
            setConfirmationModalShow({ show: false, text: null, id: null, deleteAll: null })
            callAllProducts();
        }).catch((err) => {
            setAlert({ type: 'error', title: `${err}` });
            setConfirmationModalShow({ show: false, text: null, id: null, deleteAll: null })
        })
    }
    const deleteAllProducts = () => {
        const api = apiUrl + "/ProductFlow/delete-all/" + slug;
        var myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${localStorage?.getItem("token")}`);
        const setOptions = {
            method: "DELETE",
            headers: myHeaders,
        }
        productPosts(setAlert, api, hideLoader, setOptions).then(() => {
            setConfirmationModalShow({ show: false, text: null, id: null, deleteAll: null })
            callAllProducts();
            setSelectAll(false)
        }).catch(() => {
                setConfirmationModalShow({ show: false, text: null, id: null, deleteAll: null })
                hideLoader()
            })
    }
    const copyEmail = (email) => {
        const domain = customizeDomainsReducer?.domain;
        let link = domain ? (!domain?.includes('https://') ? 'https://'+domain : domain)+'/'+email : `${pageUrl}/${slug}/${email}`;
        navigator.clipboard.writeText(link).then((response => setAlert({ type: 'success', title: `Product Link Copied to Clipboard` }) ))
    }

    const editProduct = async (values) => {
        setIsEdit({ is: true, id: values?.id })
        sessionStorage.setItem("isEdit", JSON.stringify(isEdit))
        history.push({
            pathname: basePath + "/editProduct", state: {
                product_id: values?.id,
                edit: { is: true, id: values?.id }
            }
        });
    }

    const onConnect = (e) => {
        if (stripeConnectReducer?.productType === "paid" && stripeConnectReducer?.paypal === 0 && stripeConnectReducer?.stripe === 0) {
            setconnectModal(false)
            setpayoutSettingsModal(true)
        }
        else upgradeSubmit(e, null, slug, "connect")
    }

    const onDisconnect = (e) => {
        e.preventDefault();
        disconnectPayout(e, slug, setdisconnectModal);
    }

    const connectStripe = (e) => {
        upgradeSubmit(e, null, slug, "connect", "stripe")
    }
    const connectPayPal = (e) => {
        upgradeSubmit(e, null, slug, "connect", "paypal")
    }
    const selectFilter = (e) => {

        const value = e.target.dataset?.value !== "all-products" ? e.target.dataset?.value?.toLowerCase() : null;
        setfilter({ ...filter, filter: value, page: 1 });
        setActiveFilter(value);
    }
    const upgradeHandleClick = (e) => {
        e.preventDefault();
        history.push(basePath + "/settings")
    }
    return (
        <>
            <div id="wrapper">
                <PageHeader />
                <div class="monetization-main new-sidebar">
                    <Sidebar from="products" />
                    <section class="monetization-section">
                        <div class="section-header sec-head-u">
                            <h1 class="mb-0">Products</h1>
                            <div className="d-flex full-flex">
                                <HeaderSearch filter={filter} setfilter={setfilter} name="products" />
                                <div class="align-items-center justify-content-end">
                                    <button className="btn btn-primary btn-semi-rounded import-btn align-items-center
                                        justify-content-center newbtn-u" id="btn-newProductModal" onClick={openProductType}>
                                        New Product
                                    </button>
                                </div>
                            </div>
                        </div>
                        {showModalasd && <TeamModal showModal={showModalasd} upgradeHandleClick={upgradeHandleClick} heading={'Upgrade to access Products.'} text={' Products is a Pro Feature<br /> Upgrade to Zaap Pro to unlock this feature.'} isUltra={false}/>}
                        {!allProducts.data?.length > 0 && !showModalasd && (filter?.filter === 'All' || !filter?.filter)?
                            <div class="section-main sec-main-u p-0 position-relative">
                                <EmptyState from={"products"} firstText={'Rome wasn’t built in a day.'} secondText={"Start monetising your audience by selling digital products, coaching calls, and offering lead magnets."} buttonText={"New Product"} clickedFunction={openProductType}/>
                            </div>
                        :
                        <div class="section-main sec-main-u">
                            <div class="d-flex table-responsive overflow-visible">
                                <table className="table">
                                        <thead class="d-md-table-header-group  dashview-u">
                                            <tr>
                                                <th className="product-t-head thead-padd" style={{height:"50px"}}>
                                                    {allProducts?.data?.length > 0 &&
                                                        <label className="custom-checkbox checkbox">
                                                            <input type="checkbox" checked={selectAll} name="select-all" onChange={(e) => {setSelectAll(e.target.checked)}}/>
                                                            <span class="checkmark"></span>
                                                        </label>
                                                    }
                                                    {selectAll ?
                                                        <button className="delete-all-btn" onClick={() => setConfirmationModalShow({show: true, text: "Are you sure you want to delete All products?", id: "", callBackTo: "delete", deleteAll: true})}>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="16" viewBox="0 0 14 16" fill="none">
                                                                <path d="M8.77364 0.564453C9.54452 0.564453 10.1761 1.17108 10.2319 1.94053L10.2359 2.05151V3.53857H12.4293C12.8331 3.53857 13.1605 3.87146 13.1605 4.2821C13.1605 4.6634 12.8782 4.97767 12.5146 5.02062L12.4293 5.02562H12.3789L11.8418 12.6728C11.736 14.179 10.54 15.3569 9.07236 15.4313L8.92474 15.435H4.23572C2.75088 15.435 1.51038 14.3057 1.33282 12.8223L1.31862 12.6728L0.780849 5.02562H0.731137C0.327341 5.02562 0 4.69274 0 4.2821C0 3.90079 0.282235 3.58652 0.645858 3.54357L0.731137 3.53857H2.92455V2.05151C2.92455 1.26756 3.52104 0.625299 4.27767 0.568532L4.38682 0.564453H8.77364ZM10.9122 5.02562H2.24749L2.77719 12.5669C2.82639 13.2672 3.34705 13.8256 4.00996 13.9303L4.1217 13.9435L4.23572 13.948H8.92474C9.65355 13.948 10.2646 13.4038 10.3708 12.6822L10.3833 12.5669L10.9122 5.02562ZM5.11796 7.25621C5.49291 7.25621 5.80195 7.54324 5.84418 7.91303L5.84909 7.99974V10.9739C5.84909 11.3845 5.52175 11.7174 5.11796 11.7174C4.743 11.7174 4.43396 11.4304 4.39173 11.0606L4.38682 10.9739V7.99974C4.38682 7.5891 4.71416 7.25621 5.11796 7.25621ZM8.0425 7.25621C8.41745 7.25621 8.72649 7.54324 8.76873 7.91303L8.77364 7.99974V10.9739C8.77364 11.3845 8.4463 11.7174 8.0425 11.7174C7.66755 11.7174 7.35851 11.4304 7.31627 11.0606L7.31137 10.9739V7.99974C7.31137 7.5891 7.63871 7.25621 8.0425 7.25621ZM8.77364 2.05151H4.38682V3.53857H8.77364V2.05151Z" fill="#FF2020"/>
                                                            </svg>
                                                            <span>Delete All</span>
                                                        </button>
                                                        :
                                                        'Product'
                                                    }
                                                </th>
                                                <th className={`thead-padd resp d-none-u ${selectAll ? "invisible" : ''} sortable-col`} onClick={(e) => filterToggler(e, "price")}>
                                                    <span className="sort-u">
                                                        Price&nbsp;&nbsp;<img src={chevron_down_greyIcon} alt="" />
                                                    </span>                       
                                                </th>
                                                <th className={`thead-padd resp d-none-u ${selectAll ? "invisible" : ''} sortable-col`} onClick={(e) => filterToggler(e, "sales")}>
                                                    <span className="sort-u">
                                                        Sales&nbsp;&nbsp;<img src={chevron_down_greyIcon} alt="" />
                                                    </span>    
                                                </th>
                                                <th className={`thead-padd resp d-none-u ${selectAll ? "invisible" : ''} sortable-col`} onClick={(e) => filterToggler(e, "revenue")}>
                                                    <span className="sort-u">
                                                        Revenue&nbsp;&nbsp;<img src={chevron_down_greyIcon} alt="" />
                                                    </span>
                                                </th>
                                                <th className={`thead-padd hide-for-tab ${selectAll ? " invisible": ''}`}>
                                                    <div class="filter-dropdown dropdown-toggle noselect sort-u2" id="filter-dropdown" data-bs-toggle="dropdown" aria-expanded="false">
                                                        <span class="">Filter:</span>
                                                        <span class="filter-dropdown-menu-selected filtname ms-2 font-weight-500">{filter?.filter && filter?.filter !== 'all' ? (filter?.filter === 'lead' ? 'Lead Magnets' : filter?.filter === 'digital' ?  'Digital Products' : 'Meetings') : 'All'}</span>
                                                        <img src={chevron_down_greyIcon} width="10px" alt="" className="ms-1" />
                                                    </div>
                                                    <ul class="dropdown-menu filter-dropdown-menu" aria-labelledby="filter-dropdown">
                                                    <li>
                                                        <a className={`dropdown-item ${activeFilter === 'all' ? 'active' : ''}`} data-value="all" onClick={selectFilter}>
                                                            <span class="me-5 d-flex align-items-center justify-content-betwen" data-value="all" onClick={selectFilter}>
                                                            {activeFilter === 'all'  && <img src={tickIcon} alt="" data-value="all" onClick={selectFilter} />}&nbsp;&nbsp;All</span>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a className={`dropdown-item ${activeFilter === 'meeting' ? 'active' : ''}`} data-value="meeting" onClick={selectFilter}>
                                                            <span class="me-5 d-flex align-items-center justify-content-betwen" data-value="meeting" onClick={selectFilter}>
                                                            {activeFilter === 'meeting'  && <img src={tickIcon} alt="" data-value="meeting" onClick={selectFilter} />}&nbsp;&nbsp;Meeting</span>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a className={`dropdown-item ${activeFilter === 'lead' ? 'active' : ''}`} data-value="lead" onClick={selectFilter}>
                                                            <span class="me-5 d-flex align-items-center justify-content-betwen" data-value="lead" onClick={selectFilter}>
                                                            {activeFilter === 'lead'  && <img src={tickIcon} alt="" data-value="lead" onClick={selectFilter} />}&nbsp;&nbsp;Lead Magnet</span>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a className={`dropdown-item ${activeFilter === 'digital' ? 'active' : ''}`} data-value="digital" onClick={selectFilter}>
                                                            <span class="me-5 d-flex align-items-center justify-content-betwen" data-value="digital" onClick={selectFilter}>
                                                            {activeFilter === 'digital'  && <img src={tickIcon} alt="" data-value="digital" onClick={selectFilter} />}&nbsp;&nbsp;Digital Products</span>
                                                        </a>
                                                    </li>
                                                    </ul>
                                                    </th>
                                                <th className="th-end-u"></th>
                                            </tr>
                                        </thead>
                                    {
                                        loading ?

                                            <tbody>

                                                <tr>
                                                    <td className="d-md-table-cell">
                                                        <Skeleton height={40}
                                                            borderRadius="10px"
                                                            highlightColor="#ffffff"
                                                            duration={4}
                                                        />
                                                    </td>
                                                    <td className="d-md-table-cell d-none">
                                                        <Skeleton height={40}
                                                            borderRadius="10px"
                                                            highlightColor="#ffffff"
                                                            duration={4}
                                                        /></td>
                                                    <td className="d-md-table-cell d-none">
                                                        <Skeleton height={40}
                                                            borderRadius="10px"
                                                            highlightColor="#ffffff"
                                                            duration={4}
                                                        /></td>
                                                    <td className="d-md-table-cell d-none">
                                                        <Skeleton height={40}
                                                            borderRadius="10px"
                                                            highlightColor="#ffffff"
                                                            duration={4}
                                                        /></td>
                                                    <td className="d-md-table-cell d-none">
                                                        <div class="table-action-dropdown dropdown-toggle noselect d-flex align-items-center justify-content-end" id="table-action-dropdown" data-bs-toggle="dropdown" aria-expanded="false">
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <Skeleton height={40}
                                                            borderRadius="10px"
                                                            highlightColor="#ffffff"
                                                            duration={4}
                                                        />
                                                    </td>
                                                    <td className="d-md-table-cell d-none">
                                                        <Skeleton height={40}
                                                            borderRadius="10px"
                                                            highlightColor="#ffffff"
                                                            duration={4}
                                                        /></td>
                                                    <td className="d-md-table-cell d-none">
                                                        <Skeleton height={40}
                                                            borderRadius="10px"
                                                            highlightColor="#ffffff"
                                                            duration={4}
                                                        /></td>
                                                    <td className="d-md-table-cell d-none">
                                                        <Skeleton height={40}
                                                            borderRadius="10px"
                                                            highlightColor="#ffffff"
                                                            duration={4}
                                                        /></td>
                                                    <td className="d-md-table-cell d-none">
                                                        <div class="table-action-dropdown dropdown-toggle noselect d-flex align-items-center justify-content-end" id="table-action-dropdown" data-bs-toggle="dropdown" aria-expanded="false">
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <Skeleton height={40}
                                                            borderRadius="10px"
                                                            highlightColor="#ffffff"
                                                            duration={4}
                                                        />
                                                    </td>
                                                    <td className="d-md-table-cell d-none">
                                                        <Skeleton height={40}
                                                            borderRadius="10px"
                                                            highlightColor="#ffffff"
                                                            duration={4}
                                                        /></td>
                                                    <td className="d-md-table-cell d-none">
                                                        <Skeleton height={40}
                                                            borderRadius="10px"
                                                            highlightColor="#ffffff"
                                                            duration={4}
                                                        /></td>
                                                    <td className="d-md-table-cell d-none">
                                                        <Skeleton height={40}
                                                            borderRadius="10px"
                                                            highlightColor="#ffffff"
                                                            duration={4}
                                                        /></td>
                                                    <td className="d-md-table-cell d-none">
                                                        <div class="table-action-dropdown dropdown-toggle noselect d-flex align-items-center justify-content-end" id="table-action-dropdown" data-bs-toggle="dropdown" aria-expanded="false">
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <Skeleton height={40}
                                                            borderRadius="10px"
                                                            highlightColor="#ffffff"
                                                            duration={4}
                                                        />
                                                    </td>
                                                    <td className="d-md-table-cell d-none">
                                                        <Skeleton height={40}
                                                            borderRadius="10px"
                                                            highlightColor="#ffffff"
                                                            duration={4}
                                                        /></td>
                                                    <td className="d-md-table-cell d-none">
                                                        <Skeleton height={40}
                                                            borderRadius="10px"
                                                            highlightColor="#ffffff"
                                                            duration={4}
                                                        /></td>
                                                    <td className="d-md-table-cell d-none">
                                                        <Skeleton height={40}
                                                            borderRadius="10px"
                                                            highlightColor="#ffffff"
                                                            duration={4}
                                                        /></td>
                                                    <td className="d-md-table-cell d-none">
                                                        <div class="table-action-dropdown dropdown-toggle noselect d-flex align-items-center justify-content-end" id="table-action-dropdown" data-bs-toggle="dropdown" aria-expanded="false">
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <Skeleton height={40}
                                                            borderRadius="10px"
                                                            highlightColor="#ffffff"
                                                            duration={4}
                                                        />
                                                    </td>
                                                    <td className="d-md-table-cell d-none">
                                                        <Skeleton height={40}
                                                            borderRadius="10px"
                                                            highlightColor="#ffffff"
                                                            duration={4}
                                                        /></td>
                                                    <td className="d-md-table-cell d-none">
                                                        <Skeleton height={40}
                                                            borderRadius="10px"
                                                            highlightColor="#ffffff"
                                                            duration={4}
                                                        /></td>
                                                    <td className="d-md-table-cell d-none">
                                                        <Skeleton height={40}
                                                            borderRadius="10px"
                                                            highlightColor="#ffffff"
                                                            duration={4}
                                                        /></td>
                                                    <td className="d-md-table-cell d-none">
                                                        <div class="table-action-dropdown dropdown-toggle noselect d-flex align-items-center justify-content-end" id="table-action-dropdown" data-bs-toggle="dropdown" aria-expanded="false">
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <Skeleton height={40}
                                                            borderRadius="10px"
                                                            highlightColor="#ffffff"
                                                            duration={4}
                                                        />
                                                    </td>
                                                    <td className="d-md-table-cell d-none">
                                                        <Skeleton height={40}
                                                            borderRadius="10px"
                                                            highlightColor="#ffffff"
                                                            duration={4}
                                                        /></td>
                                                    <td className="d-md-table-cell d-none">
                                                        <Skeleton height={40}
                                                            borderRadius="10px"
                                                            highlightColor="#ffffff"
                                                            duration={4}
                                                        /></td>
                                                    <td className="d-md-table-cell d-none">
                                                        <Skeleton height={40}
                                                            borderRadius="10px"
                                                            highlightColor="#ffffff"
                                                            duration={4}
                                                        /></td>
                                                    <td className="d-md-table-cell d-none">
                                                        <div class="table-action-dropdown dropdown-toggle noselect d-flex align-items-center justify-content-end" id="table-action-dropdown" data-bs-toggle="dropdown" aria-expanded="false">
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <Skeleton height={40}
                                                            borderRadius="10px"
                                                            highlightColor="#ffffff"
                                                            duration={4}
                                                        />
                                                    </td>
                                                    <td className="d-md-table-cell d-none">
                                                        <Skeleton height={40}
                                                            borderRadius="10px"
                                                            highlightColor="#ffffff"
                                                            duration={4}
                                                        /></td>
                                                    <td className="d-md-table-cell d-none">
                                                        <Skeleton height={40}
                                                            borderRadius="10px"
                                                            highlightColor="#ffffff"
                                                            duration={4}
                                                        /></td>
                                                    <td className="d-md-table-cell d-none">
                                                        <Skeleton height={40}
                                                            borderRadius="10px"
                                                            highlightColor="#ffffff"
                                                            duration={4}
                                                        /></td>
                                                    <td className="d-md-table-cell d-none">
                                                        <div class="table-action-dropdown dropdown-toggle noselect d-flex align-items-center justify-content-end" id="table-action-dropdown" data-bs-toggle="dropdown" aria-expanded="false">
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <Skeleton height={40}
                                                            borderRadius="10px"
                                                            highlightColor="#ffffff"
                                                            duration={4}
                                                        />
                                                    </td>
                                                    <td className="d-md-table-cell d-none">
                                                        <Skeleton height={40}
                                                            borderRadius="10px"
                                                            highlightColor="#ffffff"
                                                            duration={4}
                                                        /></td>
                                                    <td className="d-md-table-cell d-none">
                                                        <Skeleton height={40}
                                                            borderRadius="10px"
                                                            highlightColor="#ffffff"
                                                            duration={4}
                                                        /></td>
                                                    <td className="d-md-table-cell d-none">
                                                        <Skeleton height={40}
                                                            borderRadius="10px"
                                                            highlightColor="#ffffff"
                                                            duration={4}
                                                        /></td>
                                                    <td className="d-md-table-cell d-none">
                                                        <div class="table-action-dropdown dropdown-toggle noselect d-flex align-items-center justify-content-end" id="table-action-dropdown" data-bs-toggle="dropdown" aria-expanded="false">
                                                        </div>
                                                    </td>
                                                </tr>

                                            </tbody>
                                            :
                                            <tbody>

                                                {allProducts?.data?.length > 0 && (
                                                    allProducts?.data?.map((val, index) => (
                                                        <tr key={index}>
                                                            <td>
                                                                <p class="mb-0 d-inline-flex align-items-center justify-content-start">
                                                                    <span class="d-flex align-items-start jusitfy-content-center flex-column">
                                                                        <span class="d-flex align-items-center">
                                                                            <img src={val?.cover_images[0]?.images || val?.cover_image || defaultProductImage} width="30px" height="30px" className="d-md-flex zaap-border-rounded border-radius-10 imp-border-u img-object-fit" alt="" />
                                                                            <span className="prodname">{val?.name}</span>
                                                                        </span>
                                                                        <span class="d-md-none d-flex text-lightgray">
                                                                        </span>
                                                                    </span>
                                                                </p>
                                                            </td>
                                                            <td class="d-md-table-cell d-none prodname">{val?.currency == "eur" ? '€' : val?.currency == "gbp" ? '£' : '$'}{val?.price}</td>
                                                            <td class="d-md-table-cell d-none prodname">{val?.sales}</td>
                                                            <td class="d-md-table-cell d-none prodname">{val?.currency == "eur" ? '€' : val?.currency == "gbp" ? '£' : '$'}{val?.revenue ? val?.revenue : 0}</td>
                                                            <td class={`d-md-table-cell d-none product-types hide-for-tab thead-padd ${val?.product_type}`}>  <p className="mb-0 filt_tag"> <img src={val?.product_type == 'Lead Magnet' ? redCircle : val?.product_type == 'Digital Product' ? yellowCircle : purpleCircle} alt="" />{val?.product_type} </p></td>

                                                            <td>
                                                                <div class="table-action-dropdown dropdown-toggle noselect d-flex align-items-center justify-content-end" id="table-action-dropdown" data-bs-toggle="dropdown" aria-expanded="false">
                                                                    <img src={dotsIcon} width="" alt="" />
                                                                </div>
                                                                <ul class="dropdown-menu" aria-labelledby="table-action-dropdown">
                                                                    <li><a className="dropdown-item" onClick={() => editProduct(val)}><span class="me-5">Edit Product</span></a></li>
                                                                    <li><a className="dropdown-item" onClick={() => copyEmail(val?.slug)}><span class="me-5">Copy Link</span></a></li>
                                                                    <li><a className="dropdown-item theme-active semi-bold" onClick={() =>
                                                                        setConfirmationModalShow({
                                                                            show: true, text: "Are you sure you want to delete this products?", id: val?.id, callBackTo: "delete", deleteAll: false
                                                                        })} ><span class="me-5">Delete Product</span></a></li>
                                                                </ul>
                                                            </td>
                                                        </tr>
                                                    )))}

                                            </tbody>
                                    }
                                </table>
                            </div>


                            <div class="section-footer d-flex align-items-center justify-content-between">
                                <CommunityFooter from={"products"} loading={loading} filter={filter} setfilter={setfilter} total={allProducts?.total} last_page={allProducts?.last_page} current_page={allProducts?.current_page}/>
                            </div>

                        </div>
                        }
                    </section>

                </div>
            </div>
            {showProductType && <ProductTypeModal isOpen={showProductType} onHide={closeProductType} />}

            {payoutSettingsModal && <PayoutSettings show={payoutSettingsModal} setShow={setpayoutSettingsModal} connectStripe={connectStripe} setpayoutDisconnect={setdisconnectModal} connectPayPal={connectPayPal} stripeConnectReducer={stripeConnectReducer} />}

            {connectModal && <ConnectModal show={connectModal} setShow={setconnectModal} connect={onConnect} />}

            {disconnectModal && <DisonnectModal show={disconnectModal} setShow={setdisconnectModal} disconnect={onDisconnect} />}

            {confirmationModalShow?.show && <ConfirmationModal confirmationModalShow={confirmationModalShow} setShow={setConfirmationModalShow} setisConfirmMessage={setisConfirmMessage} callback={confirmationModalShow?.deleteAll ? deleteAllProducts :deleteProduct} />}
        </>
    )
}
export default Products