import React from 'react'
import { Modal } from 'react-bootstrap'
import updgradeIcon from '../../assets/img/upgrade-modal-icon.svg'
import returnIcon from "../../assets/img/modals/return.svg";

const DiscountPopup = ({ show, setShow, manageStripe }) => {
    const handleClose = () => setShow(false);
    return (
        <Modal className='downgrade-discount-modal' centered show={show?.show} onHide={handleClose}>
            <Modal.Header className='p-0 borderless d-flex flex-column align-items-start'>
                <img  className={`icon-shadow ${show?.type == 'congrats' && 'bg-green-upgrade'}`} src={updgradeIcon} alt="" srcset="" />
                <Modal.Title className='font-weight-500 mt-10px'>{show?.type == 'ask' ? "Before you downgrade.....":"Congratulations!"}</Modal.Title>
            </Modal.Header>
            <Modal.Body className='p-0 text-gray font-weight-500 mt-10px'>
                {show?.type == 'ask' ?
                    <>
                        <div>
                            As a thank you for staying with us, we’re offering you 2 months of free subscription instead of downgrading your plan.
                        </div>
                        <div className='mt-3'>
                            Got feedback?
                            &nbsp;
                            <a href="https://zaap.ai/support" target='_blank' className='text-primary'>
                                We’d love to hear it ↗
                            </a>
                        </div>
                    </>
                    :
                    <>
                        <div>
                            You’ve secured 2 months free on your Ultra plan!
                        </div>
                        <div className='mt-3'>
                            There will be no charges for the next 2 months, and your usual billing will resume afterward.
                        </div>
                    </>
                }

            </Modal.Body>
            <Modal.Footer className='p-0 mt-30px borderless justify-content-start align-items-center gap-1'>
            {show?.type == 'ask' ?
            <>
            <button onClick={(e) => manageStripe(e, 'downgrade')} type="button" className="m-0 close btn btn-grey btn-semi-rounded font-weight-500 fs-12px">
                    <span>Downgrade</span>
                </button>
                <button onClick={(e) => manageStripe(e, 'avail_discount')} type="submit" className="m-0  blue-btn">Claim 2 Months Free
                    <small className="blue-badge">
                        <img src={returnIcon} alt="" />
                    </small>
                </button>
                </>
                :
                <button onClick={handleClose} type="button" className="m-0 close btn btn-grey btn-semi-rounded font-weight-500 fs-12px">
                    <span>Close</span>
                    <small class="badge bg-light text-dark p-1 ms-2">Esc</small>
                </button>
                }
            </Modal.Footer>
        </Modal>
    )
}

export default DiscountPopup