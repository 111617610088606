const INIT_STATE = {};
const selectedEmailPlanReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case 'SET_SELECTED_EMAIL_PLAN':
            return action.payload
        case 'RESET_SELECTED_EMAIL_PLAN':
            return INIT_STATE
        default:
            return state
    }
}
export default selectedEmailPlanReducer