import React from 'react'
import BlueCheckboxIcon from '../../assets/img/blue-checkbox-icon.svg'
import YellowCheckboxIcon from '../../assets/img/yellow-checkbox-icon.svg'
import PurpleCheckboxIcon from '../../assets/img/purple-checkbox-icon.svg'
import OrangeCheckboxIcon from '../../assets/img/orange-checkbox-icon.svg'
import grayCheckboxIcon from '../../assets/img/gray-checkbox-icon.svg'
import ultraTooltip from '../../assets/img/ultra-tooltip.svg'
// import proTooltip from '../../assets/img/pro-tooltip.svg'
import { useEffect, useState } from "react";
import { hideLoader, showLoader } from '../../components/loader';
import { Alert } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { toast } from 'react-toastify';
import { apiUrl } from "../../utils/url";
import { useHistory } from 'react-router-dom';
import CustomTooltip from '../common/CustomTooltip'

export const Step7 = (moveNext) => {

    const [error, setError] = useState([])
    const history = useHistory()
    const pro = useSelector(state => state?.proReducer);
    const planType = useSelector(state => state?.plantypeReducer);
    const { slug } = useSelector(state => state?.slugReducer);
    const [monthlyPlan, setMonthlyPlan] = useState(true)
    const [yearlyPlan, setYearlyPlan] = useState(false)
    function togglePlans(e) {
        const selectedValue = e.target.value;
        if (selectedValue === 'yearly') {
            setYearlyPlan(true)
            setMonthlyPlan(false)
        } else if (selectedValue === 'monthly') {
            setMonthlyPlan(true)
            setYearlyPlan(false)
        }
    }
    const handleSubmit = (e, type) => {
        showLoader();
        setError([])
        var myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${localStorage?.getItem("token")}`)

        const formdata = new FormData();
        formdata.append('step', 6);
        formdata.append('type', type);
        formdata.append('plan_type', e.target.name);
        formdata.append("tolt_referral", window.tolt_referral ?? '');
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        fetch(apiUrl + "/walkthrough", requestOptions)
            .then(async (res) => {
                hideLoader()
                const response = await res?.json();
                if (response?.status_code == 200) {
                    if (type == 'free') {
                        const slug = localStorage?.getItem("slug")

                        localStorage.removeItem("lastStep")
                        localStorage.removeItem("currentStep")
                        localStorage.removeItem("walkthrough_step")
                        localStorage.setItem("walkthrough", 1)
                        history.push({
                            pathname: `/dashboard`,
                            state: { 
                                sidebarOpen: true,
                                welcomeModalOpen: true
                            }
                            // state: { congratsOpen: true }
                        });
                        history.go()
                        // }
                        hideLoader()

                        return
                    }
                    else{
                    location.href = response?.data?.url;

                    localStorage.setItem("subscription_from_walkthrough", 1);

                    // localStorage.removeItem("lastStep")
                    // localStorage.removeItem("currentStep")
                    // localStorage.removeItem("walkthrough_step")
                    localStorage.setItem("temp_slug", response?.data?.slug);
                    localStorage.setItem("slug", response?.data?.slug);
                    localStorage.setItem("last_activity", `/project/${response?.data?.slug}/page-editor`)
                    localStorage.setItem("walkthrough", 1)
                    localStorage.setItem("plan_type", e.target.name)
                    localStorage.setItem("period", type)
                }
                }
                else {
                    setError(getErrors(response?.errors))
                    toast.error(response?.status_message)
                }
            }).catch((err) => hideLoader())
    }
    // useEffect(() => {
    //     checkPlan();
    // }, [])
    const checkPlan = async () => {
        showLoader();
        var myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${localStorage?.getItem("token")}`);
        const res = await fetch(`${apiUrl}/project/get/plan/${slug ?? localStorage?.getItem("slug")}/?walkthrough=true`, {
            headers: myHeaders
        })
        const response = await res.json();
        if (response?.status_code == 200) {
            if (response?.data?.pro == 1) {
                const slug = localStorage?.getItem("slug")

                localStorage.removeItem("lastStep")
                localStorage.removeItem("currentStep")
                localStorage.removeItem("walkthrough_step")
                localStorage.setItem("walkthrough", 1)
                history.push({
                    pathname: `/dashboard`,
                    state: {
                        sidebarOpen: true,
                        welcomeModalOpen: true
                    }
                });
                history.go()
                hideLoader()

                return

            } else {
                hideLoader()
            }
        } else {
            hideLoader()
            console.log(response?.status_message)
            return;
        }
    }
    return (
        <>

            <div className="steps-card choosePlanBox" style={{ width: "740px" }} >
                <div class="counter">5</div>
                <h1 class="text-left newpageheading" style={{ marginTop: "11px", marginBottom: "0" }}>
                    Choose a Plan
                </h1>
                {/* <div className="plan-text-container">
                    <div className='choose-plan-type'>
                        <div class="up-in-toggle bg-f1f1f1 schedule-toggle w-50 btn-semi-rounded" style={{ padding: "5px 3px" }}>
                            <input type="radio" id="text_align_left" name="scheduleTime" onChange={togglePlans} checked={monthlyPlan} style={{ display: "none" }}  value={"monthly"} />
                            <label for="text_align_left" class="btn-semi-rounded newsletter-btn-semi-rounded " > <span className='choose-plan-type-text'> Monthly</span></label>
                            <input type="radio" id="text_align_right" name="scheduleTime" onChange={togglePlans} checked={yearlyPlan} style={{ display: "none" }} value={"yearly"}  />
                            <label for="text_align_right" class="btn-semi-rounded newsletter-btn-semi-rounded "  > <span className='choose-plan-type-text'> Yearly</span></label>
                        </div>
                    </div>
                    <div class="plan-box">
                        <svg className='mobsvg' fill="none" viewBox="0 0 387 133" xmlns="http://www.w3.org/2000/svg"><path d="m0 126.9c97.123 0 275.14 8.118 382-122.9" stroke="url(#a)" stroke-width="11"/><defs><linearGradient id="a" x1="310.58" x2="61.054" y1="105.93" y2="112.1" gradientUnits="userSpaceOnUse"><stop stop-color="#FFBA34" offset="0"/><stop stop-color="#18f" offset=".49745"/><stop stop-color="#5F5F5F" offset="1"/></linearGradient></defs></svg>
                        <svg className='tabsvg' xmlns="http://www.w3.org/2000/svg" width="700" height="130" viewBox="0 0 700 130" fill="none"><path d="M0 124.353C179.5 124.353 508.5 132.5 706 1" stroke="url(#paint0_linear_2452_15450)" stroke-width="11"/><defs><linearGradient id="paint0_linear_2452_15450" x1="574.013" y1="103.3" x2="113.513" y2="124.289" gradientUnits="userSpaceOnUse"><stop stop-color="#FFBA34"/><stop offset="0.177083" stop-color="#F5DB85"/><stop offset="0.333333" stop-color="#A0D9DF"/><stop offset="0.497448" stop-color="#1188FF"/><stop offset="1" stop-color="#5F5F5F"/></linearGradient></defs></svg>
                        <div className='plancircle graycrcle'><svg xmlns="http://www.w3.org/2000/svg" width="12" height="9" viewBox="0 0 12 9" fill="none"><path fill-rule="evenodd" clip-rule="evenodd" d="M11.7225 0.282433C12.0925 0.65901 12.0925 1.26956 11.7225 1.64614L4.77515 8.71757C4.40518 9.09414 3.80534 9.09414 3.43537 8.71757L0.277478 5.50328C-0.0924926 5.1267 -0.0924926 4.51615 0.277478 4.13958C0.647448 3.763 1.24729 3.763 1.61726 4.13958L4.10526 6.67201L10.3827 0.282433C10.7527 -0.0941443 11.3526 -0.0941443 11.7225 0.282433Z" fill="white"/></svg></div>
                        <div className='plancircle bluecrcle'></div>
                        <div className='plancircle yellowcrcle'></div>
                    </div>
                </div> */}
                {monthlyPlan &&
                    <>
                        <div className='new-plan-card' id="monthly-plans">
                            {/* pro plan */}
                            <div className='plan-card-walkthrough'>
                                <div>
                                    <div className='card-text-container'>
                                        <h3 className='plan-card-period cardhead-u'>Pro</h3>
                                        <h1 className='mb-0 plan-card-price price-u'>$9/mo</h1>
                                    </div>
                                    <div className='features-main-container'>
                                        <div className='features-list-container'>
                                            <div className='plan-feature-list'>
                                                <img src={BlueCheckboxIcon} alt="" />
                                                <p className='feature-list-text list-u'>Contact Form Widget</p>
                                            </div>
                                            <div className='plan-feature-list'>
                                                <img src={BlueCheckboxIcon} alt="" />
                                                <p className='feature-list-text list-u'>Socials-Sync Widget</p>
                                            </div>
                                            <div className='plan-feature-list'>
                                                <img src={BlueCheckboxIcon} alt="" />
                                                <p className='feature-list-text list-u'>Custom Page Themes</p>
                                            </div>
                                            <div className='plan-feature-list'>
                                                <img src={BlueCheckboxIcon} alt="" />
                                                <p className='feature-list-text list-u'>3rd Party Integrations</p>
                                            </div>
                                            <div className='plan-feature-list'>
                                                <img src={BlueCheckboxIcon} alt="" />
                                                <p className='feature-list-text list-u'>
                                                    Sell Digital Products
                                                    <CustomTooltip contentClass="plan-tooltip" message={[{ msg: "This includes Paid Meetings, Lead Magnets and Discount codes" }]}>
                                                        <div className='plan-asterisk blue-button'>*</div>
                                                    </CustomTooltip>
                                                </p>
                                            </div>
                                            <div className='plan-feature-list'>
                                                <img src={BlueCheckboxIcon} alt="" />
                                                <p className='feature-list-text list-u'>Detailed Analytics</p>
                                            </div>
                                            <div className='plan-feature-list'>
                                                <img src={BlueCheckboxIcon} alt="" />
                                                <p className='feature-list-text list-u'>Publish Posts</p>
                                            </div>
                                            <div className='plan-feature-list'>
                                                <img src={BlueCheckboxIcon} alt="" />
                                                <p className='feature-list-text list-u'>Send Marketing Emails</p>
                                            </div>
                                            <div className='plan-feature-list'>
                                                <img src={BlueCheckboxIcon} alt="" />
                                                <p className='feature-list-text list-u'>
                                                    Send up to 25K Emails
                                                    <CustomTooltip contentClass="plan-tooltip" message={[{ msg: "You can send up to 25,000 emails per month, resets with your billing cycle." }, { msg: "You can increase this limit by purchasing the Email Limit Increase Add-on for $5 per 25K emails." }]}>
                                                        <div className='plan-asterisk blue-button'>*</div>
                                                    </CustomTooltip>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='features-main-container'>
                                    <div className='plan-button-container'>
                                        <button onClick={(e) => handleSubmit(e, 'monthly')} name="pro" className='plan-trial-button blue-button'>Continue with Pro</button>
                                    </div>
                                </div>
                            </div>
                            {/* ultra plan */}
                            <div className='plan-card-walkthrough'>
                                <div>
                                    <div className='card-text-container'>
                                        <h3 className='plan-card-period cardhead-u'>Ultra</h3>
                                        <h1 className='mb-0 plan-card-price price-u'>$19/mo</h1>
                                    </div>

                                    <div className='features-main-container'>
                                        <div className='features-list-container'>
                                            <div className='plan-feature-list'>
                                                <img src={YellowCheckboxIcon} alt="" />
                                                <p className='feature-list-text list-u'>Custom Domain</p>
                                            </div>
                                            <div className='plan-feature-list'>
                                                <img src={YellowCheckboxIcon} alt="" />
                                                <p className='feature-list-text list-u'>Invite Team Members</p>
                                            </div>
                                            <div className='plan-feature-list'>
                                                <img src={YellowCheckboxIcon} alt="" />
                                                <p className='feature-list-text list-u'>Remove Zaap Branding</p>
                                            </div>
                                            <div className='plan-feature-list'>
                                                <img src={YellowCheckboxIcon} alt="" />
                                                <p className='feature-list-text list-u'>Priority Support
                                                    <CustomTooltip contentClass="plan-tooltip" message={[{ msg: "24-hour email response and under 6-hour Discord ticket response." }]}>
                                                        <div className='plan-asterisk text-dark yellow-button'>*</div>
                                                    </CustomTooltip></p>
                                            </div>
                                            <div className='plan-feature-list'>
                                                <img src={YellowCheckboxIcon} alt="" />
                                                <p className='feature-list-text list-u'>Send up to 50K Emails
                                                    <CustomTooltip contentClass="plan-tooltip" message={[{ msg: "You can send up to 20,000 emails per month, resets with your billing cycle." }, { msg: "You can increase this limit by purchasing the Email Limit Increase Add-on for $5 per 25K emails." }]}>
                                                        <div className='plan-asterisk text-dark yellow-button'>*</div>
                                                    </CustomTooltip>
                                                </p>
                                            </div>
                                            <div className='plan-feature-list'>
                                                <img src={YellowCheckboxIcon} alt="" />
                                                <p className='feature-list-text list-u'>+ Everything in Pro</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='plan-button-container'>
                                    <button onClick={(e) => handleSubmit(e, 'monthly')} name="ultra" className='plan-trial-button yellow-button'>Continue with Ultra</button>
                                </div>
                            </div>
                            {/* lifetime */}
                            <div className='plan-card-walkthrough '>
                                <div>
                                    <div className='card-text-container'>
                                        <h3 className='plan-card-period cardhead-u mb-1'>Lifetime</h3>
                                        <h1 className='mb-0 plan-card-price price-u'>$249/one-time</h1>
                                    </div>

                                    <div className='features-main-container'>
                                        <div className='features-list-container'>
                                            <div className='plan-feature-list'>
                                                <img src={PurpleCheckboxIcon} alt="" />
                                                <p className='feature-list-text list-u'>All Future Updates</p>
                                            </div>

                                            <div className='plan-feature-list'>
                                                <img src={PurpleCheckboxIcon} alt="" />
                                                <p className='feature-list-text list-u'>Lifetime Access </p>
                                            </div>

                                            <div className='plan-feature-list'>
                                                <img src={PurpleCheckboxIcon} alt="" />
                                                <p className='feature-list-text list-u'>All Features in Pro + Ultra</p>
                                            </div>

                                            <div className='plan-feature-list'>
                                                <img src={PurpleCheckboxIcon} alt="" />
                                                <p className='feature-list-text list-u'>
                                                    Send up to 50K Emails
                                                    <CustomTooltip contentClass="plan-tooltip" message={[{ msg: "You can send up to 50,000 emails per month, resets with your billing cycle." }, { msg: "You can increase this limit by purchasing the Email Limit Increase Add-on for $5 per 25K emails." }]}>
                                                        <div className='plan-asterisk purple-button'>*</div>
                                                    </CustomTooltip>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='plan-button-container'>
                                    {(pro == 1 || pro == true) && (planType == 'lifetime') ?
                                        <button className='plan-trial-button yellow-button'>Current Plan</button>
                                        :
                                        <button onClick={(e) => handleSubmit(e, 'lifetime')} name="lifetime" className='plan-trial-button purple-button'>Continue with Lifetime</button>
                                    }
                                </div>
                            </div>
                        </div>
                        {/* free plan */}
                        <div className='plan-card-free mt-15px mb-1'>
                            <div>
                                <div className='card-text-container'>
                                    <h3 className='plan-card-period cardhead-u mb-0'>Free</h3>
                                </div>
                                <div className='features-main-container'>
                                    <div className='features-list-container flex-row flex-wrap'>
                                        <div className='plan-feature-list'>
                                            <img src={grayCheckboxIcon} alt="" />
                                            <p className='feature-list-text list-u'>Unlimited Link Blocks</p>
                                        </div>
                                        <div className='plan-feature-list'>
                                            <img src={grayCheckboxIcon} alt="" />
                                            <p className='feature-list-text list-u'>Link Clicks Analytics</p>
                                        </div>
                                        <div className='plan-feature-list'>
                                            <img src={grayCheckboxIcon} alt="" />
                                            <p className='feature-list-text list-u'>Build Email List</p>
                                        </div>
                                        <div className='plan-feature-list'>
                                            <img src={grayCheckboxIcon} alt="" />
                                            <p className='feature-list-text list-u'>Socials Icons</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='features-main-container'>
                                <div className='plan-button-container'>
                                    <button onClick={(e) => handleSubmit(e, 'free')} name="free" className='plan-trial-button blue-button clr-gray'>Continue for free (Limited Features)</button>
                                </div>
                            </div>
                        </div>
                    </>
                }
                {/* <button onClick={(e) => handleSubmit(e,'free')} name="free" className='newcontinue-btn'>Continue for free (Limited Features)</button> */}
                {/* {yearlyPlan &&
                <div className='plan-cards-container' id="yearly-plans" >
                    <div aria-label='free plan' className='plan-card'>
                            <div>
                                <div className='card-text-container'>
                                    <h3 className='plan-card-period cardhead-u'>Free</h3>
                                    <h1 className='mb-0 plan-card-price price-u'>$0/mo</h1>
                                </div>
                                <div className='features-main-container'>
                                    <div className='features-list-container'>
                                        <div className='plan-feature-list'>
                                            <img src={grayCheckboxIcon} alt="" />
                                            <p className='feature-list-text list-u'>Unlimited Links</p>
                                        </div>
                                        <div className='plan-feature-list'>
                                            <img src={grayCheckboxIcon} alt="" />
                                            <p className='feature-list-text list-u'>Custom Theme</p>
                                        </div>
                                        <div className='plan-feature-list'>
                                            <img src={grayCheckboxIcon} alt="" />
                                            <p className='feature-list-text list-u'>Build Email List</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='features-main-container'>
                                <div className='plan-button-container'>
                                    <button onClick={(e) => handleSubmit(e,'free')} name="free" className='plan-trial-button blue-button clr-gray'>Join Free</button>                       
                                </div>
                            </div>
                    </div>
                    <div aria-label='pro plan ' className='plan-card'>
                            <div>
                                <div className='card-text-container'>
                                    <h3 className='plan-card-period cardhead-u'>Pro</h3>
                                    <h1 className='mb-0 plan-card-price price-u'>$79/yr</h1>
                                </div>
                                <div className='features-main-container'>
                                    <div className='features-list-container'>
                                        <div className='plan-feature-list'>
                                            <img src={BlueCheckboxIcon} alt="" />
                                            <p className='feature-list-text list-u'>Email marketing</p>
                                        </div>
                                        <div className='plan-feature-list'>
                                            <img src={BlueCheckboxIcon} alt="" />
                                            <p className='feature-list-text list-u'>Sell digital products</p>
                                        </div>
                                        <div className='plan-feature-list'>
                                            <img src={BlueCheckboxIcon} alt="" />
                                            <p className='feature-list-text list-u'>Sell coaching calls</p>
                                        </div>
                                        <div className='plan-feature-list'>
                                            <img src={BlueCheckboxIcon} alt="" />
                                            <p className='feature-list-text list-u'>Advanced analytics</p>
                                        </div>
                                        <div className='plan-feature-list'>
                                            <img src={BlueCheckboxIcon} alt="" />
                                            <p className='feature-list-text list-u'>Sync-blocks</p>
                                        </div>
                                        <div className='plan-feature-list'>
                                            <img src={BlueCheckboxIcon} alt="" />
                                            <p className='feature-list-text list-u'>Publish blog posts</p>
                                        </div>
                                        <div className='plan-feature-list'>
                                            <img src={BlueCheckboxIcon} alt="" />
                                            <p className='feature-list-text list-u'>+ Everything in free</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='features-main-container'>
                                <div className='plan-button-container'>
                                    <button onClick={(e) => handleSubmit(e,'yearly')} name="pro" className='plan-trial-button blue-button'>7 Day Free Trial</button>
                                </div>
                            </div>
                    </div>
                    <div aria-label='ultra plan' className='plan-card'>
                        <div>
                            <div className='card-text-container'>
                                <h3 className='plan-card-period cardhead-u'>Ultra</h3>
                                <h1 className='mb-0 plan-card-price price-u'>$199/yr</h1>
                            </div>

                            <div className='features-main-container'>
                                <div className='features-list-container'>
                                    <div className='plan-feature-list'>
                                        <img src={YellowCheckboxIcon} alt="" />
                                        <p className='feature-list-text list-u'>Custom Domain</p>
                                    </div>
                                    <div className='plan-feature-list'>
                                        <img src={YellowCheckboxIcon} alt="" />
                                        <p className='feature-list-text list-u'>Remove Zaap Branding</p>
                                    </div>
                                    <div className='plan-feature-list'>
                                        <img src={YellowCheckboxIcon} alt="" />
                                        <p className='feature-list-text list-u'>Invite Team Members</p>
                                    </div>
                                    <div className='plan-feature-list'>
                                        <img src={YellowCheckboxIcon} alt="" />
                                        <p className='feature-list-text list-u'>Priority support</p>
                                    </div>
                                    <div className='plan-feature-list'>
                                        <img src={YellowCheckboxIcon} alt="" />
                                        <p className='feature-list-text list-u'>+ Everything in Pro</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='plan-button-container'>
                            <button onClick={(e) => handleSubmit(e,'yearly')} name="ultra" className='plan-trial-button yellow-button'>7 Day Free Trial</button>
                        </div>
                    </div>
                </div>
                } */}


            </div>

            {/* <div class="page-aside-bg5"></div> */}
        </>
    )
}
