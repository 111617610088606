import React from 'react'
import returnIcon from "../../assets/img/modals/return.svg";
import { Button, Modal } from "react-bootstrap"
import { hideLoader, showLoader } from '../loader';
import { apiUrl } from '../../utils/url';
import { useDispatch, useSelector } from 'react-redux';

const EmailDisclaimer = ({ show, setShow, setShowNewsletter }) => {
    const dispatch = useDispatch();
    const userDataReducer = useSelector(state => state?.userDataReducer);
    const handleClose = () => setShow(false);
    const handleAccept = async (e) => {
        e.preventDefault();
        showLoader()
        const response = await fetch(apiUrl + "/accept-email-agreement", {
            method: "GET",
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            }
        });
        const data = await response.json()
        if (data.status_code === 200) {
            dispatch({type: "SET_USER_DATA", payload: {...userDataReducer, accept_email_disclaimer:true}})
            hideLoader();
            handleClose()
            setTimeout(() => {
                setShowNewsletter(true)
            }, 200);
        } else {
            hideLoader();
            console.error('Error:', data.status_message);
        }
    }
    return (
        <Modal className="new-confirmation-modal" show={show} onHide={handleClose} centered>
            <Modal.Header className="borderless px-4 pb-0">
                <Modal.Title className="font-weight-500 fs-22px d-flex align-items-center gap-2">
                    Email Disclaimer
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="py-0 font-weight-500 pe-4 fs-14px px-4 mt-20px">
                <p><b>By using the email marketing feature, you agree to abide by the following terms:</b></p>
                <ul className={'marketing-list'}>
                    <li>No Unsolicited Emails – You must only send emails to recipients who have explicitly opted in to receive communications from you. Sending unsolicited emails (spam) is strictly prohibited.</li>
                    <li>Compliance with Laws – You are responsible for ensuring that your emails comply with all applicable laws and regulations, including but not limited to the CAN-SPAM Act, GDPR, and other relevant anti-spam laws in your region.</li>
                    <li>No Fraud or Deception – Emails must not contain false, misleading, or deceptive content. This includes misleading subject lines, impersonation, or any form of fraud.</li>
                    <li>No Abusive Content – You may not use this feature to send content that is harassing, abusive, hateful, or otherwise harmful.</li>
                    <li>Enforcement & Legal Action – We reserve the right to suspend or terminate your access to this feature if you violate these terms. In cases of serious breaches, including but not limited to sending spam, fraudulent communications, or unlawful content, we may pursue legal action and seek damages to the fullest extent permitted by law.</li>
                </ul>
                <p>Failure to comply with these guidelines may result in the suspension of this feature, termination of your account, and potential legal consequences.</p>
                <p>By proceeding, you acknowledge and agree to these terms.</p>
            </Modal.Body>
            <form>
                <Modal.Footer className="borderless justify-content-start px-4 gap-3 mt-20px pt-0 pb-4">
                    <button className="close btn btn-grey btn-semi-rounded d-flex align-items-center justify-content-center fs-12px font-weight-500 m-0" onClick={handleClose}>
                        <span>Cancel</span>
                        <small class="badge bg-light text-dark p-1 ms-2">Esc</small>
                    </button>
                    <button className="new-primary-btn m-0" type="button" onClick={handleAccept} autoFocus>
                        Accept
                        <small class="primary-badge">
                            <img src={returnIcon} alt="" />
                        </small>
                    </button>
                </Modal.Footer>
            </form>
        </Modal>

    )
}

export default EmailDisclaimer