import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import PasswordValidation from "../password-validation"
import { hideLoader, showLoader } from "../loader"
import { apiUrl } from "../../utils/url"
import { Alert } from "react-bootstrap"
import returnIcon from "../../assets/img/modals/return.svg"
import PasswordInput from "../form/PasswordInput";

const Passwords = () =>
{
    const dispatch = useDispatch()
    const [error, setError] = useState([])
    const [value, setValue] = useState({ name: localStorage.user, email: localStorage.email });
    const [passwords, setpasswords] = useState({ current_password: null, password: null, password_confirmation: null })

    const updatePassword = (e) =>
    {
        e.preventDefault();
        showLoader();
        setError([])
        const api = apiUrl + "/password-update";
        var myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${localStorage?.getItem("token")}`);
        const formdata = new FormData();

        formdata.append("current_password", passwords?.current_password)
        formdata.append("password", passwords?.password)
        formdata.append("password_confirmation", passwords?.password_confirmation)

        const setOptions = {
            method: "POST",
            headers: myHeaders,
            body: formdata
        }

        fetch(api, setOptions)
            .then(async (res) => {
                let errorList = []
                const response = await res?.json();

                console.log('response', response)

                if (response?.status_code == 200) {
                    toast.success(response.status_message)
                    hideLoader();
                }
                else
                {
                    console.log('error');

                    if (response?.errors?.password) {
                        response?.errors?.password?.map((val) => errorList.push(val))
                    }
                    if (response?.errors?.password_confirmation) {
                        response?.errors?.password_confirmation?.map((val) => errorList.push(val))
                    }
                    setError(errorList);

                    if (response?.status_message) {
                        toast.error(response?.status_message);
                    }
                }
            }).catch((errors) => hideLoader())

    }
    const handleChangePasswords = (e) => {
        setpasswords({ ...passwords, [e.target.name]: e.target.value })
    }
  return (
    <section class="container h-100 account-form" style={{overflowY:"auto"}}>
    <div className="container settings-section p-0">
      <form action="" method="post" onSubmit={updatePassword} style={{margin: "30px"}}>
        <div class="">
            {error?.length > 0 && (<Alert variant="danger">{error?.map((val, index) => <li>{val}</li>)}</Alert>)}
                <div class="">
                    <h1 class="settings-section-heading " style={{marginBottom:"18px"}}>Password</h1>
                    <div class="form-group" style={{marginBottom:"16px"}}>
                        <label htmlFor="current_password">Current Password</label>
                        <PasswordInput
                            placeholder="Current Password"
                            name="current_password"
                            value={value?.current_password}
                            onChange={handleChangePasswords}
                            required="required"
                        />
                    </div>

                    <div class="form-group" style={{marginBottom:"16px"}}>
                        <label htmlFor="password">New Password</label>
                        <PasswordInput
                            placeholder="New Password"
                            name="password"
                            value={value?.password}
                            onChange={handleChangePasswords}
                            required="required"
                            showGuidance={true}
                        />
                    </div>

                    <div class="form-group" style={{marginBottom:"20px"}}>
                        <label htmlFor="password_confirmation">Repeat Password</label>
                        <PasswordInput
                            placeholder="Repeat Password"
                            name="password_confirmation"
                            value={value?.password_confirmation}
                            onChange={handleChangePasswords}
                            required="required"
                            showGuidance={true}
                        />
                    </div>
                </div>
                <button type="submit" name="submit" className="settings-button-primary btn-primary">Update<small className="badge bg-primary-dark text-dark px-2 py-1 d-flex align-items-center justify-content-center"><img src={returnIcon} alt="" /></small></button>
            </div>
        </form>
    </div>
    </section>
  )
}

export default Passwords
