
import { Modal } from "react-bootstrap";
import payout_settings_warning_banner from "../../assets/img/payout-setting-warning-banner.svg"
import notConnected from "../../assets/img/payout-settings-not-connect-icon.svg";

const ConnectModal = ({ show, setShow, connect }) => {
    const hideModal = () => setShow(false)
    return (
        <Modal show={show} onHide={hideModal} >
            <Modal.Body style={{ padding: "0px" }}>
                <div style={{
                    borderTopRightRadius: '1.2rem',
                    borderTopLeftRadius: '1.2rem',
                    overflow: 'hidden',
                }}>
                    <img src={payout_settings_warning_banner} width="100%" />
                </div>
                <div style={{ padding: "20px", marginTop: "-40px" }}>
                    <div class="form-group">
                        <div style={{ marginTop: "-5px" }}>
                            <img src={notConnected} width="50px" style={{ boxShadow: "0px 4px 20px rgba(103, 101, 122, 0.19)", borderRadius: "10px" }} alt="" />
                        </div>
                        <h4 class="mt-3 mb-1">Connect your bank account to<br />start selling on Zaap?</h4>
                        <p class="text-gray font-weight-500">Connect your existing Stripe account or create a new Stripe account to start selling on Zaap.</p>
                    </div>
                    <div class="text-center mt-4 d-flex">
                        <button autoFocus type="button" class="btn btn-primary btn-semi-rounded d-flex align-items-center justify-content-center" onClick={connect}>
                            <b class="me-2">Connect</b>
                        </button>
                        <button type="button" class="close btn btn-grey btn-semi-rounded d-flex align-items-center justify-content-center ms-2" onClick={hideModal}>
                            <b class="me-2">Skip for now</b>
                        </button>
                    </div>
                </div>

            </Modal.Body>
        </Modal>
    )
}
export default ConnectModal;
