import { hideLoader, showLoader } from "../components/loader";
import { apiUrl } from "../utils/url";

const getProjects = async (slug) => {

    showLoader()
    try {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${localStorage?.getItem("token")}`);
        const res = await fetch(apiUrl + `/project/get/${slug}`, {
            headers: myHeaders
        })
        const response = await res.json();
        hideLoader();
        // console.log("response main", response);
        if(response?.status_code == 200)
        return {
            pro: response?.data?.pro === 1,
            new_user: response?.data?.new_user === 1,
            is_email_enabled: response?.data?.is_email_enabled === 1,
            plan_type: response?.data?.plan_type,
            meet_token: response?.data?.meet_token,
            paypal: response?.data?.paypal,
            stripe: response?.data?.stripe,
            blocks: response?.data?.blocks,
            settings: response?.data?.settings,
            eligibility: response?.data?.eligibility,
            available_pros: response?.data?.available_pros,
            total_pros: response?.data?.total_pros,
            res: response?.data,
            zoom_token: response?.data?.zoom_token
        }
        else {
            // toast.error(response.status_message);
            // history.push("/dashboard")
            throw response
        }
    }
    catch (err) {
        hideLoader();
        return err;
    }
}
export default getProjects