import React from 'react'
import OrangeCheckboxIcon from '../../assets/img/orange-checkbox-icon.svg'
import YellowCheckboxIcon from '../../assets/img/yellow-checkbox-icon.svg'
import PurpleCheckboxIcon from '../../assets/img/purple-checkbox-icon.svg'
import BlueCheckboxIcon from '../../assets/img/blue-checkbox-icon.svg'
import grayCheckboxIcon from '../../assets/img/gray-checkbox-icon.svg'
import { useEffect, useState } from "react";
import { hideLoader, showLoader } from '../../components/loader';
import { Alert, Dropdown } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { toast } from 'react-toastify';
import { apiUrl } from "../../utils/url";
import { useHistory } from 'react-router-dom';
import SideBar from '../layout';
import PageHeader from '../layout/page-header';
import tickIcon from "../../assets/img/white-tick.svg";
import { audiencePosts as manageStripePost } from '../../api/audience'
import lifetime from "../../assets/img/lifetime.svg"
import { useAlert } from '../../contexts/AlertContext'
import arrowRight from '../../assets/img/right-diagonal.svg'
import CustomTooltip from '../common/CustomTooltip'
import DiscountPopup from './discount-popup'
const NewUpgradePage = () => {

    const [error, setError] = useState([])
    const history = useHistory()
    const dispatch = useDispatch()
    const { slug } = useSelector(state => state?.slugReducer);
    const newUser = useSelector(state => state?.newUserReducer);
    const pro = useSelector(state => state?.proReducer);
    const planType = useSelector(state => state?.plantypeReducer);
    const [monthlyPlan, setMonthlyPlan] = useState(true)
    const [yearlyPlan, setYearlyPlan] = useState(false)
    const [monthlyPlan2, setMonthlyPlan2] = useState(true)
    const [yearlyPlan2, setYearlyPlan2] = useState(false)
    const [showDiscountPopup, setShowDiscountPopup] = useState(false)
    const selectedEmailPlan =  useSelector(state => state.selectedEmailPlanReducer)
    const emailPlans =  useSelector(state => state.emailPlansReducer)
    const [selectedPlan, setSelectedPlan] = useState(null)
    const { setAlert } = useAlert();
    console.log("selectedEmailPlan",selectedEmailPlan)
    function togglePlans(e) {
        const selectedValue = e.target.value;
        if (selectedValue === 'yearly') {
            setYearlyPlan(true)
            setMonthlyPlan(false)
        } else if (selectedValue === 'monthly'){
            setMonthlyPlan(true)
            setYearlyPlan(false)
        }
    }
    function togglePlans2(e,value) {
        console.log("value",value);
        const selectedValue = e?.target?.value ?? value;
        if (selectedValue === 'yearly') {
            setYearlyPlan2(true)
            setMonthlyPlan2(false)
        } else if (selectedValue === 'monthly'){
            setMonthlyPlan2(true)
            setYearlyPlan2(false)
        }
    }
    const handleSubmit = (e,type) => {
        if (planType == 'lifetime') {
            return;
        }
        showLoader();
        setError([])
        var myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${localStorage?.getItem("token")}`)

        const formdata = new FormData();
        formdata.append('project',slug);
        formdata.append('type',type);
        formdata.append('plan_type',e.target.name);
        formdata.append("tolt_referral", window.tolt_referral??'');
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        fetch(apiUrl + "/subscribe-plan", requestOptions)
            .then(async (res) => {
                hideLoader()
                const response = await res?.json();
                if (response?.status_code == 200) {
                    if(response?.data?.url){
                        location.href = response?.data?.url;

                        localStorage.setItem("temp_slug",response?.data?.slug);
                        localStorage.setItem("last_activity", `/project/${response?.data?.slug}/settings`)
                        localStorage.setItem("plan_type",e.target.name)
                        localStorage.setItem("period",type)
                    }else {
                        dispatch({ type: "SET_PRO", payload: true })
                        dispatch({ type: 'SET_PROJECT_DISABLED', payload:false });
                        dispatch({ type: "SET_PlAN_TYPE", payload: e.target.name })
                        window?.dataLayer?.push({
                            event: 'upgrade',
                            upgrade:e.target.name + (type != 'lifetime' ? ' '+type : null)
                        });
                        setAlert({ type: 'success', title: response?.status_message })
                    }
                }
                else {
                    setError(getErrors(response?.errors))
                    toast.error(response?.status_message)
                }
            }).catch((err) => hideLoader())

           

    }

    const manageStripe = (e, type=null) => {
        showLoader()
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${localStorage?.getItem("token")}`)
        const formdata = new FormData()
        if (type) {
            formdata.append("type", type)
        }
        // formdata.append("project", slug)
        const api = apiUrl + `/project/downgrade-free/${slug}`;
        const setOptions = {
            method: "POST",
            body: formdata,
            headers: myHeaders
        }
        manageStripePost(null, api, hideLoader, setOptions, 'custom_message').then((response) => {
            hideLoader();
            if (response?.status_code == 200) {
                if (response?.data?.url) {
                    location.href = response?.data?.url
                }else if(response?.data?.show_popup){
                    setShowDiscountPopup({show:true,type:'ask'});
                }else if (response?.data?.show_congrats_popup) {
                    setShowDiscountPopup({show:false});
                    setTimeout(() => {
                        setAlert({ type: 'success', title: response?.status_message })
                        setShowDiscountPopup({show:true,type:'congrats'});
                    }, 300);
                }else if (response?.data?.subscription_canceled) {
                    setShowDiscountPopup({show:false});
                    setAlert({ type: 'success', title: response?.status_message })
                    dispatch({ type: "SET_PRO", payload: false })
                    dispatch({ type: "SET_PlAN_TYPE", payload: null })

                }
            }else {
                setAlert({ type: 'error', title: response?.status_message })
            }
        })
    }
    const proFeatures = [
        "Contact Form Widget",
        "Socials-Sync Widget",
        "Custom Page Themes",
        "3rd Party Integrations",
        <>
            Sell Digital Products
            <CustomTooltip contentClass="plan-tooltip" message={[{ msg: "This includes Paid Meetings, Lead Magnets and Discount codes" }]}>
                <div className='plan-asterisk blue-button'>*</div>
            </CustomTooltip>
        </>,
        "Detailed Analytics",
        "Publish Posts",
        "Send Marketing Emails",
        <>
        Send up to 25K Emails
        <CustomTooltip contentClass="plan-tooltip" message={[{ msg: "You can send up to 25,000 emails per month, resets with your billing cycle." }, { msg: "You can increase this limit by purchasing the Email Limit Increase Add-on for $5 per 25K emails." }]}>
            <div className='plan-asterisk blue-button'>*</div>
        </CustomTooltip>
        </>,
    ];
    const ultraFeatures = [
        "Custom Domain",
        "Invite Team Members",
        "Remove Zaap Branding",
        <>
        Priority Support
        <CustomTooltip contentClass="plan-tooltip" message={[{ msg: "24-hour email response and under 6-hour Discord ticket response." }]}>
            <div className='plan-asterisk text-dark yellow-button'>*</div>
        </CustomTooltip>
        </>,
        <>
        Send up to 50K Emails
        <CustomTooltip contentClass="plan-tooltip" message={[{ msg: "You can send up to 50,000 emails per month, resets with your billing cycle." }, { msg: "You can increase this limit by purchasing the Email Limit Increase Add-on for $5 per 25K emails." }]}>
            <div className='plan-asterisk text-dark yellow-button'>*</div>
        </CustomTooltip>
        </>
        ,
        "+ Everything in Pro",
    ]
    const getEmailPlans = async () => {
        try {
            var myHeaders = new Headers();
            myHeaders.append("Authorization", `Bearer ${localStorage?.getItem("token")}`);
            const res = await fetch(apiUrl + "/email-plans", {
                headers: myHeaders
            })
            const response = await res.json();
            hideLoader()
            if (response.status_code === 200) {
                dispatch({type: "SET_EMAIL_PLANS", payload: response?.data})
            }else {
                setAlert({type:"error", message: response?.status_message})
            }
        }
        catch (err) {
            console.log("error", err);
        }
    };
    useEffect(() => {
        if (emailPlans?.length == 0) {
            getEmailPlans();
        }
    }, []);
    useEffect(() => {
        if (emailPlans) {
            const plan = emailPlans.find(plan => plan.id === selectedEmailPlan?.email_plan_id) || emailPlans[0];
            setSelectedPlan(plan);
        }
    },[selectedEmailPlan?.email_plan_id,emailPlans])
    const handleSelectPlan = async (plan_id) => {
    
        showLoader();
        var myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${localStorage?.getItem("token")}`)

        const formdata = new FormData();
        formdata.append('project',slug);
        formdata.append('addon_plan_id',plan_id);
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        fetch(apiUrl + "/subscribe/addon-plan", requestOptions)
            .then(async (res) => {
                hideLoader()
                const response = await res?.json();
                if (response?.status_code == 200) {
                    if(response?.data?.url){
                        localStorage.setItem("temp_slug",response?.data?.slug);
                        localStorage.setItem("addon_plan_id",plan_id);
                        localStorage.setItem("addon_plan_type","email");
                        localStorage.setItem("last_activity", `/project/${response?.data?.slug}/settings`)
                        location.href = response?.data?.url;

                    }
                }
                else {
                    setAlert({type: "error", msg:response?.status_message})
                }
            }).catch((err) => hideLoader())

    };
    return (
        // <div id="wrapper">
            <div className='up-cont-u'>
            
            {/* from="zaap-upgrade" */}
            {/* <div className="community-main"> */}
                {/* <SideBar from="zaap-upgrade"/> */}
                    <div className='choose-plan-container upgrade-card'>
                        <div className="plan-text-container">
                            <h1 className='settings-section-heading'>
                                Upgrade
                            </h1>
                            <div className='choose-plan-type'>
                            <div className="up-in-toggle bg-f1f1f1 schedule-toggle w-50 btn-semi-rounded" style={{ padding: "5px 3px" }}>
                                    <input type="radio" id="text_align_left" name="scheduleTime" onChange={togglePlans} checked={monthlyPlan} style={{ display: "none" }}  value={"monthly"} />
                                    <label for="text_align_left" className="btn-semi-rounded newsletter-btn-semi-rounded " > <span className='choose-plan-type-text'> Monthly</span></label>
                                    <input type="radio" id="text_align_right" name="scheduleTime" onChange={togglePlans} checked={yearlyPlan} style={{ display: "none" }} value={"yearly"}  />
                                    <label for="text_align_right" className="btn-semi-rounded newsletter-btn-semi-rounded "  > <span className='choose-plan-type-text'> Yearly</span></label>
                                </div>
                            </div>
                            <div className="plan-box">
                                <svg className='mobsvg' fill="none" viewBox="0 0 387 133" xmlns="http://www.w3.org/2000/svg"><path d="m0 126.9c97.123 0 275.14 8.118 382-122.9" stroke="url(#a)" stroke-width="11"/><defs><linearGradient id="a" x1="310.58" x2="61.054" y1="105.93" y2="112.1" gradientUnits="userSpaceOnUse"><stop stop-color="#FFBA34" offset="0"/><stop stop-color="#18f" offset=".49745"/><stop stop-color="#5F5F5F" offset="1"/></linearGradient></defs></svg>
                                <svg className='tabsvg' xmlns="http://www.w3.org/2000/svg" width="700" height="130" viewBox="0 0 700 130" fill="none"><path d="M0 124.353C179.5 124.353 508.5 132.5 706 1" stroke="url(#paint0_linear_2452_15450)" stroke-width="11"/><defs><linearGradient id="paint0_linear_2452_15450" x1="574.013" y1="103.3" x2="113.513" y2="124.289" gradientUnits="userSpaceOnUse"><stop stop-color="#FFBA34"/><stop offset="0.177083" stop-color="#F5DB85"/><stop offset="0.333333" stop-color="#A0D9DF"/><stop offset="0.497448" stop-color="#1188FF"/><stop offset="1" stop-color="#5F5F5F"/></linearGradient></defs></svg>
                                <div className='plancircle graycrcle'>
                                    {!pro && <img src={tickIcon} alt="" />}
                                </div>
                                <div className='plancircle bluecrcle'>
                                    {pro && planType != "ultra" && <img src={tickIcon} alt="" />}
                                </div>
                                <div className='plancircle yellowcrcle'>
                                    {pro && planType == "ultra" && <img src={tickIcon} alt="" />}
                                </div>
                            </div>
                            {/* {(pro != 1 && pro != true) &&
                            <div className=' mt-3 activate-profile'>
                                To Activate Your Profile, Join Any Plan
                            </div>
                            } */}
                        </div>
                            <PlanContainer id="monthly-plans">
                                {monthlyPlan && (
                                    <>
                                        <PlanCard
                                            type="pro"
                                            title="Pro"
                                            price="$9/mo"
                                            features={proFeatures}
                                            buttonText={
                                                (pro == 1 || pro == true) && (planType == "pro" || !planType)
                                                    ? "Current Plan"
                                                    : pro == 1
                                                        ? "Downgrade to Pro"
                                                        : "Upgrade to Pro"
                                            }
                                            onClick={(e) => handleSubmit(e, "monthly")}
                                            disabled={((pro == 1 || pro == true) && (planType == "pro" || !planType)) || (pro == 1 && planType == "lifetime")}
                                        />
                                        <PlanCard
                                            type="ultra"
                                            title="Ultra"
                                            price="$19/mo"
                                            features={ultraFeatures}
                                            buttonText={
                                                (pro == 1 || pro == true) && planType == "ultra"
                                                    ? "Current Plan"
                                                    : "Upgrade to Ultra"
                                            }
                                            onClick={(e) => handleSubmit(e, "monthly")}
                                            disabled={((pro == 1 || pro == true) && planType == "ultra") || (pro == 1 && planType == "lifetime")}
                                        />
                                    </>
                                )}

                                {yearlyPlan && (
                                    <>
                                        <PlanCard
                                            type="pro"
                                            title="Pro"
                                            price="$89/yr"
                                            features={proFeatures}
                                            buttonText={
                                                (pro == 1 || pro == true) && (planType == "pro" || !planType)
                                                    ? "Current Plan"
                                                    : pro == 1
                                                        ? "Downgrade to Pro"
                                                        : "Upgrade to Pro"
                                            }
                                            onClick={(e) => handleSubmit(e, "yearly")}
                                            disabled={((pro == 1 || pro == true) && (planType == "pro" || !planType)) || (pro == 1 && planType == "lifetime")}
                                        />
                                        <PlanCard
                                            type="ultra"
                                            title="Ultra"
                                            price="$199/yr"
                                            features={ultraFeatures}
                                            buttonText={
                                                (pro == 1 || pro == true) && planType == "ultra"
                                                    ? "Current Plan"
                                                    : "Upgrade to Ultra"
                                            }
                                            onClick={(e) => handleSubmit(e, "yearly")}
                                            disabled={((pro == 1 || pro == true) && planType == "ultra") || (pro == 1 && planType == "lifetime")}
                                        />
                                    </>
                                )}
                                <div className='lifetime-plan-card purple-box-shadow'>
                                    <div>
                                        <div className='card-text-container'>
                                            <h3 className='plan-card-period cardhead-u mb-1'>Lifetime</h3>
                                            <h1 className='mb-0 plan-card-price price-u'>$249/one-time</h1>
                                        </div>

                                        <div className='features-main-container'>
                                            <div className='features-list-container'>
                                            <div className='plan-feature-list'>
                                                <img src={PurpleCheckboxIcon} alt="" />
                                                <p className='feature-list-text list-u'>All Future Updates</p>
                                            </div>

                                            <div className='plan-feature-list'>
                                                <img src={PurpleCheckboxIcon} alt="" />
                                                <p className='feature-list-text list-u'>Lifetime Access </p>
                                            </div>

                                            <div className='plan-feature-list'>
                                                <img src={PurpleCheckboxIcon} alt="" />
                                                <p className='feature-list-text list-u'>All Features in Pro + Ultra</p>
                                            </div>
                                            <div className='plan-feature-list'>
                                                <img src={PurpleCheckboxIcon} alt="" />
                                                <p className='feature-list-text list-u'>Send up to 50K Emails
                                                    <CustomTooltip contentClass="plan-tooltip" message={[{ msg: "You can send up to 50,000 emails per month, resets with your billing cycle." }, { msg: "You can increase this limit by purchasing the Email Limit Increase Add-on for $5 per 25K emails." }]}>
                                                        <div className='plan-asterisk purple-button'>*</div>
                                                    </CustomTooltip>
                                                </p>
                                            </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='plan-button-container'>
                                    {(pro == 1 || pro == true) && (planType == 'lifetime') ? 
                                            <button className='plan-trial-button purple-button'>Current Plan</button>
                                            :
                                        <button disabled={pro == 1 && planType == 'lifetime'} onClick={(e) => handleSubmit(e,'lifetime')} name="lifetime" className='plan-trial-button purple-button'>Upgrade to Lifetime</button>
                                    }
                                    </div>
                                </div>
                            </PlanContainer>
                            <div style={{padding:"0px 15px 15px 15px"}}>
                                <div className='plan-2nd-row'>
                                    <PlanCard
                                        type="free"
                                        title="Free"
                                        price=""
                                        features={[
                                            "Unlimited Link Blocks",
                                            "Socials Icons",
                                            "Link Clicks Analytics",
                                            "Build Email List",
                                        ]}
                                        buttonText={!pro ? "Current Plan" : "Downgrade to Free"}
                                        onClick={!pro ? undefined : manageStripe}
                                        disabled={false}
                                    />
                                    <div className='plan-card-free red-box-shadow'>
                                        <div>
                                            <div className='card-text-container'>
                                                <h3 className='plan-card-period cardhead-u mb-0 d-flex align-items-center justify-content-start gap-1'>
                                                    Emails Limit Increase
                                                    <CustomTooltip contentClass="plan-tooltip" message={[{ msg: `Your current plan includes ${selectedPlan?.email_limit?.toLocaleString()} emails, so this increase will be added to your existing limit.`}, { msg: "Billed as a separate monthly subscription." }, { msg: "Unused emails do not roll over, limits reset with your billing cycle." }]}>
                                                        <div className='plan-asterisk red-button'>*</div>
                                                    </CustomTooltip>
                                                </h3>
                                            </div>
                                            <div className='mt-15px'>
                                            {!selectedEmailPlan?.email_plan_id ?
                                            <>
                                                <div className="grey-dropdown" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
                                                    {selectedPlan ? `${selectedPlan?.email_limit?.toLocaleString()} Emails - $${selectedPlan?.formatted_price}/mo` : "Select Plan"}
                                                </div>
                                                <div className="dropdown-menu grey-dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                    {emailPlans?.map(plan => (
                                                        <div key={plan.id} className="dropdown-item grey-dropdown-item" href="#" onClick={() => setSelectedPlan(plan)}>
                                                            {`${plan.email_limit?.toLocaleString()} Emails - $${plan?.formatted_price}/mo`}
                                                        </div>
                                                    ))}
                                                    <a className="dropdown-item grey-dropdown-item" target="_blank" href="https://zaap.ai/support">100,000+ - Contact us</a>
                                                </div>
                                                </>
                                                :
                                                <div className="grey-dropdown cursor-text">
                                                    {selectedPlan ? `${selectedPlan?.email_limit.toLocaleString()} Emails - $${selectedPlan?.formatted_price}/mo` : "Select Plan"}
                                                </div>
                                            }
                                            </div>
                                            <div className={`features-main-container ${!pro && 'opacity-75'}`}>
                                                <div className='features-list-container'>
                                                    <div className='plan-feature-list'>
                                                        <img src={BlueCheckboxIcon} className='filter-hue-142' alt="" />
                                                        <p className='feature-list-text list-u'>Purchase {selectedPlan?.email_limit.toLocaleString()} emails as monthly Add-ons</p>
                                                    </div>
                                                    <div className='plan-feature-list mt-1'>
                                                        <img src={BlueCheckboxIcon} className='filter-hue-142' alt="" />
                                                        <p className='feature-list-text list-u'>Billed Monthly with your current plan</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='features-main-container'>
                                            <div className='plan-button-container'>
                                                {
                                                    !pro ?
                                                    <CustomTooltip contentClass="plan-tooltip" message={[{ msg: "Unavailable for free plan users" }]}>
                                                        <button className='plan-trial-button red-button opacity-75' disabled>
                                                            Purchase Add-on
                                                        </button>
                                                    </CustomTooltip>
                                                    :
                                                    <button onClick={(e) => handleSelectPlan(selectedPlan?.id)} name="free" className='plan-trial-button red-button '>{selectedEmailPlan?.email_plan_id ?'Current Plans':'Purchase Add-on'}</button>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='compare-features'>
                                    <span>Compare all features</span>
                                    <div className='d-flex align-items-center gap-2 flex-wrap justify-content-end'>
                                        <a href='mailto:support@zaap.ai' className="close btn btn-grey btn-semi-rounded  d-flex align-items-center gap-1 fs-12px font-weight-500">
                                            Contact Us
                                            <img className='filter-brightness-0' src={arrowRight} alt="" />
                                        </a>
                                        <a href='https://www.zaap.ai/features' target='_blank' className='blue-btn'>
                                            Explore Features
                                            <img src={arrowRight} alt="" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                    </div>
                    <div className='choose-plan-container upgrade-card big-cont'>
                        {/* pro plane panel */}
                        <div className="plan-text-container nowrap">
                            <h2 className='settings-section-heading mb-0'>
                                Purchase Lifetime
                            </h2>
                            <div className='d-flex'>
                                <div className='d-flex justify-content-between flex-column '>
                                    <div className="plan-text-container px-0 pt-1">
                                        <div className='adv-plan-card lifetime-adv-plan-card'>
                                            <div className='free-u'>
                                                <img src={lifetime} alt="" />
                                            </div>
                                            <div className='free-head'>
                                                <h3 className='head-free-u'>
                                                    One payment. Access forever.
                                                </h3>
                                            </div>
                                        </div>
                                        <div className='adv-plan-card lifetime-adv-plan-card'>
                                            <div className='free-u'>
                                                <img src={lifetime} alt="" />
                                            </div>
                                            <div className='free-head'>
                                                <h3 className='head-free-u'>
                                                    Unlock Every Feature From Pro + Ultra.
                                                </h3>
                                            </div>
                                        </div>
                                        <div className='adv-plan-card lifetime-adv-plan-card'>
                                            <div className='free-u'>
                                                <img src={lifetime} alt="" />
                                            </div>
                                            <div className='free-head'>
                                                <h3 className='head-free-u'>
                                                    Enjoy Future Updates.
                                                </h3>
                                            </div>
                                        </div>
                                        <div className='adv-plan-card lifetime-adv-plan-card'>
                                            <div className='free-u'>
                                                <img src={lifetime} alt="" />
                                            </div>
                                            <div className='free-head'>
                                                <h3 className='head-free-u'>
                                                    No Subscription.
                                                </h3>

                                            </div>
                                        </div>
                                    </div>
                                    <div className='text-wrap lifetime-terms'>
                                        *Lifetime terms and conditions apply. Email broadcast sending has a ‘fair-use’ policy. We reserve the right to block email sending if emails break the conditions (Such as spam sending) or if email sending volume is outside the fair use number.
                                    </div>
                                </div>
                            </div>
                    </div>
                    </div>
                    <div className='choose-plan-container upgrade-card big-cont'>
                        {/* pro plane panel */}
                        <div className="plan-text-container nowrap">
                                <h2 className='settings-section-heading'>
                                    Calculate Savings
                                </h2>
                                <div className='choose-plan-type'>
                                <div className="up-in-toggle bg-f1f1f1 schedule-toggle w-50 btn-semi-rounded" style={{ padding: "5px 3px" }}>
                                        <input type="radio" id="text_align_left2" name="selectPlan" onChange={togglePlans2} checked={monthlyPlan2} style={{ display: "none" }}  value={"monthly"} />
                                        <label for="text_align_left2" className="btn-semi-rounded newsletter-btn-semi-rounded " > <span className='choose-plan-type-text'> Pro Plan</span></label>
                                        <input type="radio" id="text_align_right2" name="selectPlan" onChange={togglePlans2} checked={yearlyPlan2} style={{ display: "none" }} value={"yearly"}  />
                                        <label for="text_align_right2" className="btn-semi-rounded newsletter-btn-semi-rounded "  > <span className='choose-plan-type-text'> Ultra Plan</span></label>
                                    </div>
                                </div>
                                {/* {(pro != 1 && pro != true) &&
                                <div className=' mt-3 activate-profile'>
                                    To Activate Your Profile, Join Any Plan
                                </div>
                                } */}
                        </div>
                        {/* {monthlyPlan2 &&
                            <div className='plan-text-container'>
                                <div className='range-box'>
                                    <div className='ranepop'><svg xmlns="http://www.w3.org/2000/svg" width="133" height="59" viewBox="0 0 133 59" fill="none"><rect width="133" height="47" rx="23.5" fill="#0F615C"/><path d="M67.2103 56.1788C66.4317 57.463 64.5683 57.463 63.7897 56.1788L58.2477 47.0368C57.4397 45.7039 58.3993 44 59.958 44L71.042 44C72.6007 44 73.5603 45.7039 72.7523 47.0368L67.2103 56.1788Z" fill="#0F615C"/></svg><span>$71/mo<br/>in savings</span></div>
                                    <div className='rangecircle'></div>
                                    <div className='range-line'></div>
                                </div>  
                                <div className='adv-plan-card'>
                                    <div className='free-u'>
                                        <div className='free-tag clr-gray'>
                                            Free
                                        </div>
                                    </div>
                                    <div className='free-head'>
                                        <h3 className='head-free-u'>
                                            Advanced Link-in-bio  
                                        </h3>
                                        <span className='para-free-u'>
                                            Replaces Linktree, Webflow, Carrd
                                        </span>
                                    </div>
                                    <div className='price-u'>
                                        <div className='pricetag-u'>
                                            $10/mo
                                        </div>
                                    </div>
                                </div>
                                <div className='adv-plan-card'>
                                    <div className='free-u'>
                                        <div className='free-tag clr-gray'>
                                            Free
                                        </div>
                                    </div>
                                    <div className='free-head'>
                                        <h3 className='head-free-u'>
                                            Email List Builder 
                                        </h3>
                                        <span className='para-free-u'>
                                            Replaces ConvertKit, Mailchimp, BeeHiiv
                                        </span>
                                    </div>
                                    <div className='price-u'>
                                        <div className='pricetag-u'>
                                            $10/mo
                                        </div>
                                    </div>
                                </div>
                                <div className='adv-plan-card'>
                                    <div className='free-u'>
                                        <div className='free-tag blue-button'>
                                            Pro
                                        </div>
                                    </div>
                                    <div className='free-head'>
                                        <h3 className='head-free-u'>
                                            Advanced Analytics  
                                        </h3>
                                        <span className='para-free-u'>
                                            Replaces Linktree, Webflow, Carrd
                                        </span>
                                    </div>
                                    <div className='price-u'>
                                        <div className='pricetag-u'>
                                            $10/mo
                                        </div>
                                    </div>
                                </div>
                                <div className='adv-plan-card'>
                                    <div className='free-u'>
                                        <div className='free-tag blue-button'>
                                            Pro
                                        </div>
                                    </div>
                                    <div className='free-head'>
                                        <h3 className='head-free-u'>
                                            Send Email Broadcasts  
                                        </h3>
                                        <span className='para-free-u'>
                                            Replaces ConvertKit, Mailchimp, BeeHiiv
                                        </span>
                                    </div>
                                    <div className='price-u'>
                                        <div className='pricetag-u'>
                                            $50/mo
                                        </div>
                                    </div>
                                </div>
                                <div className='price-area'>
                                    Total Monthly Price = <span className='disdate'>$80/mo <svg width="93" height="14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2.426 12.08c0-2.345 2.814-5.11 5.2-5.15 1.308-.022 2.58.057 3.881.174.708.064 1.995-.195 2.563.373.913.913.894 1.951 1.68 2.911 1.456 1.781 4.605.554 6.257-.547.54-.36 1.667-1.052 1.89-1.667.398-1.09 1.215-2.27 2.215-2.936 2.506-1.67 6.951-2.606 8.634.572 1.306 2.468.726 4.03 4.068 4.03 2.417 0 4.14-1.588 5.312-3.582.25-.425 1.1-.883 1.518-1.12.687-.39 1.019-1.039 1.766-1.343.879-.358 4.212-1.26 4.951-.336.633.79 2.097.93 2.576 1.791.3.541.46 2.004.895 2.352.535.428.696 1.206 1.381 1.58 1.253.683 3.12 1.02 4.38.211 1.13-.727 1.973-1.409 2.81-2.463.96-1.209 1.842-1.934 3.036-2.911 1.141-.934 2.113-1.972 3.732-2.016 1.371-.037 3.187.214 4.043 1.356.65.867 1.006 1.244 1.431 2.215a7.63 7.63 0 0 0 1.02 1.692c.627.783 2.96 1.231 3.894 1.231 1.001 0 2.09-.902 2.152-1.916.07-1.167 1.406-3.029 2.463-3.557.922-.461 4.131-1.268 4.703-.125" stroke="#FF1451" stroke-width="3" stroke-linecap="round"/></svg></span><span className='newprice'>$9/mo</span>
                                </div>
                            </div>
                        } */}
                        {/* ultra plane panel */}
                        {/* {yearlyPlan2 && */}
                            <div className="plan-text-container">
                                <div className='range-box'>
                                    <div className={`ranepop ${!monthlyPlan2 && 'changed-opacity'}`} onClick={(e)=>{togglePlans2(e,'monthly')}} ><svg xmlns="http://www.w3.org/2000/svg" width="133" height="59" viewBox="0 0 133 59" fill="none"><rect width="133" height="47" rx="23.5" fill="#0F615C"/><path d="M67.2103 56.1788C66.4317 57.463 64.5683 57.463 63.7897 56.1788L58.2477 47.0368C57.4397 45.7039 58.3993 44 59.958 44L71.042 44C72.6007 44 73.5603 45.7039 72.7523 47.0368L67.2103 56.1788Z" fill="#0F615C"/></svg><span>$71/mo<br/>in savings</span></div>
                                    <div className={`rangecircle ${!monthlyPlan2 && 'changed-opacity'}`} onClick={(e)=>{togglePlans2(e,'monthly')}} ></div>
                                    <div className={`ranepop popup2 ${monthlyPlan2 && 'changed-opacity'}`} onClick={(e)=>{togglePlans2(e,'yearly')}} ><svg xmlns="http://www.w3.org/2000/svg" width="133" height="59" viewBox="0 0 133 59" fill="none"><rect width="133" height="47" rx="23.5" fill="#0F615C"/><path d="M67.2103 56.1788C66.4317 57.463 64.5683 57.463 63.7897 56.1788L58.2477 47.0368C57.4397 45.7039 58.3993 44 59.958 44L71.042 44C72.6007 44 73.5603 45.7039 72.7523 47.0368L67.2103 56.1788Z" fill="#0F615C"/></svg><span>$126/mo<br/>in savings</span></div>
                                    <div className={`rangecircle2 ${monthlyPlan2 && 'changed-opacity'}`} onClick={(e)=>{togglePlans2(e,'yearly')}} ></div>
                                    <div className='range-line'></div>
                                </div>  
                                <div className='adv-plan-card'>
                                    <div className='free-u'>
                                        <div className='free-tag clr-gray'>
                                            Free
                                        </div>
                                    </div>
                                    <div className='free-head'>
                                        <h3 className='head-free-u'>
                                            Advanced Link-in-bio  
                                        </h3>
                                        <span className='para-free-u'>
                                            Replaces Linktree, Webflow, Carrd
                                        </span>
                                    </div>
                                    <div className='price-u'>
                                        <div className='pricetag-u'>
                                            $10/mo
                                        </div>
                                    </div>
                                </div>
                                <div className='adv-plan-card'>
                                    <div className='free-u'>
                                        <div className='free-tag clr-gray'>
                                            Free
                                        </div>
                                    </div>
                                    <div className='free-head'>
                                        <h3 className='head-free-u'>
                                            Email List Builder 
                                        </h3>
                                        <span className='para-free-u'>
                                            Replaces ConvertKit, Mailchimp, BeeHiiv
                                        </span>
                                    </div>
                                    <div className='price-u'>
                                        <div className='pricetag-u'>
                                            $10/mo
                                        </div>
                                    </div>
                                </div>
                                <div className='adv-plan-card'>
                                    <div className='free-u'>
                                        <div className='free-tag blue-button'>
                                            Pro
                                        </div>
                                    </div>
                                    <div className='free-head'>
                                        <h3 className='head-free-u'>
                                            Advanced Analytics  
                                        </h3>
                                        <span className='para-free-u'>
                                            Replaces Linktree, Webflow, Carrd
                                        </span>
                                    </div>
                                    <div className='price-u'>
                                        <div className='pricetag-u'>
                                            $10/mo
                                        </div>
                                    </div>
                                </div>
                                <div className='adv-plan-card'>
                                    <div className='free-u'>
                                        <div className='free-tag blue-button'>
                                            Pro
                                        </div>
                                    </div>
                                    <div className='free-head'>
                                        <h3 className='head-free-u'>
                                            Send Email Broadcasts  
                                        </h3>
                                        <span className='para-free-u'>
                                            Replaces ConvertKit, Mailchimp, BeeHiiv
                                        </span>
                                    </div>
                                    <div className='price-u'>
                                        <div className='pricetag-u'>
                                            $50/mo
                                        </div>
                                    </div>
                                </div>
                                <div className={`adv-plan-card ${monthlyPlan2 && 'changed-opacity'}`}>
                                    <div className='free-u'>
                                        <div className='free-tag yellow-button'>
                                            Ultra
                                        </div>
                                    </div>
                                    <div className='free-head'>
                                        <h3 className='head-free-u'>
                                            Sell Digital Products 
                                        </h3>
                                        <span className='para-free-u'>
                                            Replaces Gumroad, PayHip, Shopify
                                        </span>
                                    </div>
                                    <div className='price-u'>
                                        <div className='pricetag-u'>
                                            $50/mo
                                        </div>
                                    </div>
                                </div>
                                <div className={`adv-plan-card ${monthlyPlan2 && 'changed-opacity'}`}>
                                    <div className='free-u'>
                                        <div className='free-tag yellow-button'>
                                            Ultra
                                        </div>
                                    </div>
                                    <div className='free-head'>
                                        <h3 className='head-free-u'>
                                            Calendar Bookings  
                                        </h3>
                                        <span className='para-free-u'>
                                            Replaces Calendly
                                        </span>
                                    </div>
                                    <div className='price-u'>
                                        <div className='pricetag-u'>
                                            $10/mo
                                        </div>
                                    </div>
                                </div>
                                <div className='price-area'>
                                    Total Monthly Price = <span className='disdate'>{monthlyPlan2 ? '$80/mo' : '$145/mo'} <svg width="93" height="14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2.426 12.08c0-2.345 2.814-5.11 5.2-5.15 1.308-.022 2.58.057 3.881.174.708.064 1.995-.195 2.563.373.913.913.894 1.951 1.68 2.911 1.456 1.781 4.605.554 6.257-.547.54-.36 1.667-1.052 1.89-1.667.398-1.09 1.215-2.27 2.215-2.936 2.506-1.67 6.951-2.606 8.634.572 1.306 2.468.726 4.03 4.068 4.03 2.417 0 4.14-1.588 5.312-3.582.25-.425 1.1-.883 1.518-1.12.687-.39 1.019-1.039 1.766-1.343.879-.358 4.212-1.26 4.951-.336.633.79 2.097.93 2.576 1.791.3.541.46 2.004.895 2.352.535.428.696 1.206 1.381 1.58 1.253.683 3.12 1.02 4.38.211 1.13-.727 1.973-1.409 2.81-2.463.96-1.209 1.842-1.934 3.036-2.911 1.141-.934 2.113-1.972 3.732-2.016 1.371-.037 3.187.214 4.043 1.356.65.867 1.006 1.244 1.431 2.215a7.63 7.63 0 0 0 1.02 1.692c.627.783 2.96 1.231 3.894 1.231 1.001 0 2.09-.902 2.152-1.916.07-1.167 1.406-3.029 2.463-3.557.922-.461 4.131-1.268 4.703-.125" stroke="#FF1451" stroke-width="3" stroke-linecap="round"/></svg></span><span className='newprice'>{monthlyPlan2 ? '$9/mo' : '$19/mo'}</span>
                                </div>
                            </div>
                        {/* } */}
                    </div>
                   
            {/* </div> */}
                <DiscountPopup show={showDiscountPopup} setShow={setShowDiscountPopup} manageStripe={manageStripe}/>
            </div>
        // </div>
            //  <div className="page-aside-bg5"></div> 
        
    )
}
const PlanContainer = ({ id, children }) => (
    <div className="plan-cards-container" id={id}>
      {children}
    </div>
  );
const PlanCard = ({ type, title, price, features, buttonText, onClick, disabled }) => {
    const icon = {
      free: grayCheckboxIcon,
      pro: BlueCheckboxIcon,
      ultra: YellowCheckboxIcon,
    }[type];
  
    const boxShadowClass = {
      free: "grey-box-shadow",
      pro: "blue-box-shadow",
      ultra: "yellow-box-shadow",
    }[type];
  
    const buttonClass = {
      free: "blue-button clr-gray",
      pro: "blue-button",
      ultra: "yellow-button",
    }[type];
  
    return (
      <div className={`plan-card ${boxShadowClass}`}>
        <div>
          <div className="card-text-container">
            <h3 className="plan-card-period cardhead-u mb-1">{title}</h3>
            <h1 className="mb-0 plan-card-price price-u">{price}</h1>
          </div>
          <div className="features-main-container">
            <div className="features-list-container">
              {features.map((feature, index) => (
                <div className="plan-feature-list" key={index}>
                  <img src={icon} alt="" />
                  <p className="feature-list-text list-u">{feature}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="plan-button-container">
          <button
            className={`plan-trial-button ${buttonClass}`}
            onClick={onClick}
            disabled={disabled}
            name={type}
          >
            {buttonText}
          </button>
        </div>
      </div>
    );
  };
export default NewUpgradePage;