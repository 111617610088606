import React, { useState, useEffect } from 'react'
import { toast } from 'react-toastify';
import { apiUrl } from '../utils/url';
import { useHistory } from "react-router-dom";
import { useDispatch } from 'react-redux';
// import { useParams } from 'react-router-dom'

export const AcceptInvite = () => {
    const params = new URLSearchParams(window.location.search);
    const [token, setToken] = useState(params?.get("t"));
    const history = useHistory()
    const dispatch = useDispatch()

    useEffect(() => {
        if (token) sendRequest();
    }, [])

    const redirectToPage = (slug) => {
        setTimeout(() => {
            if (slug) {
                history.push(`/project/${slug}/settings/team-members`)
            }
            localStorage.removeItem("lastActivity")
        }, 1000);
    }

    const sendRequest = () => {
        localStorage.setItem("invite_token", token)
        if (localStorage.getItem("user")) {
            const myHeaders = new Headers();
            myHeaders.append("Authorization", `Bearer ${localStorage?.getItem("token")}`);
            const formdata = new FormData()
            formdata.append("token", token)
            const api = apiUrl + "/settings/team-members/accept-invite";
            const setOptions = {
                method: "POST",
                body: formdata,
                headers: myHeaders
            }
            fetch(api, setOptions).then(async (res) => {
                const response = await res?.json();
                if (response?.status_code == 200) {
                    localStorage.setItem("slug", response?.data?.project)
                    dispatch({ type: "SET_SLUG", payload: { slug: response?.data?.project, basePath: "/project/" + response?.data?.project, newChange: false } })
                    toast.success("🎉 Invite Accepted! Welcome aboard.", { icon: false,})
                    setTimeout(() => {
                        history.push("/project/" + response?.data?.project + "/page-editor")
                    }, 1000);
                }
                else {
                    response?.errors?.invalid?.map((v) => toast.error(v));
                    setTimeout(() => {
                        history.push("/dashboard")
                    }, 1000);

                }
            })
                .catch(error => console.error(error))
        }
        else {
            history.push("/login")
        }
    }

    return (
        <div class="full-page-loader">
            <div class="lds-ripple"><div></div><div></div></div>
        </div>
    )
}
