import { Modal } from "react-bootstrap"
import upgrade_new_nfc_card from "../../assets/img/upgrade_new_nfc_card.svg";
import upgrade_new_1 from "../../assets/img/upgrade_new_1.svg";
import upgrade_new_8 from "../../assets/img/upgrade_new_8.svg";
import upgrade_new_2 from "../../assets/img/upgrade_new_2.svg";
import upgrade_new_youtube from "../../assets/img/upgrade_new_youtube.svg";
import upgrade_new_tiktok from "../../assets/img/upgrade_new_tiktok.svg";
import upgrade_new_twitter from "../../assets/img/upgrade_new_twitter.svg";
import upgrade_modal_icon from "../../assets/img/upgrade-modal-icon.svg";
import upgrade_modal_custom_domain_icon from "../../assets/img/upgrade-modal-custom-domain.svg";
import upgrade_modal_branding_icon from "../../assets/img/upgrade-modal-branding.svg";
import upgrade_modal_seller_fee from "../../assets/img/upgrade-modal-seller-fee.svg";
import upgrade_modal_newsletter from "../../assets/img/upgrade-modal-newsletter.svg";
import upgrade_modal_sync from "../../assets/img/upgrade-modal-sync.svg";
import returnIcon from "../../assets/img/modals/return.svg"

import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as $ from "jquery"
import { useEffect } from "react";
import upgradeSubmit from "./upgrade-button";
import { hideLoader, showLoader } from "../loader";

const UpgradeModal = ({ isOpen, onHide ,from }) => {
    UpgradeModal.defaultProps = {
        from:null
    };
    const dispatch = useDispatch();
    const [error, setError] = useState([]);
    const [code, setCode] = useState(null)
    const isModalOpen = useSelector(state => state?.upgradeModalReducer?.open)
    const { slug } = useSelector(state => state?.slugReducer);
    // const onHide = () => dispatch({ type: "RESET_UPGRADE_MODAL" });

    window.addEventListener("message", (e) => {
        if (e?.data?.isPro) {
            history.go()
        }
        hideLoader()
    })

    useEffect(() => {
        $(".modal-dialog-centered").closest(".modal-content").removeClass("modal-content")
    }, []);

    const showInput = () => $("#promo_code1").removeClass('d-none');
    const handleChange = (e) => setCode(e.target.value)
    const onClose = () => dispatch({ type: "RESET_UPGRADE_MODAL" });

    return (
        <div id="wrapper" >
            <Modal centered id="newBlockModal"
                show={isOpen == null ? isModalOpen : isOpen} onHide={isOpen == null ? onClose : onHide} size={"md"} >
                <div className=" modal-dialog-centered" role="document">
                    <div className="modal-content p-0">

                        <div className="modal-body">
                            <div className="form-group">
                                <img src={upgrade_modal_icon} width="30px" alt="" />
                                <h4 className="mt-2 mb-1">{from == 'dashboard' ? <>To activate your profile, join Pro here</>:<>Unlock a whole lot of<br></br>power with <span style={{ color: "#7F48FB" }}>Pro</span></>}</h4>
                                <p className="text-lightgray font-weight-500 mb-0">$9/month. Cancel anytime.</p>
                            </div>
                            <div className="row">

                                <div class={`col-12 col-sm-12 col-md-12 col-lg-12 d-flex align-items-center justify-content-${from == 'dashboard' ? 'start':'center'}`}>
                                    <div className="add-block-modal-body-main">
                                        <div class="card border-0 p-0 m-0 w-100 h-100">
                                            <div class="card-body p-0">
                                                <form action="" method="post" class="mt-1" role="form" onSubmit={(e) => {
                                                    upgradeSubmit(e, code, slug, false, "stripe")
                                                }}>
                                                    {error?.length > 0 && (
                                                        <Alert variant="danger">
                                                            {error?.map((val, index) => <li>{val}</li>)}
                                                        </Alert>
                                                    )}
                                                    <div class="category-main d-flex">
                                                    {from != 'dashboard' && 
                                                    <>

                                                        <div class="d-flex align-items-center mt-2 px-2 dev_upgrade_new_main">
                                                            <div class="dv_upgrade_new px-2">
                                                                <img src={upgrade_modal_custom_domain_icon} style={{ width: "40px" }} alt="" />
                                                            </div>
                                                            <div class="d-flex py-2 justify-content-center text-left flex-column" style={{ padding: "13px 10px" }}>
                                                                <h5 class="mb-1 text-left">Use Your&nbsp;<span style={{ color: "#7F48FB" }}>Own Domain</span></h5>
                                                                <small class="text-muted m-0">Go from ‘zaap.bio/you’ to ‘yourdomain.com’</small>
                                                            </div>
                                                        </div>


                                                        <div class="d-flex align-items-center mt-2 px-2 dev_upgrade_new_main">
                                                            <div class="dv_upgrade_new px-2">
                                                                <img src={upgrade_modal_branding_icon} style={{ width: "40px" }} alt="" />
                                                            </div>
                                                            <div class="d-flex py-2 justify-content-center text-left flex-column" style={{ padding: "13px 10px" }}>
                                                                <h5 class="mb-1 text-left">Remove&nbsp;<span style={{ color: "#7F48FB" }}>Zaap’s</span>&nbsp; branding</h5>
                                                                <small class="text-muted m-0">Own your page - without our logo on it</small>
                                                            </div>
                                                        </div>

                                                        <div class="d-flex align-items-center mt-2 px-2 dev_upgrade_new_main">
                                                            <div class="dv_upgrade_new px-2">
                                                                <img src={upgrade_modal_seller_fee} style={{ width: "40px" }} alt="" />
                                                            </div>
                                                            <div class="d-flex py-2 justify-content-center text-left flex-column" style={{ padding: "13px 10px" }}>
                                                                <h5 class="mb-1 text-left"><span style={{ color: "#7F48FB" }}>Google Analytics,</span>&nbsp;<span style={{ color: "#7F48FB" }}>Facebook Pixel</span>&nbsp;& <span style={{ color: "#7F48FB" }}>Email</span>&nbsp;Integrations</h5>
                                                                <small class="text-muted m-0">Unlock Google Analytics, Facebook Pixel & 3rd party email integrations!</small>
                                                            </div>
                                                        </div>

                                                        <div class="d-flex align-items-center mt-2 px-2 dev_upgrade_new_main">
                                                            <div class="dv_upgrade_new px-2">
                                                                <img src={upgrade_modal_newsletter} style={{ width: "40px" }} alt="" />
                                                            </div>
                                                            <div class="d-flex py-2 justify-content-center text-left flex-column" style={{ padding: "13px 10px" }}>
                                                                <h5 class="mb-1 text-left">Send Email&nbsp;<span style={{ color: "#7F48FB" }}>Newsletters</span></h5>
                                                                <small class="text-muted m-0">Send unlimited newsletters to your community</small>
                                                            </div>
                                                        </div>

                                                        <div class="d-flex align-items-center mt-2 px-2 dev_upgrade_new_main">
                                                            <div class="dv_upgrade_new px-2">
                                                                <img src={upgrade_modal_sync} style={{ width: "40px" }} alt="" />
                                                            </div>
                                                            <div class="d-flex py-2 justify-content-center text-left flex-column" style={{ padding: "13px 10px" }}>
                                                                <h5 class="mb-1 text-left">Sync Your Latest&nbsp;<span style={{ color: "#7F48FB" }}>YouTube, Twitter, TikTok Posts</span></h5>
                                                                <small class="text-muted m-0">Your page will auto-update to show your latest content </small>
                                                            </div>
                                                        </div>
                                                    </>
                                                    }

                                                        <div class={`d-flex align-items-start justify-content-center mt-2 mb-2 ${from == 'dashboard'? '':'px-2'} flex-column w-100`}>
                                                            <b><label class="mb-0" htmlFor="">Have a code? <span id="promo_code_label1" style={{ color: "#FF1451" }} onClick={showInput} class="cursor-pointer">Enter Code...</span></label></b>
                                                            <input type="text" class="d-none form-control py-2" placeholder="Please Enter Code" name="promo_code" id="promo_code1" value={code} onChange={handleChange} />
                                                        </div>


                                                    </div>
                                                    <div className="text-center mt-4 d-flex">
                                                        <button type="button" onClick={isOpen == null ? onClose : onHide} className="close btn btn-grey btn-semi-rounded me-2 d-flex align-items-center justify-content-center">
                                                            <b className="me-2">Cancel</b>
                                                            <small className="badge bg-light text-dark p-1">Esc</small>
                                                        </button>
                                                        <button type="submit" className="btn btn-primary btn-semi-rounded d-flex align-items-center justify-content-center">
                                                            <b className="me-2">Upgrade to Pro</b>
                                                            <small className="badge bg-primary-dark text-dark px-2 py-1 d-flex align-items-center justify-content-center">
                                                                <img src={returnIcon} alt="" />
                                                            </small>
                                                        </button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>


                                </div>


                            </div>
                        </div>


                    </div>

                </div>
            </Modal>
        </div>
    )
}
export default UpgradeModal