
import { Modal } from "react-bootstrap"
import ModalErrors from "../../../errors/modal-errors"
import leadMagnetIcon from "../../../../assets/img/lead-magnet.svg"
import { useEffect, useState } from "react"
import { apiUrl } from "../../../../utils/url"
import { hideLoader, showLoader } from "../../../loader"
import { useHistory } from "react-router-dom"
import { useSelector } from "react-redux"
import { Link, useLocation } from "react-router-dom/cjs/react-router-dom.min"
import CroppieModal from "../../../modals/croppie-modal"
import removeicon from "../../../../assets/img/products/removeicon.svg";
import { toast } from "react-toastify"
import ErrorMessage from "../../../errors/errorMessage"

const mainPath = "products";
const subPath = "createProducts";
const CreateLeadMagnetWidgetModal =
    ({ show, handleHide, returnIcon, handleSubmit, slug, error, setLeadMagnetModal, leadMagnetModal }) => {

        const history = useHistory()
        const [croppie, setCroppie] = useState(null);
        const [croppie_image, setcroppie_image] = useState({ url: "", id: null });
        const [showCroppieModal, setshowCroppieModal] = useState(null);
        const [value, setValue] = useState({
            name: null,
            image: null,
            description: null,
        });
        const [imageUrl, setImageUrl] = useState();
        const { basePath } = useSelector(state => state?.slugReducer);

        useEffect(() => {
            setValue({ ...value, name: leadMagnetModal?.name, file_name: leadMagnetModal?.details?.file_name, description: leadMagnetModal?.details?.description, image: leadMagnetModal?.details?.image, file: leadMagnetModal?.details?.file })
        }, [leadMagnetModal])
        const handleChange = (e) => {
            const { name, value: inputValue, files } = e.target;
            const updatedValue = {
                ...value,
                [name]: inputValue,
            };
        
            if (files && files.length > 0) {
                if (files[0] && files[0].size > 102400000) {
                    toast.error("File size exceeds the 100 MB limit.");
                    return;
                }
                updatedValue.file = files[0];
                updatedValue.file_name = files[0].name;
            }
        
            setValue(updatedValue);
        };
        const handleopenmodal = (e) => {
            setcroppie_image({ url: "", id: null, name: null, type: null });
            generate_image_preview(e.target);
            hideLoader();
        };
        const handleHideModal = () => {
            setshowCroppieModal(false);
            setCroppie(null);
            setcroppie_image({ url: "", id: null });
            // history.push(basePath + "/products");
        };
        const croppieClose = () => {
            showLoader();
            setshowCroppieModal(false);
            setCroppie(null);
            setTimeout(() => {
                hideLoader();
            }, 400);
        };
        const croppedImage = () => {
            if (croppie !== null) {
                croppie.result({
                    type: "canvas",
                    size: "original",
                    format: "png",
                    quality: 1,
                    type: 'blob'
                })
                    .then((response) => {
                        const tempObj = { image: response };
                        croppie
                        .result({
                            type: "canvas",
                            size: "original",
                            format: "png",
                            quality: 1,
                        })
                        .then((res) => {
                                setImageUrl(res)
                                setValue({ ...value, image: response })
                                croppieClose()
                            });
                    });
            }
        };
        function generate_image_preview(input) {
            showLoader();
            if (input.files && input.files[0]) {
                let reader = new FileReader();
                if (input.files[0].size >= 5242880) {
                    $("#image_file_input").val("");
                    let notification_container = $(".notification-container");
                    toast.error("File Size Should not exceed 1MB");
                    // showModal();
                    return;
                }
                let val = input.files[0].name;
                let valid_extensions = ["png", "jpg", "jpeg"];
                let extension =
                    val.substring(val.lastIndexOf(".") + 1, val.length) || val;
                if (!valid_extensions.includes(extension?.toLowerCase())) {
                    $("#image_file_input").val("");
                    let notification_container = $(".notification-container");
                    toast.error("Invalid Image File. Should be png, jpg or jpeg");
                    // showModal();
                    return;
                }
                reader.onload = (event) => {
                    setcroppie_image({
                        ...croppie_image,
                        url: event.target.result,
                    });
                    setshowCroppieModal(true);
                    setTimeout(() => {
                        const el = document.getElementById("image_demo2");
                        try {
                            if (el) {
                                var croppieInstance = new Croppie(el, {
                                    enableExif: true,
                                    viewport: {
                                        width: 291,
                                        height: 184,
                                        type: "square",
                                    },
                                    boundary: {
                                        width: 870,
                                        height: 570,
                                    },
                                });
                                croppieInstance
                                    .bind({
                                        url: event.target.result,
                                    })
                                    .then(function () {
                                        setTimeout(() => { }, 300);
                                    })
                                    .catch(() => {
                                        croppieClose();
                                    });
                                setCroppie(croppieInstance);
                            }
                        } catch { }
                    }, 500);
                };
                reader.readAsDataURL(input.files[0]);
            }
        }
        const handleRemoveFile = () => {
            setValue({...value, file: null,file_name: null});
        };
        return (
            <Modal centered show={show} onHide={!leadMagnetModal?.show ? handleHide : () => {
                setLeadMagnetModal({
                    ...leadMagnetModal,
                    show: !leadMagnetModal?.show,
                })
            }} id="createProductWidgetModal">
                <div class="modal-dialog-centered" role="document">
                    <div class="modal-content overflow-none">
                        <div class="modal-header justify-content-start gap-2">
                            <img src={leadMagnetIcon} width="30px" alt="" />
                            <span class="fs-14px">Add Lead Magnet</span>
                        </div>
                        <div class="modal-body">
                            <ErrorMessage error={error}/>
                            <form name="create_folder" method="post" role="form" onSubmit={(e) => {
                                e.preventDefault();
                                handleSubmit(e, value, leadMagnetModal?.show ? leadMagnetModal?.id : "lead_magnet", true,)
                            }}>
                                <div class="notification-container"></div>

                                <div class="form-group">
                                    <label><small>Title</small></label>
                                    <input type="text" class={`zaapGlobal-input`} value={value?.name} onChange={handleChange} name="name" required="required" placeholder="Download Free Guide" />
                                </div>
                                <div class="form-group mt-10px">
                                    <label><small>Description</small></label>
                                    <input type="text" class={`zaapGlobal-input`} value={value?.description} onChange={handleChange} name="description" required="required" placeholder="What is this product?" />
                                </div>
                                <div class="form-group mt-10px">
                                    <label><small>Image</small></label>
                                    <button type="button" className="newGraybtn position-relative ">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M6 0C6.41421 0 6.75 0.335786 6.75 0.75V5.25H11.25C11.6642 5.25 12 5.58579 12 6C12 6.41421 11.6642 6.75 11.25 6.75H6.75V11.25C6.75 11.6642 6.41421 12 6 12C5.58579 12 5.25 11.6642 5.25 11.25V6.75H0.75C0.335786 6.75 0 6.41421 0 6C0 5.58579 0.335786 5.25 0.75 5.25H5.25V0.75C5.25 0.335786 5.58579 0 6 0Z" fill="#333333" />
                                        </svg>Upload New
                                        <input
                                            type="file"
                                            className="dropzone dropzone4 inset-0"
                                            name="cover_image"
                                            accept=".png,.jpg,.jpeg"
                                            id="image_file_input"
                                            onChange={(e) => handleopenmodal(e)}
                                        />
                                    </button>
                                    {(value?.image || imageUrl) &&
                                        <img src={imageUrl ?? value?.image} width={110} className="mt-2" alt="" />
                                    }
                                </div>
                                <div class="form-group mt-10px">
                                    {
                                        value?.file_name ?
                                            <>
                                                <label><small>Uploaded File</small></label>
                                                <div className="d-flex align-items-center gap-2">
                                                    <span>{value?.file_name}</span>
                                                    <img src={removeicon} width="13px" className="cursor-pointer" onClick={handleRemoveFile} />

                                                </div>
                                            </> :
                                            <>
                                                <label><small>Upload File</small></label>
                                                <div className="zaap-file-upload">
                                                    <div >
                                                        Upload .MP4 .MP3 .PDF or .ZIP
                                                    </div>
                                                    <input
                                                        onChange={handleChange}
                                                        type="file"
                                                        accept=".mp4,.mp3,.pdf,.zip"
                                                        name="file"
                                                        className="hidden-file-upload"
                                                    />
                                                </div>
                                            </>
                                    }
                                </div>
                                <div class="text-center mt-4 d-flex">
                                    <button type="button" onClick={!leadMagnetModal?.show ? handleHide : () => {
                                        setLeadMagnetModal({
                                            ...leadMagnetModal,
                                            show: !leadMagnetModal?.show,
                                        })
                                    }} class="close btn btn-grey btn-semi-rounded me-2 d-flex align-items-center justify-content-center">
                                        <span class="me-2">Cancel</span>
                                        <small class="badge bg-light text-dark p-1">Esc</small>
                                    </button>
                                    <button type="submit" class="btn btn-primary btn-semi-rounded d-flex align-items-center justify-content-center">
                                        <span class="me-2">Save Changes</span>
                                        <small class="badge bg-primary-dark text-dark px-2 py-1 d-flex align-items-center justify-content-center">
                                            <img src={returnIcon} alt="" />
                                        </small>
                                    </button>
                                </div>
                            </form>
                        </div>

                    </div>
                </div>
                <CroppieModal show={showCroppieModal} handleHide={handleHideModal} croppieClose={croppieClose} croppedImage={croppedImage} modalWidth={500} productCroppie={"productCroppie"} />

            </Modal>
        )
    }
export default CreateLeadMagnetWidgetModal