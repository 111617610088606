import React, { useState, useEffect } from "react";
import Croppie from "croppie";
import "croppie/croppie.css";
import CroppieModal from "./modals/croppie-modal";
import { toast } from "react-toastify";
import { hideLoader, showLoader } from "./loader";

const ImageCropper = ({
  maxFileSize = 1048576, // 1 MB
  allowedFormats = ["png", "jpg", "jpeg"],
  viewportWidth = 320,
  viewportHeight = 180,
  viewportType = "square",
  boundaryWidth = 256,
  boundaryHeight = 144,
  outputFormat = "jpeg",
  outputQuality = 0.9,
  onImageCropped,
  showCroppieModal,
  setShowCroppieModal,
  modalWidth=true,
}) => {
  const [croppieInstance, setCroppieInstance] = useState(null);

  const destroyCroppie = () => {
    if (croppieInstance) {
      croppieInstance.destroy();
      setCroppieInstance(null);
    }
  };

  const handleClose = () => {
    showLoader();
    destroyCroppie();
    setShowCroppieModal({ show: false });
    hideLoader();
  };

  const handleCroppedImage = async () => {
    if (!croppieInstance) return;

    try {
      const blob = await croppieInstance.result({
        type: "blob",
        size: "original",
        format: outputFormat,
        quality: outputQuality,
      });

      const imageUrl = URL.createObjectURL(blob);
      onImageCropped?.({ blob, imageUrl });
      handleClose();
    } catch (error) {
      toast.error("Error processing image");
      console.error("Cropping error:", error);
    }
  };

  const initializeCroppie = (imageUrl) => {
    const el = document.getElementById("image_demo2");

    if (!el) {
      toast.error("Error initializing image cropper");
      return;
    }

    try {
      const instance = new Croppie(el, {
        enableExif: true,
        viewport: {
          width: viewportWidth,
          height: viewportHeight,
          type: viewportType,
        },
        boundary: {
          width: boundaryWidth,
          height: boundaryHeight,
        },
      });

      instance.bind({ url: imageUrl }).then(() => setCroppieInstance(instance));
    } catch (error) {
      console.error("Croppie initialization error:", error);
      toast.error("Error initializing image cropper");
    }
  };

  const handleFileInput = () => {
    const input = showCroppieModal?.event?.target;
    const file = input?.files?.[0];

    if (!file) return;

    if (file.size > maxFileSize) {
      toast.error("File size should not exceed 1MB");
      return;
    }

    const extension = file.name.split(".").pop().toLowerCase();
    if (!allowedFormats.includes(extension)) {
      toast.error(`Invalid file format. Allowed: ${allowedFormats.join(", ")}`);
      return;
    }

    const reader = new FileReader();
    reader.onload = (e) => initializeCroppie(e.target.result);
    reader.readAsDataURL(file);
  };

  useEffect(() => {
    if (showCroppieModal?.show) {
      handleFileInput();
    }

    return destroyCroppie; // Cleanup on unmount
  }, [showCroppieModal]);

  return (
    showCroppieModal?.show && (
      <CroppieModal
        show={true}
        handleHide={handleClose}
        croppieClose={handleClose}
        croppedImage={handleCroppedImage}
        modalWidth={modalWidth}
      />
    )
  );
};

export default ImageCropper;