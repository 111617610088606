import customizeModalProfileIcon from "../../../assets/img/modals/customize-modal-profile-icon.svg"
import customizeModalThemeIcon from "../../../assets/img/modals/customize-modal-theme-icon.svg"
import customizeModalSocialIcon from "../../../assets/img/modals/customize-modal-social-icon.svg"
import customizeModalDomainsIcon from "../../../assets/img/modals/customize-modal-domains-icon.svg"
import customizeModalSeoIcon from "../../../assets/img/modals/customize-modal-seo-icon.svg"
import customizeFontIcon from "../../../assets/img/modals/customize-font-icon.svg"
import customizeModalIntegrationIcon from "../../../assets/img/modals/customize-modal-integration-icon.svg"
import closeIcon from "../../../assets/img/modals/close-icon.svg"
import domainIcon from "../../../assets/img/modals/domain-icon.svg"
import returnIcon from "../../../assets/img/modals/return.svg";
import { useHistory, useLocation, useParams } from "react-router-dom"
import { useEffect, useRef, useState } from "react"
import 'bootstrap/dist/js/bootstrap.bundle.min';
import * as $ from "jquery"
// import { basePath } from "../../../routes/mainRoutes"
import { Modal } from "react-bootstrap"
import TabContent from "./tab-content"
import Loader, { hideLoader, showLoader } from "../../loader"
import { useDispatch, useSelector } from "react-redux"
import ModalErrors from "../../errors/modal-errors"
import { toast } from "react-toastify"
import { apiUrl, appUrl, pageUrl, protocol } from "../../../utils/url"
import ProfileCustomize from "../cutomizePageOptions/profileCustomize"
import ThemeCustomize from "../cutomizePageOptions/themeCustomize"
import SocialsCustomize from "../cutomizePageOptions/socialsCustomize"
import DomainsCustomize from "../cutomizePageOptions/domainsCustomize"
import SeoCustomize from "../cutomizePageOptions/SeoCustomize"
import IntegrationsCustomize from "../cutomizePageOptions/integrationsCustomize"
import FontsCustomize from "../cutomizePageOptions/fontsCustomize"
import AlertMessage from "../../../api/AlertMessage"

const CustomizePage = ({ style, removeModal, showModal, settings }) => {
    const history = useHistory();
    const { basePath } = useSelector(state => state?.slugReducer);
    const { slug } = useSelector(state => state?.slugReducer);

    const setParams = (tabName, paramName) => {
        // // history.push(`${basePath}/page-editor/${paramName}`)
        if (tabName == "pageIntegrations") {
            history.replace({
                // pathname: "page-editor",
                search: `?customizePage=true&pageIntegrations=true&${paramName}=true`
            })
        }
        else
            history.replace({
                pathname: `${basePath}/page-editor/${paramName}/${tabName}`,
                // search: `?${tabName}=true`
            })

    }

    return (
        <div class="modal fade" id="customDomainConnectedModal" tabIndex="-1" role="dialog" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content modal-semi-curve overflow-none">

                    <div class="modal-body">

                        <form name="create_folder" method="post" role="form">
                            <div class="notification-container"></div>


                            <div class="form-group">
                                <img src={domainIcon} width="30px" alt="" />
                                <h4 class="mt-2 mb-1">Woo Hoo! Your domain is connected.</h4>
                                <p class="text-gray font-weight-500">We're doing the technical stuff to make your<br />domain work. This should only take a few<br /> minutes.</p>
                            </div>

                            <div class="text-center mt-4 d-flex">
                                <button type="button" data-bs-dismiss="modal" class="btn btn-primary btn-semi-rounded d-flex align-items-center justify-content-center">
                                    <b class="me-2">Close</b>
                                    <small class="badge bg-primary-dark text-dark px-2 py-1 d-flex align-items-center justify-content-center">
                                        <img src={returnIcon} alt="" />
                                    </small>
                                </button>
                            </div>

                        </form>


                    </div>

                </div>
            </div>
        </div>
    )
}
export const matchObject = (obj1,obj2) => {
    if (obj1 && obj2) {
        const stringifiedObj1 = JSON.stringify(obj1);
        const stringifiedObj2 = JSON.stringify(obj2);
        return stringifiedObj1 === stringifiedObj2;
    }
    return false;
};
export const CustomizePageModal = ({ show, setShowCustomizeParent, settings, setBannerModal, setIframeURL }) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const { subPath } = useParams();
    const { pathname } = useLocation();
    const [tabShow, setTabShow] = useState(null);
    const pro = useSelector(state => state?.proReducer);
    const [showSaveChanges, setShowSaveChanges] = useState({show:false,formId: []});
    const { basePath, slug } = useSelector(state => state?.slugReducer);
    const { bannerSelected, linkStyleSelected } = useSelector(state => state);
    const profileValue = useSelector(state => state?.customizeProfileReducer);
    const socialValue = useSelector(state => state?.customizeSocialsReducer);
    const themeValue = useSelector(state => state?.customizeThemeReducer);
    const fontValue = useSelector(state => state?.customizeFontReducer);
    const seoValue = useSelector(state => state?.customizeSeoReducer);
    const fonts = useSelector(state => state?.allFontsReducer)
    const [profileformState, setProfileFormState] = useState({ ...profileValue });
    const [socialformState, setSocialFormState] = useState({ ...socialValue });
    const [themeformState, setThemeFormState] = useState({ ...themeValue });
    const [fontformState, setFontFormState] = useState(fontValue );
    const [seoformState, setSeoFormState] = useState({ ...seoValue });
    const [alert, setAlert] = useState(null);

    const [error, setError] = useState([]);
    const revertChanges = () => {
        dispatch({ type: "SET_CUSTOMIZE_PROFILE", payload: profileformState })
        dispatch({ type: "SET_CUSTOMIZE_THEME", payload: themeformState })
        dispatch({ type: "SET_CUSTOMIZE_FONT", payload: fontformState })
        dispatch({ type: "SET_All_FONTS", payload: {...fonts,selected_font:fontformState} })
        dispatch({ type: "SET_CUSTOMIZE_SEO", payload: seoformState })
        dispatch({ type: "SET_CUSTOMIZE_SOCIAL", payload: socialformState })
        setShowSaveChanges({show:false,formId: []})
        // handleClose(true)
    }
    const handleClose = () => {
        if (showSaveChanges?.show && showSaveChanges?.formId?.length > 0) {
            setShowSaveChanges({...showSaveChanges,highlight:true})
            setTimeout(() => {
                setShowSaveChanges({...showSaveChanges,highlight:false})
            }, 1000);
        }else {
            setShowSaveChanges({show:false,formId: []})
            history.push(basePath + "/page-editor")
        }
    }

    const setParams = (tabName, paramName) => {
        setTabShow(tabName)
        history.push(`${basePath}/page-editor/${paramName}/${tabName}`)
    }

    const handleSubmit = (values, tab_name, profile_style,link_style) => {
        // showLoader();
        setError([]);
        var myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${localStorage?.getItem("token")}`);
        const errorList = []
        const formdata = new FormData();
        let formValues = {};
        if (tab_name === "socials") {
            let obj = { "socials": {} }
            let a = {}
            values.map((val, index) => {
                a = { ...a, [`${val?.name}`]: val.value }
            })
            obj.socials = JSON.stringify(a)
            values = obj
            formValues = { ...values, project: slug, tab: tab_name, profile_style: profile_style, banner: values?.selected };
        }else if(tab_name === "custom_fonts"){
            if (pro) {
                formValues = { ...values, project: slug, tab: tab_name, };
            }else{
                return toast.error("Font is not Allowed on this Plan")
            }
        }else{
            formValues = { ...values,  project: slug, profile_style: profile_style, banner: values?.selected, link_style: link_style };
        }
        if (!formValues?.banner) delete formValues?.banner
        delete formValues?.isEdit;
        if (formValues?.image_url) delete formValues?.image_url;
        if (!formValues?.imageData) delete formValues?.imageData;
        delete formValues?.slug;
        delete formValues?.meta_description;
        delete formValues?.image;
        delete formValues?.selectedTheme;
        delete formValues?.editLink;
        delete formValues?.selected;

        if (!formValues?.link) formValues.link = slug

        if (!formValues?.title) delete formValues?.title;
        for (const key in formValues) {
            if (Object.hasOwnProperty.call(formValues, key)) {
                if (formValues[key] !== null || formValues[key] !== undefined)
                    formdata.append(key, formValues[key]);
            }
        }
        // if (tab_name === "socials") {
        //     JSON.stringify(socialFormValues)

        // }


        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        const apiName = "update";

        fetch(apiUrl + "/project/" + apiName, requestOptions)
            .then(async (res) => {
                let newPathname = pathname;
                const response = await res?.json();
                if (response?.status_code == 200) {
                    setAlert({ type: 'success', title: `${response?.status_message}` });
                    // toast.success(response?.status_message);
                    setIframeURL("");
                    if (values?.tab == "profile") {
                        if (values?.link == slug) {
                            newPathname = newPathname.replace(slug, formValues?.link)
                            window.history.replaceState(null, 'Page Editor', newPathname)
                        } else {
                            dispatch({ type: "SET_SLUG", payload: { slug:  response?.data?.slug } })
                            newPathname = newPathname.replace(slug, response?.data?.slug)
                            history.replace(newPathname)
                        }
                    }
                    setTimeout(() => {
                        setIframeURL(protocol + '' + pageUrl + '/' + slug + '?iframe=1');
                    }, 500);
                    hideLoader()
                }

                else {
                    let errorList = []
                    response?.errors?.email?.map((val) => errorList?.push(val));
                    response?.errors?.title?.map((val) => errorList?.push(val));
                    response?.errors?.description?.map((val) => errorList?.push(val));
                    response?.errors?.link?.map((val) => errorList?.push(val));
                    response?.errors?.verified_check?.map((val) => errorList?.push(val));
                    response?.errors?.page_language?.map((val) => errorList?.push(val));
                    response?.errors?.theme?.map((val) => errorList?.push(val));
                    response?.errors?.page_title?.map((val) => errorList?.push(val));
                    response?.errors?.page_description?.map((val) => errorList?.push(val));
                    response?.errors?.block_indexing?.map((val) => errorList?.push(val));
                    response?.errors?.favicon_upload?.map((val) => errorList?.push(val));
                    // setError(errorList)

                    if (errorList.length !== 0) {
                        errorList?.map((err) => {
                            return (
                                setTimeout(() => {
                                    toast.error(err);
                                }, 3000)
                            );
                        });
                    }
                    else {
                        setTimeout(() => {
                            toast.error(response?.status_message)
                        }, 3000)
                    }
                    hideLoader()
                }
            }).catch((err) => {
                // hideLoader()
            })
    }

    useEffect(() => {
        setTabShow(subPath)

        if (subPath !== undefined || tabShow !== undefined)
            if (subPath == tabShow) {
                if (subPath == "customizePageProfile")
                    $("#btn-customizePageProfileCollapse").click()
                else if (subPath == "customizePageTheme")
                    $("#btn-customizePageThemeCollapse").click()
                else if (subPath == "customizePageSocials")
                    $("#btn-customizePageSocialsCollapse").click()
                else if (subPath == "customizePageDomains")
                    $("#btn-customizePageDomainsCollapse").click()
                else if (subPath == "customizePageSeo")
                    $("#btn-customizePageSEOCollapse").click()
                else if (subPath == "customizePageIntegration")
                    $("#btn-customizePageIntegrationCollapse").click()
            }

    }, [subPath])
    const handleSubmitAll = (formValues) => {
        setError([]);
        var myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${localStorage?.getItem("token")}`);
    
        const formdata = new FormData();
        formValues.tab.forEach((tab, index) => {
            formdata.append(`tab[${index}]`, tab);
        });
        for (const key in formValues) {
            if (Object.hasOwnProperty.call(formValues, key)) {
                if (key != 'tab' && formValues[key] !== null && formValues[key] !== undefined)
                    formdata.append(key, formValues[key]);
            }
        }
    console.log("formValues",formValues)
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };
    
        const apiName = "update";
    
        fetch(apiUrl + "/project/" + apiName, requestOptions)
            .then(async (res) => {
                let newPathname = pathname;
                const response = await res?.json();
                if (response?.status_code == 200) {
                    setAlert({ type: 'success', title: `${response?.status_message}` });
                    // toast.success(response?.status_message);
                    setIframeURL("");
                    if (formValues?.tab == "profile") {
                        if (formValues?.link == slug) {
                            newPathname = newPathname.replace(slug, formValues?.link)
                            window.history.replaceState(null, 'Page Editor', newPathname)
                        } else {
                            dispatch({ type: "SET_SLUG", payload: { slug: response?.data?.slug } })
                            newPathname = newPathname.replace(slug, response?.data?.slug)
                            history.replace(newPathname)
                        }
                    }
                    setTimeout(() => {
                        setIframeURL(protocol + '' + pageUrl + '/' + slug + '?iframe=1');
                    }, 500);
                    hideLoader()
                } else {
                    let errorList = [];
                    Object.values(response?.errors || {}).forEach(errorArray => {
                        errorList = [...errorList, ...errorArray];
                    });
                    if (errorList.length !== 0) {
                        errorList?.map((err) => {
                            toast.error(err);
                        });
                    } else {
                        toast.error(response?.status_message)
                    }
                    hideLoader();
                }
            }).catch((err) => {
                hideLoader();
            });
    };
    const submitAllChanges = (e) => {
        e?.preventDefault();
    
        const valueMapping = {
            profileValue: profileValue,
            socialValue: socialValue,
            themeValue: themeValue,
            fontValue: fontValue,
            seoValue: seoValue,
        };
    
        let formValues = {
            project: slug,
            tab: [],
            profile_style: bannerSelected,
            link_style: linkStyleSelected,
        };
    
        showSaveChanges.formId.forEach(id => {
            switch (id) {
                case 'fontValue':
                    if (pro) {
                        formValues = { ...formValues, ...valueMapping[id], tab: [...formValues?.tab,'custom_fonts'] };
                    } else {
                        return toast.error("Font is not Allowed on this Plan");
                    }
                    break;
                case 'socialValue':
                    let socials = {};
                    valueMapping[id].forEach(val => {
                        socials[val.name] = val.value;
                    });
                    formValues = { ...formValues, socials: JSON.stringify(socials), tab: [...formValues?.tab,'socials'] };
                    break;
                case 'themeValue':
                    formValues = { ...formValues, ...valueMapping[id], tab: [...formValues?.tab,'theme'],banner: themeValue?.selected };
                    break;
                case 'profileValue':
                    formValues = { ...formValues, ...valueMapping[id], tab: [...formValues?.tab,'profile'] };
                    break;
                case 'seoValue':
                    formValues = { ...formValues, ...valueMapping[id], tab: [...formValues?.tab,'seo'] };
                    break;
            }
        });
    
        handleSubmitAll(formValues);
        setShowSaveChanges({ show: false, formId: [] });
    }
    return (
        <Modal show={show} onHide={handleClose} size={"lg"} id="customizePageModal">
            <div class="modal-dialog-centered modal-xlarge" role="document">
                <div class="customize-modal-content p-0">

                    <div class="modal-body p-0">

                        <div class="customize-page-desktop custom-u" >
                            <button class="btn close-btn" data-bs-dismiss="modal" onClick={handleClose}>
                                <img src={closeIcon} width="15px" alt="" />
                            </button>
                            <div className="fade-u"></div>

                            <div class="left-bar hidden-scroll nav nav-tabs justify-content-between" role="tablist">

                            <div className="nav-tabs-menus">
                                <div
                                    class={`customize-page-menu-link custom-width-u customizePageProfile ${subPath ? subPath == "customizePageProfile" ? "active" : "" : "active"}`}
                                    id="btnPageProfile"
                                    onClick={() => setParams("customizePageProfile", "customizePageModal")}
                                    data-bs-toggle="tab"
                                    data-bs-target="#customizePageProfile"
                                    role="tab" aria-controls="profile"
                                    aria-selected="true">
                                    <img src={customizeModalProfileIcon} width="32px" alt="" />
                                    <h6 class="pt-1 mb-0 ms-2">Profile</h6>
                                </div>

                                <div
                                    class={`customize-page-menu-link custom-width-u customizePageTheme ${subPath == "customizePageTheme" ? "active" : ""}`}
                                    data-bs-toggle="tab"
                                    id="btnPageTheme"
                                    data-bs-target="#customizePageTheme"
                                    onClick={() => setParams("customizePageTheme", "customizePageModal")}
                                    // onClick={() => setParams("pageTheme")}
                                    role="tab" aria-controls="theme"
                                    aria-selected="true"
                                >
                                    <img src={customizeModalThemeIcon} width="32px" alt="" />
                                    <h6 class="pt-1 mb-0 ms-2">Design</h6>
                                </div>

                                <div
                                    class={`customize-page-menu-link custom-width-u customizePageIntegration ${subPath == "customizePageFonts" ? "active" : ""}`}
                                    data-bs-toggle="tab" data-bs-target="#customizePageFonts"
                                    id="btnPageFont"
                                    onClick={() => setParams("customizePageFonts", "customizePageModal")}
                                    role="tab" aria-controls="font" aria-selected="true">
                                    <img src={customizeFontIcon} width="32px" alt="" />
                                    <h6 class="pt-1 mb-0 ms-2">Font</h6>
                                </div>

                                <div
                                    class={`customize-page-menu-link customizePageSocials ${subPath == "customizePageSocials" ? "active" : ""}`}
                                    id="btnPageSocials"
                                    onClick={() => setParams("customizePageSocials", "customizePageModal")}
                                    data-bs-toggle="tab" data-bs-target="#customizePageSocials"
                                    role="tab" aria-controls="social" aria-selected="true">
                                    <img src={customizeModalSocialIcon} width="32px" alt="" />
                                    <h6 class="pt-1 mb-0 ms-2">Socials</h6>
                                </div>

                                {/* <div
                                    class={`customize-page-menu-link custom-width-u customizePageDomains ${subPath == "customizePageDomains" ? "active" : ""}`}
                                    data-bs-toggle="tab" data-bs-target="#customizePageDomains"
                                    id="btnPageDomains"
                                    onClick={() => setParams("customizePageDomains", "customizePageModal")}
                                    role="tab" aria-controls="domains" aria-selected="true">
                                    <img src={customizeModalDomainsIcon} width="32px" alt="" />
                                    <h6 class="pt-1 mb-0 ms-2">Domains</h6>
                                </div> */}

                                <div
                                    class={`customize-page-menu-link custom-width-u customizePageIntegration ${subPath == "customizePageSEO" ? "active" : ""}`}
                                    data-bs-toggle="tab" data-bs-target="#customizePageSEO"
                                    id="btnPageSeo"
                                    onClick={() => setParams("customizePageSEO", "customizePageModal")}
                                    role="tab" aria-controls="seo" aria-selected="true">
                                    <img src={customizeModalSeoIcon} width="32px" alt="" />
                                    <h6 class="pt-1 mb-0 ms-2">SEO</h6>
                                </div>
                            </div>
                            <div className="nav-tabs-menus">
                                <small className="ms-3 font-12 text-dark ">Edit in Project Settings</small>
                                <div onClick={() => {
                                    history.push({
                                        pathname:`/project/${slug}/settings`,
                                        state: { fromHeaderPage: 'domains' }}
                                    );
                                }} class={`customize-page-menu-link pt-0 pb-0 align-items-center  custom-width-u customizePageIntegrations`}>
                                    
                                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
                                    <rect width="30" height="30" rx="9" fill="#FF4343"/>
                                    <path d="M12.9 9.4V8M17.1 20.6V22M9.4 12.9H8M20.6 17.1H22M10.0399 10.0399L9.05 9.05M19.9601 19.9601L20.95 20.95M15 18.9598L13.5151 20.4447C12.4216 21.5382 10.6487 21.5382 9.55528 20.4447C8.46181 19.3513 8.46181 17.5784 9.55528 16.4849L11.0402 15M18.9598 15L20.4447 13.5151C21.5382 12.4216 21.5382 10.6487 20.4447 9.55528C19.3513 8.46181 17.5784 8.46181 16.4849 9.55528L15 11.0402" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                    <div className="d-flex ms-2 w-100 justify-content-between align-items-center ">
                                    <h6 class="mb-0">Domains</h6>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                        <path d="M1 13L13 1M13 1H5M13 1V9" stroke="#5B5B5B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                    </div>
                                </div>
                            </div>

                                {/* <div
                                    class={`customize-page-menu-link custom-width-u customizePageIntegrations ${subPath == "customizePageIntegrations" ? "active" : ""}`}
                                    data-bs-toggle="tab"
                                    data-bs-target="#customizePageIntegrations"
                                    role="tab" aria-controls="integrations"
                                    id="btnPageIntegrations"
                                    onClick={() => setParams("customizePageIntegrations", "customizePageModal")}
                                    aria-selected="true">
                                    <img src={customizeModalIntegrationIcon} width="32px" alt="" />
                                    <h6 class="pt-1 mb-0 ms-2">Integrations</h6>
                                </div> */}

                            </div>

                            <TabContent slug={slug} showSaveChanges={showSaveChanges} setShowSaveChanges={setShowSaveChanges} setBannerModal={setBannerModal} subPath={subPath} tabShow={tabShow} setShowCustomizeParent={setShowCustomizeParent} settings={settings} handleSubmit={handleSubmit} error={error}  />
                        </div>


                        {/* <div class="customize-page-mobile d-md-none d-flex px-3">

                            <button class="btn close-btn" data-bs-dismiss="modal" onClick={handleClose}>
                                <img src={closeIcon} width="15px" alt="" />
                            </button>
                            <div class="mt-5 accordion w-100 overflow-auto" id="accordionExample">

                                <div class="accordion-item borderless">
                                    <h2 class="accordion-header" id="headingOne">
                                        <button class="accordion-button btn zaap-border-straight 
                                    borderless d-flex align-items-center collapsed" type="button"
                                            onClick={() => setParams("customizePageProfile", "customizePageModal")}
                                            id="btn-customizePageProfileCollapse"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#customizePageProfileCollapse"
                                            aria-expanded="false"
                                            aria-controls="customizePageProfileCollapse"
                                        >
                                            <img src={customizeModalProfileIcon} width="40px" alt="" />
                                            <h5 class="pt-1 mb-0 ms-3 font-weight-500">Profile</h5>
                                        </button>
                                    </h2>
                                    <div id="customizePageProfileCollapse"
                                        class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                        <div class="accordion-body px-2 customize-page-main">
                                            <ProfileCustomize settings={{ title: settings?.title, description: settings?.description, link: settings?.link, verified_check: settings?.verified_check, page_language: settings?.page_language, favicon_upload: settings?.favicon_upload }} handleSubmit={handleSubmit} error={error} />

                                        </div>
                                    </div>
                                </div>

                                <div class="accordion-item borderless">
                                    <h2 class="accordion-header" id="headingOne">
                                        <button
                                            id="btn-customizePageThemeCollapse"
                                            onClick={() => setParams("customizePageTheme", "customizePageModal")}
                                            class="accordion-button btn zaap-border-straight borderless d-flex align-items-center collapsed"
                                            type="button" data-bs-toggle="collapse"
                                            data-bs-target="#customizePageThemeCollapse"
                                            aria-expanded="false"
                                            aria-controls="customizePageThemeCollapse"
                                        >
                                            <img src={customizeModalThemeIcon} width="40px" alt="" />
                                            <h5 class="pt-1 mb-0 ms-3 font-weight-500">Theme</h5>
                                        </button>
                                    </h2>
                                    <div id="customizePageThemeCollapse" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                        <div class="accordion-body px-2 customize-page-main">
                                            <ThemeCustomize theme_color={settings?.theme_color} error={error} handleSubmit={handleSubmit} show={show} setBannerModal={setBannerModal} />

                                        </div>
                                    </div>
                                </div>

                                <div class="accordion-item borderless">
                                    <h2 class="accordion-header" id="headingOne">
                                        <button class="accordion-button btn zaap-border-straight borderless d-flex align-items-center collapsed" type="button"
                                            id="btn-customizePageFontsCollapse"
                                            onClick={() => setParams("customizePageFonts", "customizePageModal")} data-bs-toggle="collapse" data-bs-target="#customizePageFontsCollapse" aria-expanded="false" aria-controls="customizePageSocialsCollapse">
                                            <img src={customizeFontIcon} width="40px" alt="" />
                                            <h5 class="pt-1 mb-0 ms-3 font-weight-500">Fonts</h5>
                                        </button>
                                    </h2>
                                    <div id="customizePageFontsCollapse" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                        <div class="accordion-body px-2 customize-page-main">
                                            <FontsCustomize handleSubmit={handleSubmit} error={error} settings={settings?.socials} />
                                        </div>
                                    </div>
                                </div>

                                <div class="accordion-item borderless">
                                    <h2 class="accordion-header" id="headingOne">
                                        <button class="accordion-button btn zaap-border-straight borderless d-flex align-items-center collapsed" type="button"
                                            id="btn-customizePageSocialsCollapse"
                                            onClick={() => setParams("customizePageSocials", "customizePageModal")} data-bs-toggle="collapse" data-bs-target="#customizePageSocialsCollapse" aria-expanded="false" aria-controls="customizePageSocialsCollapse">
                                            <img src={customizeModalSocialIcon} width="40px" alt="" />
                                            <h5 class="pt-1 mb-0 ms-3 font-weight-500">Socials</h5>
                                        </button>
                                    </h2>
                                    <div id="customizePageSocialsCollapse" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                        <div class="accordion-body px-2 customize-page-main">
                                            <SocialsCustomize handleSubmit={handleSubmit} error={error} settings={settings?.socials} />
                                        </div>
                                    </div>
                                </div>

                                {/* <div class="accordion-item borderless">
                                    <h2 class="accordion-header" id="headingOne">
                                        <button class="accordion-button btn zaap-border-straight borderless d-flex align-items-center collapsed" type="button" onClick={() => setParams("customizePageDomains", "customizePageModal")}
                                            id="btn-customizePageDomainsCollapse" data-bs-toggle="collapse" data-bs-target="#customizePageDomainsCollapse" aria-expanded="false" aria-controls="customizePageDomainsCollapse">
                                            <img src={customizeModalDomainsIcon} width="40px" alt="" />
                                            <h5 class="pt-1 mb-0 ms-3 font-weight-500">Domains</h5>
                                        </button>
                                    </h2>
                                    <div id="customizePageDomainsCollapse" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                        <div class="accordion-body px-2 customize-page-main">
                                            <DomainsCustomize slug={slug} settings={settings?.custom_domain} handleSubmit={handleSubmit} error={error} setShowCustomizeParent={setShowCustomizeParent} />
                                        </div>
                                    </div>
                                </div>

                                <div class="accordion-item borderless">
                                    <h2 class="accordion-header" id="headingOne">
                                        <button class="accordion-button btn zaap-border-straight borderless d-flex align-items-center collapsed" type="button" onClick={() => setParams("customizePageSeo", "customizePageModal")} id="btn-customizePageSEOCollapse" data-bs-toggle="collapse" data-bs-target="#customizePageSEOCollapse" aria-expanded="false" aria-controls="customizePageSEOCollapse">
                                            <img src={customizeModalSeoIcon} width="40px" alt="" />
                                            <h5 class="pt-1 mb-0 ms-3 font-weight-500">SEO</h5>
                                        </button>
                                    </h2>
                                    <div id="customizePageSEOCollapse" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                        <div class="accordion-body px-2 customize-page-main">
                                            <div class="accordion-body px-2 customize-page-main">
                                                <SeoCustomize handleSubmit={handleSubmit} error={error} settings={settings?.seo} />

                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="accordion-item borderless">
                                    <h2 class="accordion-header" id="headingOne">
                                        <button class="accordion-button btn zaap-border-straight borderless d-flex align-items-center collapsed" type="button" onClick={() => setParams("customizePageIntegrations", "customizePageModal")} id="btn-customizePageIntegrationsCollapse" data-bs-toggle="collapse" data-bs-target="#customizePageIntegrationsCollapse" aria-expanded="false" aria-controls="customizePageIntegrationsCollapse">
                                            <img src={customizeModalIntegrationIcon} width="40px" alt="" />
                                            <h5 class="pt-1 mb-0 ms-3 font-weight-500">Integrations</h5>
                                        </button>
                                    </h2>
                                    <div id="customizePageIntegrationsCollapse" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                        <div class="accordion-body px-2 customize-page-main">

                                            <IntegrationsCustomize setShowCustomizeParent={setShowCustomizeParent} />
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div> */}
                    </div>

                </div>
            </div>
            {/* {console.log("showSaveChanges",showSaveChanges)} */}
            {
                showSaveChanges && showSaveChanges?.formId?.length > 0 &&
                <div className={`unsave_changes_container ${showSaveChanges?.highlight && ' highlight '}`}>
                    <span>
                        Heads up -- you have unsaved changes 
                    </span>
                    <span className="d-flex gap-2 align-items-center">
                        <button className="revert_btn" onClick={revertChanges}>
                            Revert
                        </button>
                        <button  type="button" className="blue-btn2-u" onClick={submitAllChanges}>
                            Save Changes
                        </button>
                    </span>
                </div>
            }
            {alert && <AlertMessage
                    show={alert}
                    setShow={setAlert}
                    type={alert?.type} // "error", "warning"
                    title={alert?.title}
                    description={alert?.description}
                />}
        </Modal>
    )
}

export const CustomizeDomainModal = ({ show, setshow, setShowCustomizeParent }) => {
    const history = useHistory()
    const dispatch = useDispatch()
    const [error, setError] = useState([])
    const { basePath, slug } = useSelector(state => state?.slugReducer);
    const value = useSelector(state => state?.customizeDomainsReducer);
    const [showModal, setShowModal] = useState(true)

    const handleChange = (e) => dispatch({ type: "SET_CUSTOMIZE_DOMAINS", payload: { ...value, [e.target.name]: e.target.value } })

    const handleSubmit = (e, values) => {
        setShowModal(false)
        e.preventDefault()
        setShowCustomizeParent(false)
        // showLoader();
        setTimeout(() => {
            dispatch({
                type: "SET_DOMAIN_MODAL_SHOW", payload: {
                    dotModal: true,
                    connectedModal: false,
                }
            });
            // hideLoader()
        }, 200);
        setError([]);
        var myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${localStorage?.getItem("token")}`);
        const formdata = new FormData();
        const formValues = { ...values, project: slug };

        for (const key in formValues) {
            if (Object.hasOwnProperty.call(formValues, key)) {
                formdata.append(key, formValues[key]);
            }
        }

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        fetch(apiUrl + "/project/update", requestOptions)
            .then(async (res) => {
                const response = await res?.json();
                if (response?.status_code == 200) {
                    toast.success(response?.status_message);
                    handleDotModals()
                }

                else {
                    let errorList = []
                    response?.errors?.domain?.map((val) => errorList?.push(val));
                    setError(errorList);
                    dispatch({
                        type: "SET_DOMAIN_MODAL_SHOW", payload: {
                            dotModal: false,
                            connectedModal: false,
                        }
                    });
                    setTimeout(() => {

                        setShowModal(true)
                    }, 200);
                    toast.error(response?.status_message)
                }
            })
            .catch((err) => {
                dispatch({
                    type: "SET_DOMAIN_MODAL_SHOW", payload: {
                        dotModal: false,
                        connectedModal: false,
                    }
                });
                // handleClose()
            })
    }
    const handleClose = () => {
        setTimeout(() => {
            history.push(`${basePath}/page-editor/customizePageModal/customizePageDomains`)
        }, 300);
    }

    const handleDotModals = () => {
        history.push(`${basePath}/page-editor/`)
        dispatch({
            type: "SET_DOMAIN_MODAL_SHOW", payload: {
                dotModal: false,
                connectedModal: true,
            }
        });
    }
    return (
        <Modal show={!showModal ? showModal : show} onHide={handleClose} id="customDomainModal" centered>
            <div class="modal-dialog-centered" role="document">
                <div class="modal-content modal-semi-curve overflow-none">

                    <div class="modal-body">

                        <form name="create_folder" method="post" role="form" onSubmit={(e) => handleSubmit(e, value)}>
                            <div class="notification-container"></div>


                            <div class="form-group">
                                <img src={domainIcon} width="30px" alt="" />
                                <h4 class="mt-2 mb-1">Connect domain</h4>
                                <p class="text-gray font-weight-500">Use your own domain for your Znaplink page and products.</p>
                            </div>

                            <div class="form-group">
                                <label><small>Step 1: Add an A record to your DNS</small></label>
                                <input type="text" class={`form-control zaap-form-control`} name="name" required="required" placeholder="" value="52.201.4.2" readonly />
                            </div>

                            <div class="form-group mt-3">
                                <label><small>Step 2: Enter your domain</small></label>
                                <input type="url" class={`form-control zaap-form-control`} name="domain" value={value?.domain} onChange={handleChange} required="required" placeholder="https://mydomain.com" />
                                <ModalErrors text={error} />
                            </div>

                            <div class="text-center mt-4 d-flex">
                                <button type="button" onClick={handleClose} class="close btn btn-grey btn-semi-rounded me-2 d-flex align-items-center justify-content-center">
                                    <b class="me-2">Cancel</b>
                                    <small class="badge bg-light text-dark p-1">Esc</small>
                                </button>
                                <button type="submit" data-bs-toggle="modal" data-bs-target="#customDomainConnectingDotsModal" class="btn btn-primary btn-semi-rounded d-flex align-items-center justify-content-center">
                                    <b class="me-2">Save Changes</b>
                                    <small class="badge bg-primary-dark text-dark px-2 py-1 d-flex align-items-center justify-content-center">
                                        <img src={returnIcon} alt="" />
                                    </small>
                                </button>
                            </div>
                        </form>
                    </div>

                </div>
            </div>
        </Modal>
    )

}
export default CustomizePage