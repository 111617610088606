import { useEffect } from "react";
import { useLocation } from "react-router-dom";

function DynamicTitle({ defaultTitle }) {
    const location = useLocation();
    const pathSegments = location.pathname.split("/").filter(Boolean);
    const lastSegment = pathSegments.length > 0 ? `/${pathSegments[pathSegments.length - 1]}` : "/";

    useEffect(() => {
        const titles = {
            "/": "Zaap | The most advanced link-in-bio used by thousands of creators",
            "/dashboard": "Zaap | Dashboard",
            "/page-editor": "Zaap | Page Editor",
            "/audience": "Zaap | Audience",
            "/products": "Zaap | Products",
            "/orders": "Zaap | Orders",
            "/codes": "Zaap | Discount Codes",
            "/post": "Zaap | Blog",
            "/newsletter": "Zaap | Email Marketing",
            "/page": "Zaap | Page Analytics",
            "/posts": "Zaap | Post Analytics",
            "/links": "Zaap | Link Analytics",
            "/settings": "Zaap | Settings",
            "/account-settings": "Zaap | Account Settings",
        };

        document.title = titles[lastSegment] || defaultTitle;
    }, [lastSegment]);

    return null;
}

export default DynamicTitle;
