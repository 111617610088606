import React from 'react'
import { Modal } from 'react-bootstrap';
import { useEffect, useState } from "react";
import blockIcon from "../../../../../assets/img/block-icon.svg";
import returnIcon from "../../../../../assets/img/modals/return.svg";
import CroppieModal from '../../../../modals/croppie-modal';
import { ReactSortable } from 'react-sortablejs';
import removeicon from "../../../../../assets/img/products/removeicon.svg";
import { hideLoader, showLoader } from '../../../../loader';
import ErrorMessage from '../../../../errors/errorMessage';

function GobalblockPage({ type, error, setError, showSectionChildModal, setShowSectionChildModal, handleSubmit, handleUpdate, data, close }) {

    const [title, setTitle] = useState();
    const [description, setDescription] = useState();
    const [cover_image, setCoverImage] = useState();
    const [croppie, setCroppie] = useState(null);
    const [croppie_image, setcroppie_image] = useState({ url: "", id: null });
    const [showCroppieModal, setshowCroppieModal] = useState(null);
    const [coverImageURl, setCoverImageURl] = useState(null);
    const [features, setFeatures] = useState([""]);
    const [alignment, setAlignment] = useState("right");
    const handleopenmodal = (e) => {
        setcroppie_image({ url: "", id: null, name: null, type: null });
        generate_image_preview(e.target);
        hideLoader();
    };
    const handleHideModal = () => {
        setshowCroppieModal(false);
        setCroppie(null);
        setcroppie_image({ url: "", id: null });
        // history.push(basePath + "/products");
      };
      const croppieClose = () => {
        showLoader();
        setshowCroppieModal(false);
        setCroppie(null);
        setTimeout(() => {
          hideLoader();
        }, 400);
      };
      const croppedImage = () => {
        if (croppie !== null) {
          croppie
            .result({
              type: "canvas",
              size: "original",
              format: "png",
              quality: 1,
            })
            .then((response) => {
              const tempObj = { image_url: response };
              croppie
                .result({
                  size: "viewport",
                  format: "png",
                  quality: 1,
                  type: "blob",
                })
                .then((res) => {
                  setCoverImage(
                    {
                      cover_image:res
                    }
                  )
                  setCoverImageURl(croppie?.data?.url)
                  croppieClose()
                });
            });
        }
      };
      function Removecardfun() {
        setCoverImage(null)
        setCoverImageURl(null)
      }
      function generate_image_preview(input) {
        showLoader();
        if (input.files && input.files[0]) {
          let reader = new FileReader();
          if (input.files[0].size >= 5242880) {
            $("#image_file_input").val("");
            let notification_container = $(".notification-container");
            toast.error("File Size Should not exceed 1MB");
            // showModal();
            return;
          }
          let val = input.files[0].name;
          let valid_extensions = ["png", "jpg", "jpeg"];
          let extension =
            val.substring(val.lastIndexOf(".") + 1, val.length) || val;
          if (!valid_extensions.includes(extension?.toLowerCase())) {
            $("#image_file_input").val("");
            let notification_container = $(".notification-container");
            toast.error("Invalid Image File. Should be png, jpg or jpeg");
            // showModal();
            return;
          }
          reader.onload = (event) => {
            setcroppie_image({
              ...croppie_image,
              url: event.target.result,
            });
            setshowCroppieModal(true);
            setTimeout(() => {
              const el = document.getElementById("image_demo2");
              try {
                if (el) {
                  var croppieInstance = new Croppie(el, {
                    enableExif: true,
                    viewport: {
                      width: 850,
                      height: 470,
                      type: "square",
                    },
                    boundary: {
                      width: 870,
                      height: 570,
                    },
                  });
                  croppieInstance
                    .bind({
                      url: event.target.result,
                    })
                    .then(function () {
                      setTimeout(() => { }, 300);
                    })
                    .catch(() => {
                      croppieClose();
                    });
                  setCroppie(croppieInstance);
                }
              } catch { }
            }, 500);
          };
          reader.readAsDataURL(input.files[0]);
        }
      }
      const handleFormAction = (actionType, e) => {
        let formData;
        if (type !== "pricing") {
            formData = {title, description, cover_image:cover_image?.cover_image, alignment};
        }else{
            formData = {title, features:features.filter(feature => feature.trim() !== '')};
            if (!formData.features || formData.features.length === 0) {
            setError(["Feature is required"])
            return false;
            }else if(!formData.title){
            setError(["Title is required"])
            return false;
            }
        }
    
        if (actionType === 'save') {
            handleSubmit(formData, type);
        } else if (actionType === 'update') {
            handleUpdate(formData, type, e.target.dataset?.value);
        }
    };
    //   const handleSave = () => {
    //     let formData;
    //     if (type !== "pricing") {
    //        formData = {title, description, cover_image:cover_image?.cover_image};
    //     }else{
    //        formData = {title, features:features.filter(feature => feature.trim() !== '')};
    //        if (!formData.features || formData.features.length === 0) {
    //         setError(["Feature is required"])
    //         return false;
    //        }else if(!formData.title){
    //         setError(["Title is required"])
    //         return false;
    //        }
    //     }
    //     handleSubmit(formData,type);
    //   }
    //   const handleUpdateData = (e) => {
    //       let formData;
    //       if (type !== "pricing") {
    //         formData = {title, description, cover_image:cover_image?.cover_image};
    //     }else{
    //         formData = {title, features:features.filter(feature => feature.trim() !== '')};
    //         if (!formData.features || formData.features.length === 0) {
    //         setError(["Feature is required"])
    //         return false;
    //         }else if(!formData.title){
    //         setError(["Title is required"])
    //         return false;
    //         }
    //     }
    //     handleUpdate(formData,type,e.target.dataset?.value);
    // };
    useEffect(()=>{
      if (data?.meta) {
        setTitle(JSON?.parse(data?.meta)?.title)
        if (type == 'pricing') {
        setFeatures(JSON?.parse(data?.meta)?.features);
        }else{
          setAlignment(JSON?.parse(data?.meta)?.alignment ?? 'right');
          setDescription(JSON?.parse(data?.meta)?.description)
          setCoverImage({cover_image:JSON?.parse(data?.meta)?.cover_image})
          setCoverImageURl(JSON?.parse(data?.meta)?.cover_image)
        }
      }
  },[data?.meta])
  const handleFeatureChange = (index, value) => {
    const updatedFeatures = [...features];
    updatedFeatures[index] = value;
    setFeatures(updatedFeatures);
  };
  function addFeature() {
    setFeatures([...features, ""]);
  }
  const removeFeature = (i) => {
    const newarr = [...features];
    newarr.splice(i, 1);
    setFeatures(newarr);
  }
  function autoResize(textarea) {
    if (textarea.value.length > 80) {
      textarea.style.height = "auto";
      textarea.style.height = textarea.scrollHeight + "px";
    }else{
      textarea.style.height = "32px";
    }
  }
  const handleAlignmentChange = (e) => {
    setAlignment(e.target.value);
  };
    return (
        <Modal centered show={showSectionChildModal} onHide={close}>
            <div className="modal-dialog-centered" role="document">
                <div className="modal-content">
                    <div className="modal-body">
                        <form name="product_type" method="post" role="form">
                            <div className="notification-container"></div>
                            <div className="form-group">
                                <img src={blockIcon} width="40px" alt="" />
                                {type === 'horizontal' ?
                                    <h4 className="productHead1 mt-15px">Edit Horizontal Section</h4>
                                    :
                                    (type === 'vertical' ?
                                    <h4 className="productHead1 mt-15px">Edit Vertical Section</h4>
                                    :
                                    <h4 className="productHead1 mt-15px">Edit Pricing Section</h4>
                                    )
                                  }

                            </div>
                                  <ErrorMessage error={error} />
                            <div className="form-group mt-20px">
                                {type === 'pricing' ?
                                     <label className="productlabels">
                                        Pricing Section Title
                                    </label>
                                    :
                                    <label className="productlabels">
                                        Headline
                                    </label>
                                }
                                <input
                                    type="text"
                                    className="zaapGlobal-input mt-10px"
                                    name="headline"
                                    placeholder={type === 'pricing' ? "Title" : "Add a feature or benefit"}
                                    value={title}
                                    onChange={(e) => setTitle(e.target.value)}
                                />
                            </div>
                            <div className="form-group mt-20px">
                                {type === 'pricing' ?
                                     <label className="productlabels">
                                        Feature
                                    </label>
                                    :
                                    <label className="productlabels">
                                        Description
                                    </label>
                                }
                                {
                                  type === 'pricing'
                                    ? features?.map((feature, i) => (
                                        <div className="form-group zaapGlobal-input mt-10px d-flex align-items-center " key={i}>
                                          <input
                                            type="text"
                                            className="color-5b5b5b"
                                            name="headline"
                                            placeholder={"Add feature"}
                                            value={feature}
                                            onChange={(e) => handleFeatureChange(i, e.target.value)}
                                          />
                                          {features.length > 1 ? 
                                            <img src={removeicon} width={"13px"} style={{marginLeft:'10px'}} onClick={(a) => removeFeature(i)}/>
                                         : <></>}
                                        </div>
                                      ))
                                    : (
                                        
                                          <textarea
                                            type="text"
                                            className="zaapGlobal-input mt-10px hidden-scroll"
                                            name="headline"
                                            placeholder={"Add more details"}
                                            value={description}
                                            onChange={(e) => setDescription(e.target.value)}
                                            onInput={(e) => autoResize(e.target)}
                                            style={{height:"32px"}}
                                          >
                                          </textarea>
                                        
                                      )
                                    }
                            </div>
                            <div className="form-group mt-20px">
                                {type === 'pricing' ?
                                     <span></span>
                                    :
                                    <label className="productlabels">
                                        Section Image
                                    </label>
                                }
                                {
                                  type === 'pricing' ?
                                  <button type="button" className="newGraybtn position-relative" onClick={addFeature}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                                          <path fill-rule="evenodd" clip-rule="evenodd" d="M6 0C6.41421 0 6.75 0.335786 6.75 0.75V5.25H11.25C11.6642 5.25 12 5.58579 12 6C12 6.41421 11.6642 6.75 11.25 6.75H6.75V11.25C6.75 11.6642 6.41421 12 6 12C5.58579 12 5.25 11.6642 5.25 11.25V6.75H0.75C0.335786 6.75 0 6.41421 0 6C0 5.58579 0.335786 5.25 0.75 5.25H5.25V0.75C5.25 0.335786 5.58579 0 6 0Z" fill="#333333" />
                                      </svg>Add feature
                                  </button> 
                                  :
                                <>
                                {!coverImageURl?
                                <button type="button" className="newGraybtn position-relative ">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M6 0C6.41421 0 6.75 0.335786 6.75 0.75V5.25H11.25C11.6642 5.25 12 5.58579 12 6C12 6.41421 11.6642 6.75 11.25 6.75H6.75V11.25C6.75 11.6642 6.41421 12 6 12C5.58579 12 5.25 11.6642 5.25 11.25V6.75H0.75C0.335786 6.75 0 6.41421 0 6C0 5.58579 0.335786 5.25 0.75 5.25H5.25V0.75C5.25 0.335786 5.58579 0 6 0Z" fill="#333333" />
                                        </svg>Upload New
                                        <input
                                            type="file"
                                            className="dropzone dropzone4 inset-0"
                                            name="cover_image"
                                            accept=".png,.jpg,.jpeg"
                                            id="image_file_input"
                                            onChange={(e)=>handleopenmodal(e)}
                                        />
                                </button>:null
                                }
                                <div className="preview-zone hidden "></div>
                                <div >
                                    {
                                    coverImageURl &&
                                    <div className='d-flex align-items-center mt-15px'>
                                        <div>
                                            <img className="prevImageU" src={coverImageURl} />
                                        </div>
                                        <div>
                                            <img onClick={(e) => Removecardfun()} src={removeicon} width={"22px"} style={{ padding: 4, cursor: "pointer" }} />
                                        </div>
                                    </div>
                                    }
                                </div>
                                </>
                                }
                                { type == "horizontal" || type == "vertical" ?
                            
                                    <div class="form-group mt-3">
                                      <label><small>Section Image Position</small></label>
                                      <div class={`up-in-toggle up-in-toggle-alignment bg-f1f1f1 ${type} btn-semi-rounded`}>
                                          <input type="radio" id="text_align_left" name="alignment" checked={alignment == "left" } value={"left"} onChange={handleAlignmentChange} />
                                          <label htmlFor="text_align_left" class="btn-semi-rounded">
                                              <span> {type == "vertical" ? "Top" : "Left"}</span>
                                          </label>
                                          <input type="radio" id="text_align_right" checked={alignment == "right"} name="alignment" value={"right"} onChange={handleAlignmentChange} />
                                          <label htmlFor="text_align_right" class="btn-semi-rounded">
                                              <span> {type == "vertical" ? "Bottom" : "Right"}</span>
                                          </label>
                                      </div>
                                </div> : null
                                    }
                            </div>
                            <div class="text-center d-flex submit-buttons-fixed mt-20px">
                              <button type="button" onClick={close} class="close btn btn-grey btn-semi-rounded me-2 d-flex align-items-center justify-content-center save-btn-u">
                                  <span class="me-2 font-weight-500">Cancel</span>
                                  <small class="badge bg-light text-dark p-1">Esc</small>
                              </button>
                              <button  data-value={data?.id} type="button" onClick={(e) => handleFormAction((data?.id ? 'update' : 'save'),e)} class="btn btn-primary btn-semi-rounded d-flex align-items-center justify-content-center save-btn-u">
                                  <span data-value={data?.id} class="me-2 font-weight-500">Save Changes</span>
                                  <small  data-value={data?.id} class="badge bg-primary-dark text-dark px-2 py-1 mt-1 d-flex align-items-center justify-content-center">
                                      <img src={returnIcon} alt="" />
                                  </small>
                              </button>
                          </div>                       
                        </form>
                    </div>
                </div>
            </div>
            <CroppieModal show={showCroppieModal} handleHide={handleHideModal} croppieClose={croppieClose} croppedImage={croppedImage} modalWidth={500} productCroppie={"productCroppie"} />
        </Modal>
        
    )
}

export default GobalblockPage