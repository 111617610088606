import React, { createContext, useState, useContext, useRef, useEffect } from 'react';
import { apiUrl } from '../utils/url';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { hideLoader, showLoader } from '../components/loader';
import Clarity from '@microsoft/clarity';
import { redirectToWalkthrough } from '../auth/google-sign';

const SidebarContext = createContext();

export const SidebarProvider = ({ children }) => {
    // State for project data
    const [projectsState, setProjectsState] = useState([]);
    const [sharedProjectsState, setSharedProjectsState] = useState([]);
    const [isLoadingState, setIsLoadingState] = useState(true);
    const [basePathState, setBasePathState] = useState(null);
    const [selectedSlugState, setSelectedSlugState] = useState(null);
    const [selectedProfileState, setSelectedProfileState] = useState(null);
    const [selectedProjectState, setSelectedProjectState] = useState(true);
    const [isProjectLoading, setIsProjectLoading] = useState(true);
    const dispatch = useDispatch()

    // Project display info
    const [profile, setProfile] = useState(false);
    const [title, setTitle] = useState(false);
    const [projectSlug, setProjectSlug] = useState(false);

    // Ref to track if data has been fetched
    const dataFetchedRef = useRef(false);

    const router = useHistory();
    const { slug } = useSelector(state => state?.slugReducer || {});

    const getProjects = () => {
        // Don't fetch if we've already loaded projects
        if (projectsState.length > 0) {
            setIsLoadingState(false);
            return;
        }

        showLoader();
        var myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${localStorage?.getItem("token")}`);

        fetch(apiUrl + "/project/get-all", {headers: myHeaders})
            .then(async (res) => {
                try {
                    const response = await res.json();

                    if (response?.status_code === 401 && response?.status_message?.toLowerCase() === "unauthorized") {
                        router.push("/logout");
                        return;
                    }

                    if (response?.data?.shared_projects.length === 0 && response?.data.projects.length === 0) {
                        localStorage.setItem("currentStep", 1);
                        localStorage.setItem("lastStep", 0);
                        localStorage.setItem("walkthrough", 0);
                        redirectToWalkthrough(1, router);
                    }

                    // Update state with fetched data
                    setSharedProjectsState(response?.data?.shared_projects || []);
                    setIsProjectLoading(false);
                    setProjectsState(response?.data.projects || []);
                    setIsLoadingState(false);

                    // Handle project selection based on URL path
                    // if (window.location.pathname.includes("dashboard") === false && !window.location.pathname.includes('account-settings')) {
                        var currentSlug = window.location.pathname.split("/")[2] ?? localStorage?.slug;
                        var matchingProject = response?.data.projects.find(project =>
                            project.slug.toLowerCase() === currentSlug?.toLowerCase()
                        );

                        var sharingProject = response?.data.shared_projects.find(sharedproject =>
                            sharedproject.project.slug.toLowerCase() === currentSlug?.toLowerCase()
                        );

                        if (matchingProject) {
                            setSelectedProjectState(true);
                            setSelectedProfileState(matchingProject?.id);
                            setBasePathState("/project/" + matchingProject?.slug);
                            setSelectedSlugState(matchingProject?.slug);
                            setProjectData(matchingProject);
                            localStorage.slug = matchingProject?.slug;
                        } else if (sharingProject) {
                            setSelectedProjectState(false);
                            setSelectedProfileState(sharingProject?.project?.id);
                            setBasePathState("/project/" + sharingProject?.project?.slug);
                            setProjectData(sharingProject?.project);
                        } else {
                            if (response?.data?.projects?.length > 0) {
                                matchingProject = response?.data?.projects[0];
                                setSelectedProjectState(true);
                                setSelectedProfileState(matchingProject?.id);
                                setBasePathState("/project/" + matchingProject?.slug);
                                setSelectedSlugState(matchingProject?.slug);
                                setProjectData(matchingProject);
                                localStorage.slug = matchingProject?.slug;
                            } else if (response?.data.shared_projects?.length > 0) {
                                sharingProject = response?.data.shared_projects[0];
                                setSelectedProjectState(false);
                                setSelectedProfileState(sharingProject?.project?.id);
                                setProjectData(sharingProject?.project);
                                setBasePathState("/project/" + sharingProject?.project?.slug);
                            }
                        }
                    // }

                    // Log Rocket
                    if (response?.data?.user_name && response?.data?.user_email && response?.data?.user_id) {
                        Clarity.identify(`${response?.data?.user_name}`);
                    }
                } catch (error) {
                    console.error("Error parsing response:", error);
                }

                hideLoader();
            })
            .catch((err) => {
                console.error("Error fetching projects:", err);
                setIsProjectLoading(false);
                hideLoader();
            });
    };

    // Update profile info when selected project changes
    useEffect(() => {
        if (selectedProjectState) {
            const currentProject = projectsState.find(val => val?.id === selectedProfileState);
            if (currentProject) {
                setProfile(currentProject?.settings[1]?.value);
                setTitle(currentProject?.settings[0]?.value);
                setProjectSlug(currentProject?.slug);
            }
        } else {
            const currentSharedProject = sharedProjectsState.find(val => val?.project?.id === selectedProfileState);
            if (currentSharedProject) {
                setProfile(currentSharedProject?.project?.settings[1]?.value);
                setTitle(currentSharedProject?.project?.settings[0]?.value);
                setProjectSlug(currentSharedProject?.project?.slug);
            }
        }
    }, [selectedProfileState, projectsState, sharedProjectsState]);

    // Change the active project
    const changeProject = (e, id, slug, picture, name, isProjectEnabled = false) => {
        e && e.preventDefault();
        localStorage.slug = slug;
        setTitle(name);
        setProfile(picture);
        setProjectSlug(slug);

        setSelectedProfileState(id);
        setBasePathState("/project/" + slug);
        setSelectedSlugState(slug);
        dispatch({ type: "SET_SLUG", payload: { slug: slug, basePath: "/project/" + slug } })

        if (!window.location.pathname.includes("/dashboard")) {
            var currentPathName = window.location.pathname.split("/")[3];
            router.replace("/project/" + slug + "/" + currentPathName);
        }
    };

    // Refresh projects data
    const refreshProjects = () => {
        dataFetchedRef.current = false;
        setProjectsState([]);
        getProjects();
    };

    // Load projects only once
    useEffect(() => {
        // Only fetch data if we haven't already
        if (dataFetchedRef.current) return;

        if (projectsState.length === 0) {
            dataFetchedRef.current = true;
            getProjects();
        } else {
            localStorage.removeItem("sendRequest");
        }
    }, []);

    // Create the context value object with all state variables and functions
    const contextValue = {
        projectsState,
        setProjectsState,
        sharedProjectsState,
        setSharedProjectsState,
        isLoadingState,
        setIsLoadingState,
        basePathState,
        setBasePathState,
        selectedSlugState,
        setSelectedSlugState,
        selectedProfileState,
        setSelectedProfileState,
        selectedProjectState,
        setSelectedProjectState,
        profile,
        title,
        projectSlug,
        isProjectLoading,
        getProjects,
        changeProject,
        refreshProjects,
        dataFetchedRef
    };
    // Set Project Basic Details
    const setProjectData = (response) => {
        // dispatch({ type: "SET_PROJECT_LOADED", payload: {loaded:true}});
        // dispatch({ type: "SET_PRO", payload: response?.pro });
        // dispatch({ type: "SET_PlAN_TYPE", payload: response?.plan_type });
        dispatch({ type: "SET_SLUG", payload: { slug: response?.slug, basePath: "/project/" + response?.slug } })
    }
    return (
        <SidebarContext.Provider value={contextValue}>
            {children}
        </SidebarContext.Provider>
    );
};

export const useSidebar = () => {
    const context = useContext(SidebarContext);
    if (context === undefined) {
        throw new Error('useSidebar must be used within a SidebarProvider');
    }
    return context;
};