import Login from "../pages/login";
import ForgetPassword from "../pages/forgot-password";
import Signup from "../pages/signup";
import ResetPassword from "../pages/reset-password";
import CallbackLogin from "../auth/callback-login";
import { AcceptInvite } from "../pages/accept-invite";
import Step1 from "../components/walkthrough-steps/step1";

const authRoutes = [
    {
        exact: false,
        path: "/register",
        component: Signup
    },
    {
        exact: false,
        path: "/create-url",
        component: Step1
    },
    {
        exact: false,
        path: "/login",
        component: Login
    },
    {
        exact: false,
        path: "/forgot-password",
        component: ForgetPassword
    },
    {
        exact: false,
        path: "/reset-password",
        component: ResetPassword
    },
    {
        path: "/google-oauth",
    },
    {
        exact: false,
        path: "/callback-login",
        component: CallbackLogin,
    },
    {
        exact: false,
        path: "/accept-invite",
        component: AcceptInvite,
    },

]
export default authRoutes