import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { hideLoader } from "../components/loader"
import { audiencePosts as oathPost } from "../api/audience";
import { apiUrl } from "../utils/url";
import { useAlert } from "../contexts/AlertContext";

const StripeAddOnCheckout = () => {
    const dispatch = useDispatch();
    const history = useHistory()
    const params = new URLSearchParams(window.location.search);
    const [status, setstatus] = useState(params?.get("status"));
    const selectedEmailPlan =  useSelector(state => state.selectedEmailPlanReducer)
    const { setAlert } = useAlert();
    useEffect(() => {
        if (status) sendRequest();
    }, [])

  const redirectToPage = () => {

      setTimeout(() => {
          history.push(localStorage.last_activity)
          localStorage.removeItem("lastActivity")
        }, 1000);
    }

    const sendRequest = () => {
        if (localStorage.getItem("sendRequest")) return true
        localStorage.setItem("sendRequest", true)
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${localStorage?.getItem("token")}`);
        const formdata = new FormData()
        let planType = localStorage?.getItem("addon_plan_type");
        let planId = localStorage?.getItem("addon_plan_id");
        formdata.append("project", localStorage?.getItem("temp_slug") ? localStorage?.getItem("temp_slug") : localStorage?.getItem("slug"))
        formdata.append("status", status)
        formdata.append("addon_plan_type", planType)
        formdata.append("addon_plan_id", planId)

        const api = apiUrl + "/stripe-addon-checkout/callback";
        const setOptions = {
            method: "POST",
            body: formdata,
            headers: myHeaders
        }
        oathPost(setAlert, api, hideLoader, setOptions).then((response) => {
            if (response) {
               
                localStorage.removeItem("sendRequest")
                dispatch({ type: "SET_PRO", payload: true })
                dispatch({ type: "SET_SELECTED_EMAIL_PLAN", payload: {...selectedEmailPlan, email_plan_id:planId} })
                // window.opener.postMessage({ isPro: true });
                redirectToPage()
            }
            else {
                dispatch({ type: "SET_SELECTED_EMAIL_PLAN", payload: {...selectedEmailPlan, email_plan_id:null} })
                localStorage.removeItem("lastActivity")
                localStorage.removeItem("temp_slug")
                redirectToPage()

            }
            
            localStorage.removeItem("temp_slug")
        }).catch(() => {
            redirectToPage()
        })
    }

    return (
        <div class="full-page-loader">
            <div class="lds-ripple"><div></div><div></div></div>
        </div>
    )
}
export default StripeAddOnCheckout