import { Modal } from "react-bootstrap"

const CroppieModal = ({ show, croppedImage, croppieClose, handleHide, modalWidth, from , postWidth, productCroppie }) =>
{
    const setHeading = () =>
    {
        if (from == "editor-link") {
            return "Crop Link Image"
        }
        else if (from == "banners") {
            return "Crop Profile Banner"
        }
        else {
            return "Crop Image"
        }
    }

    const width =  productCroppie == "productCroppie" ? "productCroppie" : postWidth ? "postWidth" : "productModal";

    return (
        <Modal centered show={show} onHide={handleHide} id={modalWidth ? width : "ppModal"}>
            <Modal.Header class="modal-header create-biolink-header py-2 px-3">
                <h5 class="modal-title">
                    {setHeading()}
                </h5>
                <button type="button" class="close" onClick={croppieClose} aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </Modal.Header>
            <Modal.Body>
                <div class="row">
                    <div class="col-md-12 text-center">
                        <div id="image_demo2" style={modalWidth ? null : { width: "350px", margin: "0 auto", marginTop: "30px" }}></div>
                    </div>
                    <div class="col-md-12 text-center pt-2 d-flex justify-content-center">
                        <button className="blue-btn py-2 px-3 border-radius-20" onClick={croppedImage}><svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0.8 10.4C1.21027 10.4 1.54841 10.7088 1.59462 11.1067L1.6 11.2V12.8C1.6 13.6435 2.2527 14.3345 3.08059 14.3956L3.2 14.4H4.8C5.24183 14.4 5.6 14.7582 5.6 15.2C5.6 15.6103 5.29117 15.9484 4.8933 15.9946L4.8 16H3.2C1.48624 16 0.0871376 14.6528 0.00391693 12.9597L0 12.8V11.2C0 10.7582 0.358172 10.4 0.8 10.4ZM15.2 10.4C15.6418 10.4 16 10.7582 16 11.2V12.8L15.9961 12.9597C15.9129 14.6528 14.5138 16 12.8 16H11.2L11.1067 15.9946C10.7088 15.9484 10.4 15.6103 10.4 15.2C10.4 14.7582 10.7582 14.4 11.2 14.4H12.8L12.9194 14.3956C13.7473 14.3345 14.4 13.6435 14.4 12.8V11.2L14.4054 11.1067C14.4516 10.7088 14.7897 10.4 15.2 10.4ZM4.8 0C5.24183 0 5.6 0.358172 5.6 0.8C5.6 1.21027 5.29117 1.5484 4.8933 1.59462L4.8 1.6H3.2C2.35651 1.6 1.66547 2.2527 1.60439 3.08059L1.6 3.2V4.8C1.6 5.24183 1.24183 5.6 0.8 5.6C0.389731 5.6 0.0515939 5.29117 0.00538178 4.8933L0 4.8V3.2C0 1.48624 1.34718 0.0871393 3.04029 0.00391846L3.2 0H4.8ZM12.8 0L12.9597 0.00391846C14.6528 0.0871393 16 1.48624 16 3.2V4.8L15.9946 4.8933C15.9484 5.29117 15.6103 5.6 15.2 5.6C14.7582 5.6 14.4 5.24183 14.4 4.8V3.2L14.3956 3.08059C14.3345 2.2527 13.6435 1.6 12.8 1.6H11.2L11.1067 1.59462C10.7088 1.5484 10.4 1.21027 10.4 0.8C10.4 0.358172 10.7582 0 11.2 0H12.8Z" fill="white" /></svg>&nbsp;&nbsp;Crop & Upload Image</button>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}
export default CroppieModal