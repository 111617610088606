import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { redirectToWalkthrough } from "./google-sign";
import { showLoader } from "../components/loader";
import { apiUrl } from "../utils/url";

const CallbackLogin = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const params = new URLSearchParams(location.search);

  const sendRequest = () => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${params?.get("token")}`);
    const formdata = new FormData();
    const api = apiUrl + "/token-validate";
    const setOptions = {
      method: "POST",
      body: formdata,
      headers: myHeaders,
    };
    fetch(api, setOptions)
      .then(async (res) => {
        const response = await res.json();
        if (response?.status_code == 200) {
          localStorage.setItem("token", response?.data?.token);
          localStorage.setItem("email", response?.data?.email);
          localStorage.setItem("user", response?.data?.name);
          localStorage.setItem("walkthrough", response?.data?.walkthrough);
          localStorage.setItem(
            "walkthrough_step",
            response?.data?.walkthrough_step
          );
          localStorage.setItem("currentStep", response?.data?.walkthrough_step);
          localStorage.setItem(
            "lastStep",
            response?.data?.walkthrough_step - 1
          );
          if (response?.data?.default_project) {
            localStorage.setItem("slug", response?.data?.default_project);
            location.href = "/dashboard";
          }else{
            localStorage.setItem("currentStep", 1);
            localStorage.setItem("lastStep", 0);
            localStorage.setItem("walkthrough", 0);
            redirectToWalkthrough(1, history)
            history.push({
              pathname: `/walkthrough`,
              search: `step=1`,
              state:{"signupfromgoogle":true}
          })
          }
          return

        } else {

          toast.error(response?.status_message);
          localStorage.clear()
          setTimeout(() => {
            history.push("/login")
          }, 100);
        }
        return;
      })
      .catch((error) => {
        toast.error(error);
        localStorage.clear()
        setTimeout(() => {
          history.push("/login")
        }, 100);
        return;
      });
  };

  useEffect(() => {
    if (params.get("token")) sendRequest();
    return
  }, [params]);
  return (
    <div class="full-page-loader">
      <div class="lds-ripple">
        <div></div>
        <div></div>
      </div>
    </div>
  );
};
export default CallbackLogin;
