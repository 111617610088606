import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { redirectToWalkthrough } from "./google-sign";
import { hideLoader } from "../components/loader"
import { audiencePosts as oathPost } from "../api/audience";
import { apiUrl } from "../utils/url";
import { useAlert } from "../contexts/AlertContext";

const GoogleOauth = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const params = new URLSearchParams(window.location.search);
    const [code] = useState(params?.get("code"));
    const { setAlert } = useAlert();

    useEffect(() => {
        if (code) sendRequest();
    }, [])

    const sendRequest = () => {
        if (localStorage.sendRequest) {
            return
        }
        localStorage.sendRequest = true
        const formdata = new FormData()
        formdata.append("code", code)
        
        localStorage.getItem("invite_token") && formdata.append("invite_token", localStorage.getItem("invite_token"))
        localStorage.getItem("slug_created") && formdata.append("slug_created", localStorage.getItem("slug_created"))
        localStorage.getItem("marketing_consent") && formdata.append("marketing_consent", localStorage.getItem("marketing_consent"))
        const api = apiUrl + "/google-authenticate";
        const setOptions = {
            method: "POST",
            body: formdata
        }
        oathPost(null, api, hideLoader, setOptions,'custom_message').then((response) => {

            if (response?.status_code == 200) {
                if (response?.data?.walkthrough == 0) {
                    window?.dataLayer?.push({
                        event: 'Googlesignup',
                        userId:response?.data?.user_id,
                        name:response?.data?.name,
                        email:response?.data?.email,
                        country:response?.data?.country,
                        
                      });
                    localStorage.setItem("walkthrough", response?.data?.walkthrough);
                    localStorage.setItem("walkthrough", response?.data?.walkthrough);
                    localStorage.setItem("walkthrough_step", response?.data?.walkthrough_step);
                    localStorage.setItem("currentStep", response?.data?.walkthrough_step);
                    localStorage.setItem("lastStep", response?.data?.walkthrough_step - 1);
                    localStorage.setItem("slug", response?.data?.default_project);
                    // window.opener.postMessage({ pathname: "walkthrough", walkthrough_step: response?.data?.walkthrough_step, walkthrough: response?.data?.walkthrough, name: response?.data?.name, token: response?.data?.token })

                    setTimeout(() => {
                        localStorage.removeItem("sendRequest")
                        dispatch({ type: "SET_STEPS", payload: { lastStep: response?.data?.walkthrough_step - 1, currentStep: response?.data?.walkthrough_step, walkthrough: response?.data?.walkthrough } });

                        redirectToWalkthrough(response?.data?.walkthrough_step, history,response?.data?.walkthrough_step === 1 ? 'googlelogin':null)
                        localStorage.setItem("user", response?.data?.name);
                        localStorage.setItem("token", response?.data?.token);
                    }, 500);

                }
                else if (response?.data?.walkthrough === 1) {
                    // window.dataLayer.push({
                    //     event: 'Googlelogin',
                    //     userId:response?.data?.user_id
                    //   });
                    // window.opener.postMessage({ pathname: "dashboard", walkthrough: response?.data?.walkthrough})
                    localStorage.setItem("user", response?.data?.name);
                    localStorage.setItem("email", response?.data?.email);
                    localStorage.setItem("token", response?.data?.token);
                    localStorage.setItem("walkthrough", response?.data?.walkthrough);
                    localStorage.setItem("slug", response?.data?.default_project);
                    setTimeout(() => {
                        localStorage.removeItem("sendRequest")
                        dispatch({ type: "SET_STEPS", payload: { lastStep: response?.data?.walkthrough_step - 1, currentStep: response?.data?.walkthrough_step, walkthrough: response?.data?.walkthrough } });

                        dispatch({ type: "SET_SLUG", payload: { slug: response?.data?.default_project, basePath: "/project/" + response?.data?.default_project, newChange: false } })

                        history.push("/project/" + response?.data?.default_project + "/page-editor")
                    }, 500);
                }
            }
            else {
                if (response?.errors?.member) {
                    setAlert({ type: 'error', title: response?.errors?.member, description: response?.errors?.member_desc})
                    hideLoader();
                    return;
                }
                history.push("login")
                localStorage.removeItem("sendRequest")
            }
        }).catch((err) => {
            toast.error(err)
            history.push("login")
            localStorage.removeItem("sendRequest")
        })
    }

    return (
        <div class="full-page-loader">
            <div class="lds-ripple"><div></div><div></div></div>
        </div>
    )
}
export default GoogleOauth