import React, { useEffect, useState } from 'react'
import { TeammemberSection } from '../teammember/TeammemberSection'
import NewUpgradePage from '../upgrade/new-upgrade-page'
import NewPageHeader from '../layout/settings-page-header'
import Teammembers from '../../pages/team-members'
import ManageBilling from '../../pages/manage-billing'
import Sidebar from '../layout/Sidebar'
import Accounts from '../../pages/accounts'
import Passwords from './passwords'
import Domains from './domain'
import DeleteAccount from './delete-account'
import PageHeader from '../layout/page-header'
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min'
import AffiliateProgram from './affiliate-program'
// import Domains from './domain'

const AccountSettings = () => {
    const location = useLocation();
    const {plan, defaultActivePage} = location?.state || {};
    const [activePage, setActivePage] = useState(defaultActivePage ??'profile');
    const [sidebarOpen, setSideBarOpen] = useState(true);
    const [from, setfrom] = useState('account');

    const handleMenuClick = (page) => {
      setActivePage(page);
      if (from =="accountMobile") {
        setSideBarOpen(false)
    }
    };
    let activeContent;
    if (activePage === 'billing') {
        activeContent = <ManageBilling />;
    }else if (activePage === 'profile') {
        activeContent = <Accounts />;
    }else if (activePage === 'password') {
        activeContent = <Passwords />;
    }else if (activePage === 'delete') {
        activeContent = <DeleteAccount />;
    }else if (activePage === 'affiliate-program') {
        activeContent = <AffiliateProgram setActivePage={setActivePage}/>;
    }
    function checkScreenSize() {
      if (window.innerWidth <= 768) {
          setfrom("accountMobile")
      }else{
          setfrom("account")
      }
  }
  useEffect(()=>{
          window.addEventListener('resize', checkScreenSize);
          
          window.addEventListener('load', checkScreenSize);    
  },[])
    useEffect(()=>{
            checkScreenSize();
    },[location.pathname])
  return (
    <div id ="wrapper">
        <PageHeader from={from} plan={plan} sidebarOpen={sidebarOpen} setSideBarOpen={setSideBarOpen}/>
        <div className="community-main align-items-start">
           <Sidebar from={from} sidebarOpen={sidebarOpen} setSideBarOpen={setSideBarOpen} onMenuClick={handleMenuClick} activePage={activePage} />
           {activeContent}
            {/* <NewUpgradePage/> */}
        </div>
    </div>
  )
}

export default AccountSettings
