import { useHistory, useLocation, useParams } from "react-router-dom/cjs/react-router-dom.min";
import BlockEditor from "./page-editor/block-editor"
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";

const ModalsHandler = () => {
    const history = useHistory()
    const location = useLocation();
    const { basePath, slug } = useSelector(state => state?.slugReducer);
    const { blockEditorId, subPath } = useParams();
    const [showBlocksModal,setShowBlocksModal] = useState(true);
    if (subPath) {
        return null;
    }
    useEffect(() => {
        if (location?.state?.showChild) {
            setShowBlocksModal(false)
        }else {
            setShowBlocksModal(true)
        }
    },[location?.state?.showChild])
    const handleClose = () => {
        history.push(basePath + "/page-editor")
    }
    // if (!showChild) {
        if (blockEditorId == "newBlockModal" && showBlocksModal)
            return <BlockEditor slug={slug} show={showBlocksModal} handleClose={handleClose} />
        // else if (blockEditorId == "customizePageModal")
        //     return <CustomizePageModal show={show} handleClose={handleClose} />
        else return
        
    // }
}

export default ModalsHandler