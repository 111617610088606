import analyticsUp from "../assets/img/analytics-up.svg"
import Sidebar from "../components/layout/Sidebar";
import 'react-loading-skeleton/dist/skeleton.css'
import Skeleton from "react-loading-skeleton";
import { useEffect, useState } from "react";
import PageHeader from "../components/layout/page-header";
import { showLoader, hideLoader } from "../components/loader";
import { apiUrl, pageUrl, protocol } from "../utils/url";
import { toast } from "react-toastify";
import chainImage from "../assets/img/dashboard/chain.png"
import funnelImage from "../assets/img/dashboard/funnel.png"
import targetImage from "../assets/img/dashboard/target.png"
import goldPlateImage from "../assets/img/dashboard/gold-plate.png"
import heartImage from "../assets/img/dashboard/heart.png"
import calendarHalfImage from "../assets/img/dashboard/calendar-half.png"
import { Line } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Filler,
} from 'chart.js';
import { months, options, originalOptionsForDashboardGraph } from "../utils/analytics/line"
import { useDispatch, useSelector } from "react-redux"
import { audiencePosts as apiService } from "../api/audience";
import * as $ from "jquery";
import setDateFormat from "../utils/setDateFormat"
import analyticsDown from "../assets/img/analytics-down.svg"
import { useLocation } from "react-router-dom/cjs/react-router-dom"
import { DashboardHeader } from "../components/dashboard/projects/DashboardHeader"
import WelcomeModal from "../components/page-editor/modals/welcome-modal"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"
import Checklist from "./checklists"
import TeamModal from "../components/teammember/team_modal"
import { useSidebar } from "../contexts/SidebarContext";
import DashboardProjectsModal from "../components/dashboard/projects/modal";

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Filler,
);

const NewDashboard = ({onlyUpgrade}) => {
    let queryParams = []
    const { state } = useLocation();
    const history = useHistory();
    
    // Use the sidebar context instead of managing project state locally
    const { 
        projectsState: projects, 
        sharedProjectsState: sharedProjects, 
        isLoadingState: isLoading, 
        basePathState: basePath, 
        selectedProfileState: selectedProfile, 
        selectedSlugState: selectedSlug,
        selectedProjectState: selectedProject, 
        setSelectedProjectState: setSelectedProject
    } = useSidebar();

    if (state && state?.welcomeModalOpen) {
        return <WelcomeModal />;
    }
    
    const projectDisabledReducer = useSelector(state => state?.projectDisabledReducer);
    const [statsLoading, setStatsLoading] = useState(true);
    const [stats, setStats] = useState([]);
    const dispatch = useDispatch();
    const [hoveredCard, setHoveredCard] = useState("text-light");
    const [mobileScreen, setMobileScreen] = useState(false);
    const [lastDays, setLastDays] = useState("Last 7 Days");

    const handleCloseModal = () => setShowModal({ show: false, duplicate: false });
    const handleOpenModal = () => setShowModal({ show: true, duplicate: false });
    const [showModal, setShowModal] = useState({ show: false, duplicate: false });
    
    const copyingText = (text) => navigator.clipboard.writeText(text).then((r => toast.success("✅ Copied and ready to paste!", { icon: false,})));

    // Analytics chart data states
    const [dataUnique, setDataUnique] = useState({
        loaded: false,
        labels: [],
        datasets: [
            {
                label: 'Unique Visitors',
                data: [0, 0, 0, 0, 0, 0],
                borderColor: '#0C8DFB',
                backgroundColor: 'rgba(12, 140, 251, 0.25)',
                fill: true,
            },
            {
                label: 'Past Periods',
                data: [0, 0, 0, 0, 0, 0],
                borderColor: '#7F48FB',
                backgroundColor: 'rgba(127, 72, 251, 0.25)',
                tension: 0.1,
                fill: true,
            },
        ]
    });
    
    const [dataImpressions, setDataImpressions] = useState({
        loaded: false,
        labels: [],
        datasets: [
            {
                label: 'Impressions',
                data: [0, 0, 0, 0, 0, 0],
                borderColor: '#0C8DFB',
                backgroundColor: 'rgba(12, 140, 251, 0.25)',
                fill: true,
            },
            {
                label: 'Past Periods',
                data: [0, 0, 0, 0, 0, 0],
                borderColor: '#7F48FB',
                backgroundColor: 'rgba(127, 72, 251, 0.25)',
                tension: 0.1,
                fill: true,
            },
        ]
    });
    
    const [dataMeetings, setDataMeetings] = useState({
        loaded: false,
        labels: [],
        datasets: [
            {
                label: 'Meetings',
                data: [0, 0, 0, 0, 0, 0],
                borderColor: '#0C8DFB',
                backgroundColor: 'rgba(12, 140, 251, 0.25)',
                fill: true,
            },
            {
                label: 'Past Periods',
                data: [0, 0, 0, 0, 0, 0],
                borderColor: '#7F48FB',
                backgroundColor: 'rgba(127, 72, 251, 0.25)',
                tension: 0.1,
                fill: true,
            },
        ]
    });
    
    const [dataSales, setDataSales] = useState({
        loaded: false,
        labels: [],
        datasets: [
            {
                label: 'Sales',
                data: [0, 0, 0, 0, 0, 0],
                borderColor: '#0C8DFB',
                backgroundColor: 'rgba(12, 140, 251, 0.25)',
                fill: true,
            },
            {
                label: 'Past Periods',
                data: [0, 0, 0, 0, 0, 0],
                borderColor: '#7F48FB',
                backgroundColor: 'rgba(127, 72, 251, 0.25)',
                tension: 0.1,
                fill: true,
            },
        ]
    });
    
    const [dataRevenue, setDataRevenue] = useState({
        loaded: false,
        labels: [],
        datasets: [
            {
                label: 'Revenue',
                data: [0, 0, 0, 0, 0, 0],
                borderColor: '#0C8DFB',
                backgroundColor: 'rgba(12, 140, 251, 0.25)',
                fill: true,
            },
            {
                label: 'Past Periods',
                data: [0, 0, 0, 0, 0, 0],
                borderColor: '#7F48FB',
                backgroundColor: 'rgba(127, 72, 251, 0.25)',
                tension: 0.1,
                fill: true,
            },
        ]
    });
    
    const [dataSubscribers, setDataSubscribers] = useState({
        loaded: false,
        labels: [],
        datasets: [
            {
                label: 'Subscribers',
                data: [0, 0, 0, 0, 0, 0],
                borderColor: '#0C8DFB',
                backgroundColor: 'rgba(12, 140, 251, 0.25)',
                fill: true,
            },
            {
                label: 'Past Periods',
                data: [0, 0, 0, 0, 0, 0],
                borderColor: '#7F48FB',
                backgroundColor: 'rgba(127, 72, 251, 0.25)',
                tension: 0.1,
                fill: true,
            },
        ]
    });

    function checkScreenSize() {
        if (window.innerWidth <= 768) {
            setMobileScreen(true);
        } else {
            setMobileScreen(false);
        }
    }
    
    useEffect(() => {
        window.addEventListener('resize', checkScreenSize);
        window.addEventListener('load', checkScreenSize);
        
        return () => {
            window.removeEventListener('resize', checkScreenSize);
            window.removeEventListener('load', checkScreenSize);
        };
    }, []);

    const renderCalendar = (name = "last_7_days", dashboardLoad = true) => {
        var first_date = new Date();
        var last_date = new Date();
        
        if (name === "last_7_days") {
            setLastDays("Last 7 Days");
            last_date.setDate(first_date.getDate() - 7);
            first_date = setDateFormat(first_date, false, true);
            last_date = setDateFormat(last_date, false, true);
            getStats(null, null, localStorage?.slug, first_date, last_date, dashboardLoad, "7 days");
        } else if (name === "last_14_days") {
            setLastDays("Last 14 Days");
            last_date.setDate(first_date.getDate() - 14);
            first_date = setDateFormat(first_date, false, true);
            last_date = setDateFormat(last_date, false, true);
            getStats(null, null, localStorage?.slug, first_date, last_date, dashboardLoad, "14 days");
        } else if (name === "last_30_days") {
            setLastDays("Last 30 Days");
            last_date.setDate(first_date.getDate() - 30);
            first_date = setDateFormat(first_date, false, true);
            last_date = setDateFormat(last_date, false, true);
            getStats(null, null, localStorage?.slug, first_date, last_date, dashboardLoad, "30 days");
        }
    };

    // Load stats when dashboard loads and slug is available
    useEffect(() => {
        if (!isLoading && selectedSlug) {
            renderCalendar("last_7_days", true);
        }
    }, [isLoading, selectedSlug]);

    const getStats = (e, id, slug, from_date, to_date, dashboardLoad, days) => {
        if (!projectDisabledReducer) {
            showLoader();
            let temp_graph_data_unique = [];
            let temp_graph_data_impressions = [];
            let temp_graph_data_meetings = [];
            let temp_graph_data_sales = [];
            let temp_graph_data_revenue = [];
            let temp_graph_data_subscribers = [];
            
            queryParams = [];
            queryParams = "?from_date=" + from_date + "&to_date=" + to_date + '&dashboard_load=' + dashboardLoad + '&days=' + days;
            e && e.preventDefault();
            
            const api = apiUrl + "/project/get-dashboard/" + slug;
            var myHeaders = new Headers();
            myHeaders.append("Authorization", `Bearer ${localStorage?.getItem("token")}`);
            
            const setOptions = {
                method: "GET",
                headers: myHeaders,
            };
            
            apiService(null, api + queryParams, hideLoader, setOptions)?.then((response) => {
                if (response) {
                    setStatsLoading(false);
                    setStats(response);
                    
                    dispatch({ type: "SET_NEW_USER", payload: response?.new_user === 1 ? true : false });
                    dispatch({ type: "SET_PRO", payload: response?.pro === 1 ? true : false });
                    dispatch({ type: "SET_PLAN_TYPE", payload: response?.plan_type });

                    var ctx = document?.getElementById("analytics-main-chart")?.getContext("2d");
                    var gradient = ctx?.createLinearGradient(0, 0, 0, 400);
                    gradient?.addColorStop(0, 'rgba(12, 140, 251, 0.25)');
                    gradient?.addColorStop(1, 'rgba(12, 140, 251, 0.00)');
                    
                    var gradientPast = ctx?.createLinearGradient(0, 0, 0, 400);
                    gradientPast?.addColorStop(0, 'rgba(127, 72, 251, 0.25)');
                    gradientPast?.addColorStop(1, 'rgba(127, 72, 251, 0.00)');
                    
                    // Set data for unique visitors chart
                    temp_graph_data_unique.push(
                        {
                            label: 'Unique Visitors',
                            fill: true,
                            borderColor: '#0C8CFB',
                            backgroundColor: gradient,
                            tension: 0.1,
                            data: response?.graph_data?.unique_visitors
                        },
                        {
                            label: 'Past Periods',
                            data: response?.past_graph_data?.unique_visitors,
                            borderColor: '#7F48FB',
                            backgroundColor: gradientPast,
                            tension: 0.1,
                            fill: true,
                        }
                    );
                    setDataUnique({ labels: response?.graph_data?.labels, datasets: temp_graph_data_unique });
                    
                    // Set data for impressions chart
                    temp_graph_data_impressions.push(
                        {
                            label: 'Past Period',
                            fill: true,
                            borderColor: '#0C8CFB',
                            backgroundColor: gradient,
                            tension: 0.1,
                            data: response?.graph_data?.impressions
                        },
                        {
                            label: 'Past Periods',
                            data: response?.past_graph_data?.impressions,
                            borderColor: '#7F48FB',
                            backgroundColor: gradientPast,
                            tension: 0.1,
                            fill: true,
                        }
                    );
                    setDataImpressions({ labels: response?.graph_data?.labels, datasets: temp_graph_data_impressions });
                    
                    // Set data for meetings chart
                    temp_graph_data_meetings.push(
                        {
                            label: 'Past Period',
                            fill: true,
                            borderColor: '#0C8CFB',
                            backgroundColor: gradient,
                            tension: 0.1,
                            data: response?.graph_data?.meetings
                        },
                        {
                            label: 'Past Periods',
                            data: response?.past_graph_data?.meetings,
                            borderColor: '#7F48FB',
                            backgroundColor: gradientPast,
                            tension: 0.1,
                            fill: true,
                        }
                    );
                    setDataMeetings({ labels: response?.graph_data?.labels, datasets: temp_graph_data_meetings });
                    
                    // Set data for sales chart
                    temp_graph_data_sales.push(
                        {
                            label: 'Past Period',
                            fill: true,
                            borderColor: '#0C8CFB',
                            backgroundColor: gradient,
                            tension: 0.1,
                            data: response?.graph_data?.sales
                        },
                        {
                            label: 'Past Periods',
                            data: response?.past_graph_data?.sales,
                            borderColor: '#7F48FB',
                            backgroundColor: gradientPast,
                            tension: 0.1,
                            fill: true,
                        }
                    );
                    setDataSales({ labels: response?.graph_data?.labels, datasets: temp_graph_data_sales });
                    
                    // Set data for revenue chart
                    temp_graph_data_revenue.push(
                        {
                            label: 'Past Period',
                            fill: true,
                            borderColor: '#0C8CFB',
                            backgroundColor: gradient,
                            tension: 0.1,
                            data: response?.graph_data?.revenue
                        },
                        {
                            label: 'Past Periods',
                            data: response?.past_graph_data?.revenue,
                            borderColor: '#7F48FB',
                            backgroundColor: gradientPast,
                            tension: 0.1,
                            fill: true,
                        }
                    );
                    setDataRevenue({ labels: response?.graph_data?.labels, datasets: temp_graph_data_revenue });
                    
                    // Set data for subscribers chart
                    temp_graph_data_subscribers.push(
                        {
                            label: 'Past Period',
                            fill: true,
                            borderColor: '#0C8CFB',
                            backgroundColor: gradient,
                            tension: 0.1,
                            data: response?.graph_data?.subscribers
                        },
                        {
                            label: 'Past Periods',
                            data: response?.past_graph_data?.subscribers,
                            borderColor: '#7F48FB',
                            backgroundColor: gradientPast,
                            tension: 0.1,
                            fill: true,
                        }
                    );
                    setDataSubscribers({ labels: response?.graph_data?.labels, datasets: temp_graph_data_subscribers });
                    
                    hideLoader();
                }
            });
        }
    };

    const handleCardHover = (name) => {
        setHoveredCard(name);
    };

    // jQuery effects for card hover animations
    useEffect(() => {
        $(document).ready(function() {
            $(".card").mouseenter(function() {
                $(".views-card-hover").animate({opacity: 1}, 1000);
            });

            $(".card").mouseleave(function() {
                $(".views-card-hover").animate({opacity: 0}, 1000);
            });
        });
    }, []);

    const redirectToSettings = (pageName) => {
        history.push({
            pathname: `/account-settings`,
            state: { defaultActivePage: pageName }
        });
    };

    return (
        <div id="wrapper">
            <PageHeader from="dashboard" isPro={stats?.pro} plan={stats?.plan_type} projects={projects} selectedProfile={selectedProfile} />
            <div className="dashboard-main new-dashboard">
                <Sidebar 
                    onlyUpgrade={onlyUpgrade}
                    welcomeModalOpen={state?.welcomeModalOpen}
                    newAccountCreated={state?.sidebarOpen}
                    renderCalendar={renderCalendar}
                    from={'dashboard'}
                />
                {!isLoading ? (
                    <>
                        <section className="dashboard-section position-relative">
                            <DashboardHeader 
                                projects={selectedProject ? projects : sharedProjects} 
                                selectedProfile={selectedProfile} 
                                stats={stats} 
                                selectedProject={selectedProject}
                            />
                            <div className="container dashboard-container">
                                <div className="affiliate-div">
                                    <div>
                                        <h2 className="m-0">Share Zaap. Get Paid.</h2>
                                        <p className="m-0">Refer creators and earn 25% profit share every month.</p>
                                    </div>
                                    <div>
                                        <a href="https://www.zaap.ai/learn/how-to-become-a-zaap-affiliate" target="_blank" className="btn-grey settings-button-primary me-2">
                                            FAQ's 
                                            <span>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8" fill="none">
                                                    <path d="M1 7L7 1M7 1H3M7 1V5" stroke="#333333" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                                </svg>
                                            </span>
                                        </a>
                                        <button onClick={() => redirectToSettings('affiliate-program')} className="blue-shadow-button">
                                            Get Unique Link
                                        </button>
                                    </div>
                                </div>
                                <Checklist />
                                <div className="border-grey mt-20px"></div>
                                <div className="dashboard-analytics d-flex mt-20px">
                                    <div className="date-btns-selector">
                                        <div className={`date-filter-button me-2 ${lastDays === "Last 7 Days" ? 'active' : ''}`} 
                                             onClick={() => renderCalendar("last_7_days", false)} 
                                             name="last_7_days">Last 7 Days</div>
                                        <div className={`date-filter-button me-2 ${lastDays === "Last 14 Days" ? 'active' : ''}`} 
                                             onClick={() => renderCalendar("last_14_days", false)} 
                                             name="last_14_days">Last 14 Days</div>
                                        <div className={`date-filter-button ${lastDays === "Last 30 Days" ? 'active' : ''}`} 
                                             onClick={() => renderCalendar("last_30_days", false)} 
                                             name="last_30_days">Last 30 Days</div>
                                    </div>
                                    <div className="row">
                                        {/* Unique Visitors Chart */}
                                        <div className="col-sm-12 col-md-6 col-lg-6 col-xxl-4 d-flex justify-content-center pb-4">
                                            <div className="analytics-card">
                                                <div className="card-name">
                                                    Unique Visitors
                                                </div>
                                                <div className="analytics-details d-flex py-2">
                                                    <div className="analytics-detail analytics-card-blue">
                                                        <div className="d-flex align-items-center justify-content-start">
                                                            <h1 className="me-2">{stats?.analytics?.ProjectStatsUniqueAnalytics}</h1>
                                                            {statsLoading ? <Skeleton width={50} className="ms-2 zaap-badge badge-free" /> :
                                                                <span className={`analytics-badge ${stats?.analytics?.UniqueAnalyticsPercent[1] === 'up' ? 'analytics-badge-success' : 'analytics-badge-danger'} d-flex align-items-center justify-content-center`}>
                                                                    {stats?.analytics?.UniqueAnalyticsPercent[0] !== '-' && 
                                                                    <img src={stats?.analytics?.UniqueAnalyticsPercent[1] === 'up' ? analyticsUp : analyticsDown} width="7px" alt="" />}
                                                                    <span className={`${stats?.analytics?.UniqueAnalyticsPercent[0] !== '-' ? 'ms-1' : ''}`}>
                                                                        {stats?.analytics?.UniqueAnalyticsPercent[0] !== '-' ? stats?.analytics?.UniqueAnalyticsPercent[0] + '%' : '-'}
                                                                    </span>
                                                                </span>
                                                            }
                                                        </div>
                                                        <h5 className="mb-0 font-weight-500">{lastDays}</h5>
                                                    </div>
                                                    <div className="analytics-detail analytics-card-purple">
                                                        <div className="d-flex align-items-center justify-content-start">
                                                            <h1 className="me-2">{stats?.analyticsPastPeriods?.ProjectStatsUniqueAnalyticsPastPeriod}</h1>
                                                        </div>
                                                        <h5 className="mb-0 font-weight-500">Previous Period</h5>
                                                    </div>
                                                </div>
                                                <div className="card-analytics-container mt-4">
                                                    <Line id="analytics-main-chart" 
                                                        data={dataUnique} 
                                                        options={originalOptionsForDashboardGraph}
                                                        width={"293px"} 
                                                        height={'199px'} />
                                                </div>
                                            </div>
                                        </div>

                                        {/* Impressions Chart */}
                                        <div className="col-sm-12 col-md-6 col-lg-6 col-xxl-4 d-flex justify-content-center pb-4">
                                            <div className="analytics-card">
                                                <div className="card-name">
                                                    Impressions
                                                </div>
                                                <div className="analytics-details d-flex py-2">
                                                    <div className="analytics-detail analytics-card-blue">
                                                        <div className="d-flex align-items-center justify-content-start">
                                                            <h1 className="me-2">{stats?.analytics?.ProjectStatsImpressionsAnalytics}</h1>
                                                            {statsLoading ? <Skeleton width={50} className="ms-2 zaap-badge badge-free" /> :
                                                                <span className={`analytics-badge ${stats?.analytics?.ImpressionsAnalyticsPercent[1] === 'up' ? 'analytics-badge-success' : 'analytics-badge-danger'} d-flex align-items-center justify-content-center`}>
                                                                    {stats?.analytics?.ImpressionsAnalyticsPercent[0] !== '-' && 
                                                                    <img src={stats?.analytics?.ImpressionsAnalyticsPercent[1] === 'up' ? analyticsUp : analyticsDown} width="7px" alt="" />}
                                                                    <span className={`${stats?.analytics?.ImpressionsAnalyticsPercent[0] !== '-' ? 'ms-1' : ''}`}>
                                                                        {stats?.analytics?.ImpressionsAnalyticsPercent[0] !== '-' ? stats?.analytics?.ImpressionsAnalyticsPercent[0] + '%' : '-'}
                                                                    </span>
                                                                </span>
                                                            }
                                                        </div>
                                                        <h5 className="mb-0 font-weight-500">{lastDays}</h5>
                                                    </div>
                                                    <div className="analytics-detail analytics-card-purple">
                                                        <div className="d-flex align-items-center justify-content-start">
                                                            <h1 className="me-2">{stats?.analyticsPastPeriods?.ProjectStatsImpressionsAnalyticsPastPeriod}</h1>
                                                        </div>
                                                        <h5 className="mb-0 font-weight-500">Previous Period</h5>
                                                    </div>
                                                </div>
                                                <div className="card-analytics-container mt-4">
                                                    <Line id="analytics-main-chart"
                                                        data={dataImpressions} 
                                                        options={originalOptionsForDashboardGraph}
                                                        width={"293px"} 
                                                        height={'199px'} />
                                                </div>
                                            </div>
                                        </div>

                                        {/* Meetings Chart */}
                                        <div className="col-sm-12 col-md-6 col-lg-6 col-xxl-4 d-flex justify-content-center pb-4">
                                            <div className="analytics-card">
                                                <div className="card-name">
                                                    Meetings
                                                </div>
                                                <div className="analytics-details d-flex py-2">
                                                    <div className="analytics-detail analytics-card-blue">
                                                        <div className="d-flex align-items-center justify-content-start">
                                                            <h1 className="me-2">{stats?.analytics?.ProjectStatsMeetingsAnalytics}</h1>
                                                            {statsLoading ? <Skeleton width={50} className="ms-2 zaap-badge badge-free" /> :
                                                                <span className={`analytics-badge ${stats?.analytics?.MeetingsAnalyticsPercent[1] === 'up' ? 'analytics-badge-success' : 'analytics-badge-danger'} d-flex align-items-center justify-content-center`}>
                                                                    {stats?.analytics?.MeetingsAnalyticsPercent[0] !== '-' && 
                                                                    <img src={stats?.analytics?.MeetingsAnalyticsPercent[1] === 'up' ? analyticsUp : analyticsDown} width="7px" alt="" />}
                                                                    <span className={`${stats?.analytics?.MeetingsAnalyticsPercent[0] !== '-' ? 'ms-1' : ''}`}>
                                                                        {stats?.analytics?.MeetingsAnalyticsPercent[0] !== '-' ? stats?.analytics?.MeetingsAnalyticsPercent[0] + '%' : '-'}
                                                                    </span>
                                                                </span>
                                                            }
                                                        </div>
                                                        <h5 className="mb-0 font-weight-500">{lastDays}</h5>
                                                    </div>
                                                    <div className="analytics-detail analytics-card-purple">
                                                        <div className="d-flex align-items-center justify-content-start">
                                                            <h1 className="me-2">{stats?.analyticsPastPeriods?.ProjectStatsMeetingsAnalyticsPastPeriod}</h1>
                                                        </div>
                                                        <h5 className="mb-0 font-weight-500">Previous Period</h5>
                                                    </div>
                                                </div>
                                                <div className="card-analytics-container mt-4">
                                                    <Line id="analytics-main-chart"
                                                        data={dataMeetings} 
                                                        options={originalOptionsForDashboardGraph}
                                                        width={"293px"} 
                                                        height={'199px'} />
                                                </div>
                                            </div>
                                        </div>

                                        {/* Sales Chart */}
                                        <div className="col-sm-12 col-md-6 col-lg-6 col-xxl-4 d-flex justify-content-center pb-4">
                                            <div className="analytics-card">
                                                <div className="card-name">
                                                    Sales
                                                </div>
                                                <div className="analytics-details d-flex py-2">
                                                    <div className="analytics-detail analytics-card-blue">
                                                        <div className="d-flex align-items-center justify-content-start">
                                                            <h1 className="me-2">{stats?.analytics?.ProjectStatsSalesAnalytics}</h1>
                                                            {statsLoading ? <Skeleton width={50} className="ms-2 zaap-badge badge-free" /> :
                                                                <span className={`analytics-badge ${stats?.analytics?.SubscriberAnalyticsPercent[1] === 'up' ? 'analytics-badge-success' : 'analytics-badge-danger'} d-flex align-items-center justify-content-center`}>
                                                                    {stats?.analytics?.SubscriberAnalyticsPercent[1] !== '-' && 
                                                                    <img src={stats?.analytics?.SubscriberAnalyticsPercent[1] === 'up' ? analyticsUp : analyticsDown} width="7px" alt="" />}
                                                                    <span className={`${stats?.analytics?.SubscriberAnalyticsPercent[0] !== '-' ? 'ms-1' : ''}`}>
                                                                        {stats?.analytics?.SubscriberAnalyticsPercent[0] !== '-' ? stats?.analytics?.SubscriberAnalyticsPercent[0] + '%' : '-'}
                                                                    </span>
                                                                </span>
                                                            }
                                                        </div>
                                                        <h5 className="mb-0 font-weight-500">{lastDays}</h5>
                                                    </div>
                                                    <div className="analytics-detail analytics-card-purple">
                                                        <div className="d-flex align-items-center justify-content-start">
                                                            <h1 className="me-2">{stats?.analyticsPastPeriods?.ProjectStatsSalesAnalyticsPastPeriod}</h1>
                                                        </div>
                                                        <h5 className="mb-0 font-weight-500">Previous Period</h5>
                                                    </div>
                                                </div>
                                                <div className="card-analytics-container mt-4">
                                                    <Line id="analytics-main-chart"
                                                        data={dataSales} 
                                                        options={originalOptionsForDashboardGraph}
                                                        width={"293px"} 
                                                        height={'199px'} />
                                                </div>
                                            </div>
                                        </div>

                                        {/* Revenue Chart */}
                                        <div className="col-sm-12 col-md-6 col-lg-6 col-xxl-4 d-flex justify-content-center pb-4">
                                            <div className="analytics-card">
                                                <div className="card-name">
                                                    Revenue
                                                </div>
                                                <div className="analytics-details d-flex py-2">
                                                    <div className="analytics-detail analytics-card-blue">
                                                        <div className="d-flex align-items-center justify-content-start">
                                                            <h1 className="me-2">{stats?.analytics?.ProjectStatsRevenueAnalytics}</h1>
                                                            {statsLoading ? <Skeleton width={50} className="ms-2 zaap-badge badge-free" /> :
                                                                <span className={`analytics-badge ${stats?.analytics?.RevenueAnalyticsPercent[1] === 'up' ? 'analytics-badge-success' : 'analytics-badge-danger'} d-flex align-items-center justify-content-center`}>
                                                                    {stats?.analytics?.RevenueAnalyticsPercent[1] !== '-' && 
                                                                    <img src={stats?.analytics?.RevenueAnalyticsPercent[1] === 'up' ? analyticsUp : analyticsDown} width="7px" alt="" />}
                                                                    <span className={`${stats?.analytics?.RevenueAnalyticsPercent[0] !== '-' ? 'ms-1' : ''}`}>
                                                                        {stats?.analytics?.RevenueAnalyticsPercent[0] !== '-' ? stats?.analytics?.RevenueAnalyticsPercent[0] + '%' : '-'}
                                                                    </span>
                                                                </span>
                                                            }
                                                        </div>
                                                        <h5 className="mb-0 font-weight-500">{lastDays}</h5>
                                                    </div>
                                                    <div className="analytics-detail analytics-card-purple">
                                                        <div className="d-flex align-items-center justify-content-start">
                                                            <h1 className="me-2">{stats?.analyticsPastPeriods?.ProjectStatsRevenueAnalyticsPastPeriod}</h1>
                                                        </div>
                                                        <h5 className="mb-0 font-weight-500">Previous Period</h5>
                                                    </div>
                                                </div>
                                                <div className="card-analytics-container mt-4">
                                                    <Line id="analytics-main-chart"
                                                        data={dataRevenue} 
                                                        options={originalOptionsForDashboardGraph}
                                                        width={"293px"} 
                                                        height={'199px'} />
                                                </div>
                                            </div>
                                        </div>

                                        {/* Subscribers Chart */}
                                        <div className="col-sm-12 col-md-6 col-lg-6 col-xxl-4 d-flex justify-content-center pb-4">
                                            <div className="analytics-card">
                                                <div className="card-name">
                                                    Subscribers
                                                </div>
                                                <div className="analytics-details d-flex py-2">
                                                    <div className="analytics-detail analytics-card-blue">
                                                        <div className="d-flex align-items-center justify-content-start">
                                                            <h1 className="me-2">{stats?.analytics?.ProjectStatsSubscriberAnalytics}</h1>
                                                            {statsLoading ? <Skeleton width={50} className="ms-2 zaap-badge badge-free" /> :
                                                                <span className={`analytics-badge ${stats?.analytics?.SubscriberAnalyticsPercent[1] === 'up' ? 'analytics-badge-success' : 'analytics-badge-danger'} d-flex align-items-center justify-content-center`}>
                                                                    {stats?.analytics?.SubscriberAnalyticsPercent[1] !== '-' && 
                                                                    <img src={stats?.analytics?.SubscriberAnalyticsPercent[1] === 'up' ? analyticsUp : analyticsDown} width="7px" alt="" />}
                                                                    <span className={`${stats?.analytics?.SubscriberAnalyticsPercent[0] !== '-' ? 'ms-1' : ''}`}>
                                                                        {stats?.analytics?.SubscriberAnalyticsPercent[0] !== '-' ? stats?.analytics?.SubscriberAnalyticsPercent[0] + '%' : '-'}
                                                                    </span>
                                                                </span>
                                                            }
                                                        </div>
                                                        <h5 className="mb-0 font-weight-500">{lastDays}</h5>
                                                    </div>
                                                    <div className="analytics-detail analytics-card-purple">
                                                        <div className="d-flex align-items-center justify-content-start">
                                                            <h1 className="me-2">{stats?.analyticsPastPeriods?.ProjectStatsSubscriberAnalyticsPastPeriod}</h1>
                                                        </div>
                                                        <h5 className="mb-0 font-weight-500">Previous Period</h5>
                                                    </div>
                                                </div>
                                                <div className="card-analytics-container mt-4">
                                                    <Line id="analytics-main-chart"
                                                        data={dataSubscribers} 
                                                        options={originalOptionsForDashboardGraph}
                                                        width={"293px"} 
                                                        height={'199px'} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </>
                ) : (
                    // Loading skeleton view
                    <section className="dashboard-section position-relative">
                        <div className="project-details-main" style={{background: `linear-gradient(0deg, #FFF 0%, #fff 100%)`}}>
                            <div className="container dashboard-container" style={{marginTop: "45px"}}>
                                <div className="d-flex align-items-center" style={{ width: "-webkit-fill-available" }}>
                                    <div className="project-user-pp">
                                        <Skeleton
                                            circle
                                            height={70}
                                            width={70}
                                            containerClassName="avatar-skeleton"
                                        />
                                    </div>
                                    <div className="project-details d-flex align-items-start justify-content-center flex-column ps-2">
                                        <div className="project-title d-flex align-items-center">
                                            <Skeleton width={100} className="ms-2 zaap-badge badge-free" />
                                            <Skeleton width={50} className="ms-2 zaap-badge badge-free" />
                                        </div>
                                        <div className="project-link d-flex align-items-center">
                                            <Skeleton width={150} className="text-muted-light ms-2" />
                                            <Skeleton count={1} className="ms-3 mt-1 copy-clipboard" />
                                        </div>
                                    </div>
                                </div>
                                <div className="cards-container">
                                    {/* Views Card Skeleton */}
                                    <div className="views-card card" onMouseEnter={() => handleCardHover("EFEFFF")}>
                                        <div className="" style={{padding: "15px"}}>
                                            <div className="card-heading dashboard-card-heading">
                                                Views
                                            </div>
                                            <div className="card-text dashboard-card-text">
                                                Last 7 days
                                            </div>
                                            <div className="dashboard-card-numbers">
                                                <Skeleton width={50} height={40}
                                                    className="mb-2"
                                                    baseColor="#7F48FB"
                                                    highlightColor="#6e36ed"
                                                    borderRadius="0.5rem"
                                                    duration={4}
                                                />
                                            </div>
                                        </div>
                                        <div className="views-card-pic-div">
                                            <img className="views-card-image" src={chainImage} alt="" />
                                        </div>
                                    </div>
                                    
                                    {/* Clicks Card Skeleton */}
                                    <div className="clicks-card card" onMouseEnter={() => handleCardHover("E5FFFF")}>
                                        <div className="" style={{padding: "15px"}}>
                                            <div className="card-heading dashboard-card-heading">
                                                Click
                                            </div>
                                            <div className="card-text dashboard-card-text">
                                                Last 7 days
                                            </div>
                                            <div className="dashboard-card-numbers">
                                                <Skeleton width={50} height={40}
                                                    className="mb-2"
                                                    baseColor="#48FBFB"
                                                    highlightColor="#74c3c3"
                                                    borderRadius="0.5rem"
                                                    duration={4}
                                                />
                                            </div>
                                        </div>
                                        <div className="clicks-card-pic-div">
                                            <img className="clicks-card-image" src={funnelImage} alt="" />
                                        </div>
                                    </div>
                                    
                                    {/* Sales Card Skeleton */}
                                    <div className="sales-card card" onMouseEnter={() => handleCardHover("FFE9E1")}>
                                        <div className="" style={{padding: "15px"}}>
                                            <div className="card-heading dashboard-card-heading">
                                                Sales
                                            </div>
                                            <div className="card-text dashboard-card-text">
                                                Last 7 days
                                            </div>
                                            <div className="dashboard-card-numbers">
                                                <Skeleton width={50} height={40}
                                                    className="mb-2"
                                                    baseColor="#FB7348"
                                                    highlightColor="#e35629"
                                                    borderRadius="0.5rem"
                                                    duration={4}
                                                />
                                            </div>
                                        </div>
                                        <div className="sales-card-pic-div">
                                            <img className="sales-card-image" src={targetImage} alt="" />
                                        </div>
                                    </div>
                                    
                                    {/* Revenue Card Skeleton */}
                                    <div className="revenue-card card" onMouseEnter={() => handleCardHover("FFF6E3")}>
                                        <div className="" style={{padding: "15px"}}>
                                            <div className="card-heading dashboard-card-heading">
                                                Revenue
                                            </div>
                                            <div className="card-text dashboard-card-text">
                                                Last 7 days
                                            </div>
                                            <div className="dashboard-card-numbers">
                                                <Skeleton width={50} height={40}
                                                    className="mb-2"
                                                    baseColor="#FFBE23"
                                                    highlightColor="#ed991d"
                                                    borderRadius="0.5rem"
                                                    duration={4}
                                                />
                                            </div>
                                        </div>
                                        <div className="revenue-card-pic-div">
                                            <img className="revenue-card-image" src={goldPlateImage} alt="" />
                                        </div>
                                    </div>
                                    
                                    {/* Subscribers Card Skeleton */}
                                    <div className="subscribers-card card" onMouseEnter={() => handleCardHover("FFE3F2")}>
                                        <div className="" style={{padding: "15px"}}>
                                            <div className="card-heading dashboard-card-heading">
                                                Subscribers
                                            </div>
                                            <div className="card-text dashboard-card-text">
                                                Last 7 days
                                            </div>
                                            <div className="dashboard-card-numbers">
                                                <Skeleton width={50} height={40}
                                                    className="mb-2"
                                                    baseColor="#FB487E"
                                                    highlightColor="#ed2662"
                                                    borderRadius="0.5rem"
                                                    duration={4}
                                                />
                                            </div>
                                        </div>
                                        <div className="subscribers-card-pic-div">
                                            <img className="subscribers-card-image" src={heartImage} alt="" />
                                        </div>
                                    </div>
                                    
                                    {/* Meetings Card Skeleton */}
                                    <div className="meetings-card card" onMouseEnter={() => handleCardHover("FFE3E5")}>
                                        <div className="" style={{padding: "15px"}}>
                                            <div className="card-heading dashboard-card-heading">
                                                Meetings
                                            </div>
                                            <div className="card-text dashboard-card-text">
                                                Last 7 days
                                            </div>
                                            <div className="dashboard-card-numbers">
                                                <Skeleton width={50} height={40}
                                                    className="mb-2"
                                                    baseColor="#FB4848"
                                                    highlightColor="#dd4b4b"
                                                    borderRadius="0.5rem"
                                                    duration={4}
                                                />
                                            </div>
                                        </div>
                                        <div className="meetings-card-pic-div">
                                            <img className="meetings-card-image" src={calendarHalfImage} alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        {/* Affiliate section and analytics placeholders */}
                        <div className="container dashboard-container">
                            <div className="affiliate-div">
                                <div>
                                    <h2 className="m-0">Share Zaap. Get Paid.</h2>
                                    <p className="m-0">Refer creators and earn 25% profit share every month.</p>
                                </div>
                                <div>
                                    <a href="https://www.zaap.ai/learn/how-to-become-a-zaap-affiliate" target="_blank" className="btn-grey settings-button-primary me-2">
                                        FAQ's 
                                        <span>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8" fill="none">
                                                <path d="M1 7L7 1M7 1H3M7 1V5" stroke="#333333" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                            </svg>
                                        </span>
                                    </a>
                                    <button onClick={() => redirectToSettings('affiliate-program')} className="blue-shadow-button">
                                        Get Unique Link
                                    </button>
                                </div>
                            </div>
                            
                            {/* Analytics placeholder skeletons */}
                            <div className="dashboard-analytics d-flex">
                                <div className="date-btns-selector">
                                    <div className={`date-filter-button me-2 active`} name="last_7_days">Last 7 Days</div>
                                    <div className={`date-filter-button me-2`} name="last_14_days">Last 14 Days</div>
                                    <div className={`date-filter-button`} name="last_30_days">Last 30 Days</div>
                                </div>
                                
                                {/* Skeleton charts */}
                                <div className="row">
                                    {[...Array(6)].map((_, index) => (
                                        <div key={index} className="col-sm-12 col-md-6 col-lg-6 col-xxl-4 d-flex justify-content-center pb-4">
                                            <div className="analytics-card">
                                                <div className="card-name">
                                                    <Skeleton width={100} />
                                                </div>
                                                <div className="analytics-details d-flex py-2">
                                                    <div className="analytics-detail analytics-card-blue">
                                                        <div className="d-flex align-items-center justify-content-start">
                                                            <h1 className="me-2"><Skeleton width={50} className="ms-2 zaap-badge badge-free" /></h1>
                                                            <span className="analytics-badge analytics-badge-success d-flex align-items-center justify-content-center">
                                                                <img src={analyticsUp} width="7px" alt="" />
                                                                <span className="ms-1"></span>
                                                            </span>
                                                        </div>
                                                        <h5 className="mb-0 font-weight-500">Last 7 days</h5>
                                                    </div>
                                                    <div className="analytics-detail analytics-card-purple">
                                                        <div className="d-flex align-items-center justify-content-start">
                                                            <h1 className="me-2"><Skeleton width={50} className="ms-2 zaap-badge badge-free" /></h1>
                                                        </div>
                                                        <h5 className="mb-0 font-weight-500">Previous Period</h5>
                                                    </div>
                                                </div>
                                                <div className="card-analytics-container">
                                                    <Skeleton height={200} />
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </section>
                )}
                
                {projectDisabledReducer && 
                    <TeamModal 
                        showModal={projectDisabledReducer} 
                        heading={'Upgrade to use Zaap'} 
                        text={'Choose a plan to continue using Zaap'} 
                        isUltra={false} 
                    />
                }
            </div>
            
            {showModal && 
                <DashboardProjectsModal 
                    showModal={showModal} 
                    handleCloseModal={handleCloseModal} 
                />
            }
        </div>
    );
};

export default NewDashboard;