import PageHeader from "../components/layout/page-header"
import 'bootstrap/dist/js/bootstrap.bundle.min';
import "../styles/analytics.css"
import "../assets/calendar-plugin/css/mobiscroll.jquery.min.css"
import { useEffect, useState } from "react"
import { months } from "../utils/analytics/line";
import "../assets/calendar-plugin/js/mobiscroll.jquery.min.js"
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Tooltip,
    Filler,
} from 'chart.js';
import * as $ from "jquery";
import { hideLoader, showLoader } from "../components/loader"
import { apiUrl } from "../utils/url"
import { audiencePosts as statGets } from "../api/audience"
import { useDispatch, useSelector } from "react-redux";
import TeamModal from "../components/teammember/team_modal";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Sidebar from "../components/layout/Sidebar";
import AnalyticsSession from "../components/analytics/analytics-session.jsx";
import AnalyticsMain from "../components/analytics/analytics-main.jsx";
import AnalyticsPosts from "../components/analytics/analytics-posts.jsx";
import AnalyticsHeader from "../components/analytics/analytics-header.jsx";
import AnalyticsLinks from "../components/analytics/analytics-links.jsx";

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Tooltip,
    Filler,
);

let apiBasePath = "/project/statistics"


let graph_dates = new Date();
var labels = []

for (let index = 1; index < 10; index++) {
    graph_dates.setDate(graph_dates.getDate() - 1);
    labels.push(months[graph_dates.getMonth()] + ' ' + graph_dates.getDate());
}

const flagsPath = ""
let params = [];
let queryParams = []
export const calculateDateRange = (filterValue) => {
    const now = new Date();
    let from_date, to_date;

    switch (filterValue) {
        case '24h':
            from_date = new Date(now.getTime() - 24 * 60 * 60 * 1000);
            break;
        case '48h':
            from_date = new Date(now.getTime() - 48 * 60 * 60 * 1000);
            break;
        case '7d':
            from_date = new Date(now.getTime() - 7 * 24 * 60 * 60 * 1000);
            break;
        case '30d':
            from_date = new Date(now.getTime() - 30 * 24 * 60 * 60 * 1000);
            break;
        case 'all':
            from_date = new Date(0); // Starting date to cover all time
            break;
        default:
            return null;
    }
    to_date = now;
    return { from_date, to_date };
};
export const getOsIcon = (osName) => {
    const os = osName?.toLowerCase();
    switch (os) {
        case 'ios':
        case 'macos':
            return 'apple';
        default:
            return os || 'question-circle'; // Fallback icon if OS name is unknown
    }
};
const Analytics = () => {
    const analyticsPageName = window.location.pathname.split("/").pop();
    const dateFilters = [
        { label: 'Last 24 hrs', value: '24h' },
        { label: 'Last 48 hrs', value: '48h' },
        { label: 'Last 7 days', value: '7d' },
        { label: 'Last 30 days', value: '30d' },
    ];
    const [loading, setloading] = useState(true);
    const history = useHistory()
    const [screenWidth, setscreenWidth] = useState(false)
    const { basePath, slug } = useSelector(state => state?.slugReducer);
    const [topSources, settopSources] = useState(null)
    const [topCountries, settopCountries] = useState(null)
    const [devices, setdevices] = useState({})
    const [browsers, setbrowsers] = useState({})
    const [operatingSystems, setoperatingSystems] = useState({})
    const [activity, setactivity] = useState({})
    const [showModalasd, setShowModalasd] = useState(false)
    const pro = useSelector(state => state?.proReducer);
    const newUser = useSelector(state => state?.newUserReducer);
    const dispatch = useDispatch();
    const [tab, setTab] = useState('overview');
    const [statsloading, setStatsloading] = useState(true);
    const [detailedAnalytics, setDetailedAnalytics] = useState(false);
    const [pageData, setPageData] = useState({});
    const [defaultpageData, setDefaultPageData] = useState({});
    const [date, setDate] = useState({});

    const toSingular = (word) => {
        if (word.endsWith("ies")) {
            return word.slice(0, -3) + "y";
        } else if (word.endsWith("es")) {
            return word.slice(0, -2);
        } else if (word.endsWith("s") && word.length > 1) {
            return word.slice(0, -1);
        }
        return word;
    };

    useEffect(() => {
        setPageData({pageHeading:`${toSingular(analyticsPageName)} Analytics`,pageDesc:`Gain detailed insights about your Zaap ${analyticsPageName}.`})
        setDefaultPageData({pageHeading:`${toSingular(analyticsPageName)} Analytics`,pageDesc:`Gain detailed insights about your Zaap ${analyticsPageName}.`})
    }, [analyticsPageName])


    const [filter, setfilter] = useState({
        page: 1,
    })

    const [statsData, setstatsData] = useState({
        unique_visitors: {},
        impressions: {},
        link_clicks: {},
        revenue: {},
        click_through_rate: {},
    })

    const [graphData, setgraphdata] = useState({
        unique_visitors: {
            label: 'Unique Visitors',
            data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            fill: true,
            borderColor: '#70EEFF',
            backgroundColor: 'rgba(112, 238, 255, 0.1)',
            tension: 0.1
        },
        impressions: {
            label: 'Impressions Visitors',
            data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            fill: true,
            borderColor: '#18f',
            backgroundColor: 'rgba(17, 136, 255, 0.1)',
            tension: 0.1
        },
        time_on_page: {
            label: 'Visit Duration',
            data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            fill: true,
            borderColor: '#7F48FB',
            backgroundColor: "rgba(127, 72, 251, 0.1)",
            tension: 0.1
        },
        link_clicks: {
            label: 'Link Clicks',
            data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            fill: true,
            borderColor: '#FFC700',
            backgroundColor: "rgba(255, 199, 0, 0.1)",
            tension: 0.1
        },
        click_through_rate: {
            label: 'Click-Through-Rate',
            data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            fill: true,
            borderColor: '#F00',
            backgroundColor: 'rgba(255, 0, 0, 0.1)',
            tension: 0.1
        },
    })
    useEffect(() => {
        setShowModalasd(!pro);
    }, [pro]);

    const [data, setData] = useState({
        loaded: false,
        labels: labels,
        datasets: [
            {
                label: 'Unique Visitors',
                data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                fill: true,
                borderColor: '#70EEFF',
                backgroundColor: 'rgba(112, 238, 255, 0.1)',
                tension: 0.1
            },
            {
                label: 'Impressions Visitors',
                data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                fill: true,
                borderColor: '#18f',
                backgroundColor: 'rgba(17, 136, 255, 0.1)',
                tension: 0.1
            },
            {
                label: 'Visit Duration',
                data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                fill: true,
                borderColor: '#7F48FB',
                backgroundColor: "rgba(127, 72, 251, 0.1)",
                tension: 0.1
            },
            {
                label: 'Link Clicks',
                data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                fill: true,
                borderColor: '#FFC700',
                backgroundColor: "rgba(255, 199, 0, 0.1)",
                tension: 0.1
            },
            {
                label: 'Click-Through-Rate',
                data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                fill: true,
                borderColor: '#F00',
                backgroundColor: 'rgba(255, 0, 0, 0.1)',
                tension: 0.1
            },
        ]
    })

    const callActivity = (_filter) => {
        showLoader()
        params = [];
        for (const key in _filter) {
            if (Object.hasOwnProperty.call(_filter, key)) {
                if (_filter[key])
                    params.push(`${[key]}=${_filter[key]}`)
            }
        };
        if (pro) {
            if (_filter?.page) {
                const formdata = new FormData();
                var myHeaders = new Headers();
                myHeaders.append("Authorization", `Bearer ${localStorage?.getItem("token")}`);
                const setOptions = {
                    method: "GET",
                    headers: myHeaders,
                }
                statGets(null, apiUrl + apiBasePath + "/getActivityLog/" + localStorage.slug + queryParams + "&" + params?.join("&"), hideLoader, setOptions).then((response) => setactivity(response)).catch((err) => console.error(err))
            }
        }
    }

    useEffect(() => {
        if (window.screen.width < 500) {
             setscreenWidth(true)
        }
        setTimeout(() => {
            setloading(false)
        }, 1500);

    }, []);
    
    const getStats = (from_date, to_date) => {
        setStatsloading(true);
        queryParams = []
        queryParams = "?from_date=" + from_date + "&to_date=" + to_date + "&type=" + analyticsPageName;
        if (pageData?.id) {
            queryParams += `&reference_id=${pageData.id}`;
        }
        for (const key in filter) {
            if (Object.hasOwnProperty.call(filter, key)) {
                if (filter[key])
                    queryParams += `&${key}=${filter[key]}`;
            }
        };
        setDate({ from_date: from_date, to_date: to_date })
        const formdata = new FormData();
        var myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${localStorage?.getItem("token")}`);
        const setOptions = {
            method: "GET",
            headers: myHeaders,
        }
        let temp_graph_data = []
        statGets(null, apiUrl + apiBasePath + "/get/" + slug + params + queryParams, hideLoader, setOptions).then((response) => {
            setstatsData({ ...response })

            temp_graph_data.push(
                {
                    label: 'Unique Visitors',
                    fill: true,
                    borderColor: '#70EEFF',
                    backgroundColor: 'rgba(112, 238, 255, 0.1)',
                    tension: 0.1,
                    data: response?.graph_data?.unique_visitors
                },
                {
                    label: 'Impressions Visitors',
                    fill: true,
                    borderColor: '#18f',
                    backgroundColor: 'rgba(17, 136, 255, 0.1)',
                    tension: 0.1,
                    data: response?.graph_data?.impressions
                },
                {
                    label: 'Visit Duration',
                    fill: true,
                    borderColor: '#7F48FB',
                    backgroundColor: "rgba(127, 72, 251, 0.1)",
                    tension: 0.1,
                    data: response?.graph_data?.time_on_page,
                },
                {
                    label: 'Link Clicks',
                    fill: true,
                    borderColor: '#FFC700',
                    backgroundColor: "rgba(255, 199, 0, 0.1)",
                    tension: 0.1,
                    data: response?.graph_data?.link_clicks
                },
                {
                    label: 'Click-Through-Rate',
                    fill: true,
                    borderColor: '#F00',
                    backgroundColor: 'rgba(255, 0, 0, 0.1)',
                    tension: 0.1,
                    data: response?.graph_data?.click_rate
                },
            );

            setData({ labels: response?.graph_data?.labels, datasets: temp_graph_data });

            setgraphdata({
                unique_visitors: {
                    label: 'Unique Visitors',
                    data: response?.graph_data?.unique_visitors,
                    fill: true,
                    borderColor: '#70EEFF',
                    backgroundColor: 'rgba(112, 238, 255, 0.1)',
                    tension: 0.1
                },
                impressions: {
                    label: 'Impressions Visitors',
                    data: response?.graph_data?.impressions,
                    fill: true,
                    borderColor: '#18f',
                    backgroundColor: 'rgba(17, 136, 255, 0.1)',
                    tension: 0.1
                },
                time_on_page: {
                    label: 'Visit Duration',
                    data: response?.graph_data?.time_on_page,
                    fill: true,
                    borderColor: '#7F48FB',
                    backgroundColor: "rgba(127, 72, 251, 0.1)",
                    tension: 0.1
                },
                link_clicks: {
                    label: 'Link Clicks',
                    data: response?.graph_data?.link_clicks,
                    fill: true,
                    borderColor: '#FFC700',
                    backgroundColor: "rgba(255, 199, 0, 0.1)",
                    tension: 0.1
                },
                click_through_rate: {
                    label: 'Click-Through-Rate',
                    data: response?.graph_data?.click_rate,
                    fill: true,
                    borderColor: '#F00',
                    backgroundColor: 'rgba(255, 0, 0, 0.1)',
                    tension: 0.1
                },
            });
            if (response?.used_on) {
                setPageData({...pageData,used_on:response?.used_on,pageDesc:response?.page_desc,created_at:response?.created_at})
            }
            settopSources(response?.top_sources)
            settopCountries(response?.top_countries);
            setdevices(response?.devices)
            setoperatingSystems(response?.os)
            setbrowsers(response?.browsers)
            setStatsloading(false);
        }).catch((err) => console.error(err))
    }
    useEffect(() => {
        regenerateGraph();
    }, [graphData])

    function regenerateGraph() {
        let tempArr = []
        for (let index = 0; index < 4; index++) {
            if ($('input[name="analytics-graph-filter[]"]').eq(index).is(':checked')) {
                var checked_value = $('input[name="analytics-graph-filter[]"]').eq(index).val();
                if(graphData[checked_value]){
                    tempArr.push(graphData[checked_value]);
                }

            }
        }
        setData({
            labels: data?.labels,
            datasets: tempArr
        });

    }
    const analyticsGraphFilterClick = () => {
        if ($('input[name="analytics-graph-filter[]"]:checked').length === 4) {
            $(".filter-dropdown-menu-selected").text('All');
        }
        else if ($('input[name="analytics-graph-filter[]"]:checked').length > 0) {
            $(".filter-dropdown-menu-selected").text('Applied');
        }
        else {
            $(".filter-dropdown-menu-selected").text('None');
        }
        regenerateGraph();
    }

    const upgradeHandleClick = (e) => {
        e.preventDefault();
        if (newUser === 1) {
            history.push(basePath + '/settings/upgrade-zaap')
        } else {
            dispatch({ type: "SET_UPGRADE_MODAL", payload: { isPro: false, open: true } });
        }
    }

    function toggleTab(e) {
        const selectedValue = e.target.value;
        if (selectedValue === 'overview') {
            setPageData(defaultpageData);
            setDetailedAnalytics(false)
        }
        setTab(selectedValue);
    }
    const showDetailedAnalytics = (id,heading, desc, created_at) => {
        setDetailedAnalytics(true)
        setPageData({id: id, pageHeading: heading,pageDesc: desc, created_at:created_at})
    }
    const goBack = () => {
        setDetailedAnalytics(false)
        setPageData(defaultpageData)
    }
    return (
        <div id="wrapper">
            <PageHeader />
            <div class="analytics-main">
                <Sidebar from={"analytics"} />
                <section class="analytics-section">
                    {showModalasd && <TeamModal showModal={showModalasd} upgradeHandleClick={upgradeHandleClick} heading={'Upgrade to Unlock Analytics.'} text={' Analytics is a PRO only Feature, Subscribe to<br /> PRO to unlock Analytics'} isUltra={false} />}
                    <AnalyticsHeader goBack ={goBack} pageData={pageData} toggleTab={toggleTab} tab={tab} setTab={setTab} screenWidth={screenWidth} loading={loading}/>
                    {screenWidth === false && (detailedAnalytics || tab === 'overview') ?
                        <AnalyticsMain loading={loading} getStats={getStats} topCountries={topCountries} statsloading={statsloading} setactivity={setactivity} activity={activity} date={date} dateFilters={dateFilters} statsData={statsData}  analyticsGraphFilterClick={analyticsGraphFilterClick} screenWidth={screenWidth} data={data} topSources={topSources} devices={devices} browsers={browsers} operatingSystems={operatingSystems} />
                        :
                        screenWidth === false ?
                        (
                            analyticsPageName === 'page' ?
                            <AnalyticsSession loading={loading} getStats={getStats} topCountries={topCountries} statsloading={statsloading} setactivity={setactivity} activity={activity} date={date} dateFilters={dateFilters} statsData={statsData} />
                            :
                            analyticsPageName === 'posts' ?
                            <AnalyticsPosts showDetailedAnalytics={showDetailedAnalytics}/>
                            : 
                            analyticsPageName === 'links' ?
                            <AnalyticsLinks showDetailedAnalytics={showDetailedAnalytics}/>
                            : null
                        )
                        :
                        null
                    }
                </section>
            </div >
        </div >
    )
}
export default Analytics